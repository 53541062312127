.ngx-slider {
  margin: 0 0 35px !important;

  .ngx-slider-bar {
    border-radius: 0 !important;
    background: #eee !important;
  }

  .ngx-slider-selection {
    background: none !important;

    box-shadow: 0 1px 2px 0 rgb(0 0 0 / 38%) inset;
  }

  .ngx-slider-pointer {
    background: none !important;
    width: 12px !important;
    height: 12px !important;
    top: -6px !important;

    &::after {
      width: 12px !important;
      height: 12px !important;
      top: 2px !important;
      left: 0 !important;
      border-radius: 6px !important;
      background: #e1ddc3 !important;
    }

    &:focus,
    &:focus-visible {
      outline: none !important;
    }
  }


  .ngx-slider-bubble {
    font-size: 14px !important;
    color: #777 !important;
  }

}
