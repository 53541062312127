// About
.history-section {
  padding: 5rem 0;
  background-color: #efefef;

  .tab-content {
    h3 {
      margin-bottom: 2rem;
      font: 400 2.5rem / 1.2 $second-font-family;
      text-transform: uppercase;
    }

    img {
      margin-bottom: 2rem;
    }
  }

  .col-lg-2 {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
}

.nav.nav-history {
  width: 100%;
  margin-bottom: 1rem;

  .nav-item {
    display: inline-block;
    margin-right: 1.7rem;
    font: 400 1.8rem / 1 $second-font-family;

    &:last-child {
      margin-right: 0;
    }
  }

  .nav-link {
    display: -ms-inline-flexbox;
    display: inline-flex;
    position: relative;
    -ms-flex-align: center;
    align-items: center;
    padding: 1.5rem 0;
    color: #979797;

    &:before {
      display: inline-block;
      width: 9px;
      height: 2px;
      margin-right: 1rem;
      background-color: #979797;
      content: '';
    }

    &.active,
    &:hover,
    &:focus {
      color: $headings-text;

      &:before {
        background-color: $headings-text;
      }
    }
  }
}

@include mq(lg) {
  .history-section {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;

    .tab-content {
      img {
        margin-bottom: 0;
      }

      h3 {
        font-size: 3rem;
      }

      .col-sm-8 {
        padding-right: 45px;
      }

      .col-sm-4 + .col-sm-8 {
        padding-right: 15px;
        padding-left: 45px;
      }
    }
  }

  .nav.nav-history {
    -ms-flex-direction: column;
    flex-direction: column;

    .nav-item {
      display: block;
      margin-right: 0;
    }

    .nav-link {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-pack: end;
      justify-content: flex-end;

      &:before {
        margin-right: 2rem;
      }
    }
  }
}
