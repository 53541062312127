// Home

// # Contents
// 1. Home - Slider
// 2. Home - Banners & Info Boxes
// 2.1) Category Banners
// 2.2) Border
// 2.3) Promotion Banner
// 3. Sections
// 3.1) Section Text
// 3.2) Blog Section
// 3.3) Brands Section
// 4. Product
// 4.1) Product Default
// 4.2) Product Widget
// 4.3) Product Categories Slider
// 4.4) Product Page & Category Page
// 5. Extra
// 5.1) Load More Button
// 5.2) Top Notice
// 5.3) Heading
// 6. Responsive

// 1. Home - Slider

.home-slide {
	height: 100%;
	// max-height: 804px;
	background: #9C9895;

	.owl-carousel .owl-item & div.slide-bg {
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		max-height: none;
		// background-size: contain;
	}

	.banner-layer {
		position: relative;
		padding: 7% 9.8% 0 10%;
		@include mq(sm, max) {
			padding: 0;
		}
		
	}

	h2 {
		margin-left: -3px;
		font-family: 'Euclid';
		font-size: 3em;
		letter-spacing: .001em;
		line-height: 1;
	}

	h3 {
		font-size: 7.0625em;
		line-height: 1;
	}

	h5 {
		margin-right: 2.2em;
		font-size: 1.25em;
		font-weight: 700;
		line-height: 1;
		vertical-align: middle;
	}

	em {
		font-size: 2.125em;
		font-style: normal;
		vertical-align: text-top;
		margin-top: -2px;
		display: inline-block;
		letter-spacing: .01em;
	}
	
	.btn-xl {
		padding: 1.125em 2.5em;
		margin-top: -4px;
		font-size: 1.25em;
		letter-spacing: -.025em;
	}
}

.home-slide1 .slide-bg {
	background-position: 34%;

	@include mq(sm, max) {
		background-position: 70%;
	}
}

.home-slide2 {
	.slide-bg {
		// background-position: 65%;
		@include mq(sm, max) {
			//background-position: 15%;
		}
	}

	h2 {
		font-size: 3.47em;
	}
}

.rotated-upto-text {
	position: relative;
	padding-left: .265em;

	small {
		display: inline-block;
		position: absolute;
		left: -1em;
		top: 50%;
		transform: translateY(-50%) rotate(-90deg);
		font-size: .2641em;
		font-weight: 500;
		letter-spacing: -.02em;
	}
}

.divider-short-thick {
	margin: 2.2rem auto 0 0;
	width: 60px;
	border-top: 5px solid $primary-color-dark;
}

// 2. Home - Banners & Info Boxes

// 2.1) Category Banners
.banners-container {
	background-color: #efefef;

	.banner {
		overflow: hidden;
		margin-bottom: 0;
		background-color: transparent;
	}

	img {
		max-height: 210px;
		object-fit: cover;
	}

	.banner-content {		
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		padding: 2rem;
		font-size: 1.3rem;
		line-height: 1.154;
		letter-spacing: -.025em;
	}

	.banner-title {
		font-size: 2.2rem;
		letter-spacing: -.025em;
	}

	hr {
		width: 35px;
		border-top-width: 4px;
		margin: 1.9rem 0 1.8rem;
	}

	.btn {
		color: $primary-color-dark;
		padding: 0;
	}
}

// 2.2) Border
.banners-container .banner,
.info-boxes-container .info-box {
	border-left: 1px solid #dedede;
	border-right: 1px solid #dedede;
}

.info-boxes-container .info-box {
	border-bottom: 1px solid #e7e7e7;
	padding-left: 2.4rem;
	padding-right: 2.4rem;
}

// 2.3) Promotion Banner
.promo-section {
	padding-top: 12.5rem;
	padding-bottom: 11.9rem;
	background-color: #24252A;
}

.category-banner {
	padding-top: 9rem;
	padding-bottom: 7.8rem;
	background-color: #24252A;
	//margin-top: 156px;
	@include mq(xs, max) {
		//margin-top: 195px;
	}
}

.promo-content {
	position: relative;
	text-align: center;

	h2 {
		color: #fff;
		font-size: 4.088rem;
		font-family: 'Euclid';
		line-height: 1;
		letter-spacing: .001em;
		text-transform: capitalize;
	}

	h3 {
		color: #fff;
		font-size: 8.886rem;
		line-height: 1;
	}

	hr { 
		border-top-color: #fff;
	}

	.btn {
		padding: 1.22em 2.95em;
	}
	
	.divider-short-thick {
		margin: 2.2rem auto 3rem;
		width: 53.38px;
		border-top-width: 4.44px;
	}
}

// 3. Sections

// 3.1) Section Text
.section-title {
	font-size: 2.2rem;
	text-transform: uppercase;
}

.section-description {
	font-size: 1.4rem;
}

.section-sub-title {
	font-size: 1.2rem;
	letter-spacing: .05em;
	font-weight: 600;
	text-transform: uppercase;
}

// 3.2) Blog Section
.blog-section {
	padding-top: 5.7rem;
	padding-bottom: 5.7rem;
	background-color: #f4f4f2;

	.post {
		display: -ms-flexbox;
		display: flex;
		align-items: center;
		-ms-flex-align: center;
		margin-bottom: 0;
	}

	.post-media {
		margin: 0;
	}

	.post-body {
		flex: 1;
		-ms-flex: 1;
		z-index: 1;
		position: relative; // for only ie 10
		margin: 0 0 0 -35px;
		padding: 3.3rem 4rem 3.2rem;
		border: 0;
		background-color: #fff;

		.post-date {
			display: inline-block;
			float: none;
			width: auto;
			margin: 0 0 .7rem;
			padding: .7rem .8rem;
			color: #fff;
			background-color: $primary-color-dark;
			font-size: 1.1rem;
			font-weight: 700;
			letter-spacing: .1em;
			text-transform: uppercase;
			box-shadow: none;
		}
	}

	.post-title {
		margin-bottom: .8rem;
		font-size: 1.8rem;
		font-weight: 600;
		letter-spacing: -.01em;
		text-transform: uppercase;
	}

	.post-content {
		font-size: 1.3rem;
		letter-spacing: .03em;
		line-height: 1.9;

		p {
			max-width: 250px;
			margin-bottom: .7rem;
		}
	}

	.read-more {
		float: left;
		color: $primary-color-dark;
		font-size: 1.4rem;
		font-weight: 700;
		letter-spacing: -.025em;
		text-transform: uppercase;

		i {
			display: inline-block;
			margin-top: -.4rem;
			line-height: 1;
			vertical-align: middle;
		}
	}
}

// 3.3) Brands Section
.brands-section {
	padding-top: 3.2rem;
	padding-bottom: 3.2rem;
}

// 4. Product

// 4.1) Product Default
.product-default {
	.label-group {
		top: 1.2rem;
		left: 1.2rem;
	}

	.product-label {
		padding: 6px 6px 5px 7px;
		font-size: 9px;
		font-family: $third-font-family;

		&.label-sale {
			background-color: #EE8379;
		}
	}
}

.price-box {
	font-family: $third-font-family;
}

.product-list .btn-icon-wish,
.product-list .btn-quickview {
	border-color: #e1ddc3;
	color: #61605a;
}

// 4.2) Product Widget
.product-widget {
	margin-bottom: 2rem;

	.ratings-container {
		margin-bottom: 1.2rem;
	}

	figure {
		margin-right: 1.2rem;
	}

	.product-title {
		font-weight: 500;
		margin-bottom: 0;
	}

	.ratings,
	.product-ratings {
		font-size: 10px;
	}

	.ratings:before {
		color: #706f6c;
	}

	.price-box {
		margin-bottom: 0;
	}

	.product-price {
		color: $primary-color-dark;
		font-size: 1.8rem;
		letter-spacing: .005em;
	}

	&:hover figure {
		box-shadow: none;
	}
}

// 4.3) Product Categories Slider
.categories-slider {
	.category-content {
		padding: 2.5rem 3.5rem;
	}

	h3 {
		position: relative;
		z-index: 1;
		width: 100%;
		padding: 1.45em 2em;
		color: #000;
		font-size: 12px;
		font-weight: 600;
		letter-spacing: 0;
		text-transform: none;
		text-align: center;

		&:before {
			content: '';
			position: absolute;
			z-index: -1;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			transform: skew(-25deg);
			background: #fff;
		}

		&:after {
			content: '\e81f';
			margin-left: 6px;
			font-family: 'porto';
		}
	}
}

// 4.4) Product Page & Category Page
.products-slider .owl-dots .owl-dot span {
	border-color: $primary-color;
}

.sidebar-shop {
	.main & .widget {
		border: 0;
		background-color: white;
	}

	.widget .owl-nav {
		top: -5.1rem;
	}

	.widget-block h5 {
		margin-top: .8rem;
	}

	.widget-title {
		padding-bottom: 1rem;
		border-bottom: 1px solid #e7e7e7;
		letter-spacing: .05em;
	}
}

.toolbox label {
	color: $primary-color-dark;
	font-size: 1.3rem;
	font-weight: 700;
}

// 5. Extra

// 5.1) Load More Button
.btn-lg {
	min-width: 17rem;
}

.btn.btn-dark.loadmore,
.btn.btn-dark.loadmore:hover {
	border: 0;
	padding: 1.11em 3.15em;
	color: #fff;
	font-size: 1.4rem;
}

.btn-center {
	margin-left: 50%;
	transform: translate(-50%);
}

// 5.2) Top Notice
.top-notice {
	color: #fff;
	font-weight: 400;

	a:hover {
		color: $primary-color;
	}
}

// 5.3) Heading
.heading {
	color: $primary-color-dark;
}

// 6. Responsive

@include mq(lg) {
	.info-boxes-container .info-box:not(:last-child) {
		border-right: 0;
	}
}

@include mq(lg, max) {
	.home-slide {
		font-size: 1.3rem;
	}
	
	.owl-carousel.owl-theme .owl-nav {
		.owl-prev {
			left: 1.7vw;
		}

		.owl-next {
			right: 1.7vw;
		}
	}

	.info-boxes-container .info-box {
		justify-content: flex-start;
		-ms-flex-pack: start;
	}
}

@include mq(md) {
	.banners-container .banner:not(:last-child) {
		border-right: 0;
	}
}

@include mq(md, max) {
	.banners-container .banner:not(:last-child) {
		border-bottom: 1px solid #e7e7e7;
	}

	.promo-content {
		h2 {
			font-size: 5vw;
		}

		h3 {
			font-size: 10vw;
		}
	}
}

@include mq(sm) {
	.category-banner .divider-short-thick {
		width: 4.5px;
		border-top: 53.38px solid #fff;
		margin: 0 2.4rem;
	}
}

@include mq(sm, max) {
	.home-slide {
		font-size: 2.3vw;
	}

	.home-slider h5 {
		margin-right: 1rem;
		margin-top: 1rem;
		color: #fff
	}

	.home-slider .btn {
		margin-top: 1.6rem;
	}
}

@include mq(xs, max) {
	.blog-section {
		.post {
			flex-wrap: wrap;
			-ms-flex-wrap: wrap;
		}

		.post-body {
			margin: 0;
			flex: 1 1 100%;
			-ms-flex: 1 1 100%;
		}
	}
}