@charset "UTF-8";
@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i");
@import url("https://fonts.googleapis.com/css?family=Oswald:200,300,400,500,600,700");
.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mt-6 {
  margin-top: 6rem !important;
}

.mt-7 {
  margin-top: 7rem !important;
}

.mt-8 {
  margin-top: 8rem !important;
}

.mt-9 {
  margin-top: 9rem !important;
}

.mt-10 {
  margin-top: 10rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.mb-5 {
  margin-bottom: 5rem !important;
}

.mb-6 {
  margin-bottom: 6rem !important;
}

.mb-7 {
  margin-bottom: 7rem !important;
}

.mb-8 {
  margin-bottom: 8rem !important;
}

.mb-9 {
  margin-bottom: 9rem !important;
}

.mb-10 {
  margin-bottom: 10rem !important;
}

@media (min-width: 768px) {
  .mt-md-0 {
    margin-top: 0rem !important;
  }
  .mt-md-1 {
    margin-top: 1rem !important;
  }
  .mt-md-2 {
    margin-top: 2rem !important;
  }
  .mt-md-3 {
    margin-top: 3rem !important;
  }
  .mt-md-4 {
    margin-top: 4rem !important;
  }
  .mt-md-5 {
    margin-top: 5rem !important;
  }
  .mt-md-6 {
    margin-top: 6rem !important;
  }
  .mt-md-7 {
    margin-top: 7rem !important;
  }
  .mt-md-8 {
    margin-top: 8rem !important;
  }
  .mt-md-9 {
    margin-top: 9rem !important;
  }
  .mt-md-10 {
    margin-top: 10rem !important;
  }
  .mb-md-0 {
    margin-bottom: 0rem !important;
  }
  .mb-md-1 {
    margin-bottom: 1rem !important;
  }
  .mb-md-2 {
    margin-bottom: 2rem !important;
  }
  .mb-md-3 {
    margin-bottom: 3rem !important;
  }
  .mb-md-4 {
    margin-bottom: 4rem !important;
  }
  .mb-md-5 {
    margin-bottom: 5rem !important;
  }
  .mb-md-6 {
    margin-bottom: 6rem !important;
  }
  .mb-md-7 {
    margin-bottom: 7rem !important;
  }
  .mb-md-8 {
    margin-bottom: 8rem !important;
  }
  .mb-md-9 {
    margin-bottom: 9rem !important;
  }
  .mb-md-10 {
    margin-bottom: 10rem !important;
  }
}
@media (min-width: 992px) {
  .mt-lg-0 {
    margin-top: 0rem !important;
  }
  .mt-lg-1 {
    margin-top: 1rem !important;
  }
  .mt-lg-2 {
    margin-top: 2rem !important;
  }
  .mt-lg-3 {
    margin-top: 3rem !important;
  }
  .mt-lg-4 {
    margin-top: 4rem !important;
  }
  .mt-lg-5 {
    margin-top: 5rem !important;
  }
  .mt-lg-6 {
    margin-top: 6rem !important;
  }
  .mt-lg-7 {
    margin-top: 7rem !important;
  }
  .mt-lg-8 {
    margin-top: 8rem !important;
  }
  .mt-lg-9 {
    margin-top: 9rem !important;
  }
  .mt-lg-10 {
    margin-top: 10rem !important;
  }
  .mb-lg-0 {
    margin-bottom: 0rem !important;
  }
  .mb-lg-1 {
    margin-bottom: 1rem !important;
  }
  .mb-lg-2 {
    margin-bottom: 2rem !important;
  }
  .mb-lg-3 {
    margin-bottom: 3rem !important;
  }
  .mb-lg-4 {
    margin-bottom: 4rem !important;
  }
  .mb-lg-5 {
    margin-bottom: 5rem !important;
  }
  .mb-lg-6 {
    margin-bottom: 6rem !important;
  }
  .mb-lg-7 {
    margin-bottom: 7rem !important;
  }
  .mb-lg-8 {
    margin-bottom: 8rem !important;
  }
  .mb-lg-9 {
    margin-bottom: 9rem !important;
  }
  .mb-lg-10 {
    margin-bottom: 10rem !important;
  }
}
@media (min-width: 1200px) {
  .mt-xl-0 {
    margin-top: 0rem !important;
  }
  .mt-xl-1 {
    margin-top: 1rem !important;
  }
  .mt-xl-2 {
    margin-top: 2rem !important;
  }
  .mt-xl-3 {
    margin-top: 3rem !important;
  }
  .mt-xl-4 {
    margin-top: 4rem !important;
  }
  .mt-xl-5 {
    margin-top: 5rem !important;
  }
  .mt-xl-6 {
    margin-top: 6rem !important;
  }
  .mt-xl-7 {
    margin-top: 7rem !important;
  }
  .mt-xl-8 {
    margin-top: 8rem !important;
  }
  .mt-xl-9 {
    margin-top: 9rem !important;
  }
  .mt-xl-10 {
    margin-top: 10rem !important;
  }
  .mb-xl-0 {
    margin-bottom: 0rem !important;
  }
  .mb-xl-1 {
    margin-bottom: 1rem !important;
  }
  .mb-xl-2 {
    margin-bottom: 2rem !important;
  }
  .mb-xl-3 {
    margin-bottom: 3rem !important;
  }
  .mb-xl-4 {
    margin-bottom: 4rem !important;
  }
  .mb-xl-5 {
    margin-bottom: 5rem !important;
  }
  .mb-xl-6 {
    margin-bottom: 6rem !important;
  }
  .mb-xl-7 {
    margin-bottom: 7rem !important;
  }
  .mb-xl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xl-9 {
    margin-bottom: 9rem !important;
  }
  .mb-xl-10 {
    margin-bottom: 10rem !important;
  }
}
@media (min-width: 1600px) {
  .mt-xxl-0 {
    margin-top: 0rem !important;
  }
  .mt-xxl-1 {
    margin-top: 1rem !important;
  }
  .mt-xxl-2 {
    margin-top: 2rem !important;
  }
  .mt-xxl-3 {
    margin-top: 3rem !important;
  }
  .mt-xxl-4 {
    margin-top: 4rem !important;
  }
  .mt-xxl-5 {
    margin-top: 5rem !important;
  }
  .mt-xxl-6 {
    margin-top: 6rem !important;
  }
  .mt-xxl-7 {
    margin-top: 7rem !important;
  }
  .mt-xxl-8 {
    margin-top: 8rem !important;
  }
  .mt-xxl-9 {
    margin-top: 9rem !important;
  }
  .mt-xxl-10 {
    margin-top: 10rem !important;
  }
  .mb-xxl-0 {
    margin-bottom: 0rem !important;
  }
  .mb-xxl-1 {
    margin-bottom: 1rem !important;
  }
  .mb-xxl-2 {
    margin-bottom: 2rem !important;
  }
  .mb-xxl-3 {
    margin-bottom: 3rem !important;
  }
  .mb-xxl-4 {
    margin-bottom: 4rem !important;
  }
  .mb-xxl-5 {
    margin-bottom: 5rem !important;
  }
  .mb-xxl-6 {
    margin-bottom: 6rem !important;
  }
  .mb-xxl-7 {
    margin-bottom: 7rem !important;
  }
  .mb-xxl-8 {
    margin-bottom: 8rem !important;
  }
  .mb-xxl-9 {
    margin-bottom: 9rem !important;
  }
  .mb-xxl-10 {
    margin-bottom: 10rem !important;
  }
}
.m-b-1 {
  margin-bottom: 0.4rem !important;
}

.m-b-2 {
  margin-bottom: 0.8rem !important;
}

.m-b-3 {
  margin-bottom: 1.6rem !important;
}

.m-b-4 {
  margin-bottom: 2.4rem !important;
}

.m-b-5 {
  margin-bottom: 4.8rem !important;
}

/*
 *  Owl Carousel - Core
 */
.owl-carousel {
  display: flex;
  width: 100%;
  height: 100%;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
  touch-action: manipulation;
  -moz-backface-visibility: hidden;
  /* fix firefox animation glitch */
  display: flex;
  width: 100%;
  height: 100%;
}
.owl-carousel .owl-stage:after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  /* fix for flashing background */
  -webkit-transform: translate3d(0px, 0px, 0px);
  display: flex;
  width: 100%;
  height: 100%;
}
.owl-carousel .owl-stage-outer owl-stage {
  display: flex;
  width: 100%;
  height: 100%;
}
.owl-carousel .owl-stage-outer owl-stage > * {
  display: block;
  width: 100%;
  height: 100%;
}
.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  -ms-backface-visibility: hidden;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-backface-visibility: hidden;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  height: 100%;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
  cursor: pointer;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
  background: none;
  color: inherit;
  border: 0;
  padding: 0 !important;
  font: inherit;
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  opacity: 0;
  display: block;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.owl-carousel.owl-refresh .owl-item {
  visibility: hidden;
}
.owl-carousel.owl-drag .owl-item {
  touch-action: pan-y;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

.owl-theme,
.owl-carousel,
.owl-carousel .owl-stage-outer,
owl-stage,
.owl-carousel .owl-stage,
.owl-carousel .owl-item {
  height: 100%;
}

.prod-thumbnail .owl-item {
  width: 80px !important;
}
.prod-thumbnail .owl-item img {
  width: 80px !important;
}
.prod-thumbnail .owl-item img.product-gallery__carousel-item--active {
  border: 2px solid #21293c;
}
.prod-thumbnail .owl-theme,
.prod-thumbnail .owl-carousel,
.prod-thumbnail .owl-carousel .owl-stage-outer,
.prod-thumbnail owl-stage,
.prod-thumbnail .owl-carousel .owl-stage,
.prod-thumbnail .owl-carousel .owl-item {
  width: 100% !important;
}

@media screen and (min-width: 992px) {
  .selected-products-slider .owl-carousel .owl-stage .owl-item:first-child {
    margin-left: 1%;
  }
}
.selected-products-slider .owl-theme .owl-nav [class*=owl-] {
  top: 20%;
  background: white;
  width: 5rem;
  height: 5rem;
  border-radius: 5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.selected-products-slider .owl-theme .owl-nav [class*=owl-]:hover {
  cursor: pointer;
  background: white;
}
.selected-products-slider .owl-carousel .owl-nav .owl-next {
  right: 4vw;
}
.selected-products-slider .owl-carousel .owl-nav .owl-prev {
  left: 4vw;
}
.selected-products-slider .owl-theme .owl-nav .disabled {
  display: none;
}

/*
 *  Owl Carousel - Animate Plugin
 */
.owl-carousel .animated {
  animation-duration: 1000ms;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  animation-name: fadeOut;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
/*
 * 	Owl Carousel - Auto Height Plugin
 */
.owl-height {
  transition: height 500ms ease-in-out;
}

/*
 * 	Owl Carousel - Lazy Load Plugin
 */
.owl-carousel .owl-item {
  /**
  	This is introduced due to a bug in IE11 where lazy loading combined with autoheight plugin causes a wrong
  	calculation of the height of the owl-item that breaks page layouts
   */
}
.owl-carousel .owl-item .owl-lazy {
  transition: opacity 400ms ease;
}
.owl-carousel .owl-item .owl-lazy[src^=""], .owl-carousel .owl-item .owl-lazy:not([src]) {
  max-height: 0;
}
.owl-carousel .owl-item img.owl-lazy {
  transform-style: preserve-3d;
}

/*
 * 	Owl Carousel - Video Plugin
 */
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #000;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 80px;
  width: 80px;
  left: 50%;
  top: 50%;
  margin-left: -40px;
  margin-top: -40px;
  background: url("/assets/images/owl.video.play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  transition: transform 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  transform: scale(1.3, 1.3);
}
.owl-carousel .owl-video-playing .owl-video-tn,
.owl-carousel .owl-video-playing .owl-video-play-icon {
  display: none;
}
.owl-carousel .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}

/*
 * 	Default theme - Owl Carousel CSS File
 */
.owl-theme .owl-nav {
  margin: 0;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  color: #222529;
  font-size: 3.4rem;
}
.owl-theme .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #222529;
  margin: 0;
  font-size: 3.4rem;
  padding: 4px 7px;
  background: transparent;
  display: inline-block;
  cursor: pointer;
  border-radius: 3px;
}
.owl-theme .owl-nav [class*=owl-] i:before {
  margin: 0;
  width: auto;
}
.owl-theme .owl-nav [class*=owl-]:hover {
  background: transparent;
  color: #222529;
  text-decoration: none;
}
.owl-theme .owl-nav .owl-prev {
  left: 0;
}
.owl-theme .owl-nav .owl-next {
  right: 0;
}
.owl-theme .owl-nav .disabled {
  opacity: 0.5;
  cursor: default;
}
.owl-theme .owl-nav.disabled + .owl-dots {
  margin-top: 2.5rem;
}
.owl-theme .owl-dots {
  line-height: 1;
  text-align: center;
  -webkit-tap-highlight-color: transparent;
}
.owl-theme .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  *display: inline;
}
.owl-theme .owl-dots .owl-dot span {
  position: relative;
  width: 15px;
  height: 15px;
  margin: 2px 2px;
  background: transparent;
  display: block;
  -webkit-backface-visibility: visible;
  transition: all 0.3s ease;
  border: 0.2rem solid rgba(0, 0, 0, 0.4);
  border-radius: 50%;
}
.owl-theme .owl-dots .owl-dot span:before {
  content: "";
  display: block;
  width: 0.7rem;
  height: 0.7rem;
  background-color: transparent;
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: -0.35rem;
  margin-left: -0.35rem;
  background-color: #e1ddc3;
  opacity: 0;
  visibility: hidden;
  transform: scale(0);
  transition: all 0.3s;
  border-radius: 50%;
}
.owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
  border-color: #e1ddc3;
  background: transparent;
}
.owl-theme .owl-dots .owl-dot.active span:before {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.owl-theme.owl-theme-light .owl-dots .owl-dot span {
  border-color: #fff;
  background: transparent;
}
.owl-theme.owl-theme-light .owl-dots .owl-dot span:before {
  border-color: #fff;
}
.owl-theme.owl-theme-light .owl-dots .owl-dot.active span, .owl-theme.owl-theme-light .owl-dots .owl-dot:hover span {
  border-color: #e1ddc3;
  background: transparent;
}
.owl-theme.owl-theme-light .owl-dots .owl-dot.active span:before {
  border-color: #e1ddc3;
}

/* Magnific Popup CSS */
.mfp-bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #fff;
  opacity: 0.4;
}

.mfp-wrap {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1043;
  position: fixed;
  outline: none !important;
  -webkit-backface-visibility: hidden;
}

.mfp-container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
}

.mfp-container:before {
  content: "";
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.mfp-align-top .mfp-container:before {
  display: none;
}

.mfp-content {
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin: 0 auto;
  text-align: left;
  z-index: 1045;
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
  width: 100%;
  cursor: auto;
}

.mfp-ajax-cur {
  cursor: progress;
}

.mfp-zoom-out-cur, .mfp-zoom-out-cur .mfp-image-holder .mfp-close {
  cursor: -moz-zoom-out;
  cursor: -webkit-zoom-out;
  cursor: zoom-out;
}

.mfp-zoom {
  cursor: pointer;
  cursor: -webkit-zoom-in;
  cursor: -moz-zoom-in;
  cursor: zoom-in;
}

.mfp-auto-cursor .mfp-content {
  cursor: auto;
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.mfp-loading.mfp-figure {
  display: none;
}

.mfp-hide {
  display: none !important;
}

.mfp-preloader {
  color: #CCC;
  position: absolute;
  top: 50%;
  width: auto;
  text-align: center;
  margin-top: -0.8em;
  left: 8px;
  right: 8px;
  z-index: 1044;
}
.mfp-preloader a {
  color: #CCC;
}
.mfp-preloader a:hover {
  color: #FFF;
}

.mfp-s-ready .mfp-preloader {
  display: none;
}

.mfp-s-error .mfp-content {
  display: none;
}

button.mfp-close, button.mfp-arrow {
  overflow: visible;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none;
  display: block;
  outline: none;
  padding: 0;
  z-index: 1046;
  box-shadow: none;
  touch-action: manipulation;
}
button::-moz-focus-inner {
  padding: 0;
  border: 0;
}

.mfp-close {
  width: 44px;
  height: 44px;
  line-height: 44px;
  position: absolute;
  top: 0;
  right: 0;
  text-decoration: none;
  text-align: center;
  opacity: 0.65;
  padding: 0 0 18px 10px;
  color: #FFF;
  font-style: normal;
  font-size: 28px;
  font-family: Arial, Baskerville, monospace;
}
.mfp-close:hover, .mfp-close:focus {
  opacity: 1;
}
.mfp-close:active {
  top: 1px;
}

.mfp-close-btn-in .mfp-close {
  color: #333;
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
  color: #FFF;
  right: -6px;
  text-align: right;
  padding-right: 6px;
  width: 100%;
}

.mfp-counter {
  position: absolute;
  top: 0;
  right: 0;
  color: #CCC;
  font-size: 12px;
  line-height: 18px;
  white-space: nowrap;
}

.mfp-arrow {
  position: absolute;
  opacity: 0.65;
  margin: 0;
  top: 50%;
  margin-top: -55px;
  padding: 0;
  width: 90px;
  height: 110px;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
.mfp-arrow:active {
  margin-top: -54px;
}
.mfp-arrow:hover, .mfp-arrow:focus {
  opacity: 1;
}
.mfp-arrow:before, .mfp-arrow:after {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;
  left: 0;
  top: 0;
  margin-top: 35px;
  margin-left: 35px;
  border: medium inset transparent;
}
.mfp-arrow:after {
  border-top-width: 13px;
  border-bottom-width: 13px;
  top: 8px;
}
.mfp-arrow:before {
  border-top-width: 21px;
  border-bottom-width: 21px;
  opacity: 0.7;
}

.mfp-arrow-left {
  left: 0;
}
.mfp-arrow-left:after {
  border-right: 17px solid #FFF;
  margin-left: 31px;
}
.mfp-arrow-left:before {
  margin-left: 25px;
  border-right: 27px solid #3F3F3F;
}

.mfp-arrow-right {
  right: 0;
}
.mfp-arrow-right:after {
  border-left: 17px solid #FFF;
  margin-left: 39px;
}
.mfp-arrow-right:before {
  border-left: 27px solid #3F3F3F;
}

.mfp-iframe-holder {
  padding-top: 40px;
  padding-bottom: 40px;
}
.mfp-iframe-holder .mfp-content {
  line-height: 0;
  width: 100%;
  max-width: 900px;
}
.mfp-iframe-holder .mfp-close {
  top: -40px;
}

.mfp-iframe-scaler {
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-top: 56.25%;
}
.mfp-iframe-scaler iframe {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
}

/* Main image in popup */
img.mfp-img {
  width: auto;
  max-width: 100%;
  height: auto;
  display: block;
  line-height: 0;
  box-sizing: border-box;
  padding: 40px 0 40px;
  margin: 0 auto;
}

/* The shadow behind the image */
.mfp-figure {
  line-height: 0;
}
.mfp-figure:after {
  content: "";
  position: absolute;
  left: 0;
  top: 40px;
  bottom: 40px;
  display: block;
  right: 0;
  width: auto;
  height: auto;
  z-index: -1;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
  background: #444;
}
.mfp-figure small {
  color: #BDBDBD;
  display: block;
  font-size: 12px;
  line-height: 14px;
}
.mfp-figure figure {
  margin: 0;
}

.mfp-bottom-bar {
  margin-top: -36px;
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  cursor: auto;
}

.mfp-title {
  text-align: left;
  line-height: 18px;
  color: #F3F3F3;
  word-wrap: break-word;
  padding-right: 36px;
}

.mfp-image-holder .mfp-content {
  max-width: 100%;
}

.mfp-gallery .mfp-image-holder .mfp-figure {
  cursor: pointer;
}

@media (max-width: 800px) and (orientation: landscape), screen and (max-height: 300px) {
  /**
   * Remove all paddings around the image on small screen
   */
  .mfp-img-mobile .mfp-image-holder {
    padding-left: 0;
    padding-right: 0;
  }
  .mfp-img-mobile img.mfp-img {
    padding: 0;
  }
  .mfp-img-mobile .mfp-figure:after {
    top: 0;
    bottom: 0;
  }
  .mfp-img-mobile .mfp-figure small {
    display: inline;
    margin-left: 5px;
  }
  .mfp-img-mobile .mfp-bottom-bar {
    background: rgba(0, 0, 0, 0.6);
    bottom: 0;
    margin: 0;
    top: auto;
    padding: 3px 5px;
    position: fixed;
    box-sizing: border-box;
  }
  .mfp-img-mobile .mfp-bottom-bar:empty {
    padding: 0;
  }
  .mfp-img-mobile .mfp-counter {
    right: 5px;
    top: 3px;
  }
  .mfp-img-mobile .mfp-close {
    top: 0;
    right: 0;
    width: 35px;
    height: 35px;
    line-height: 35px;
    background: rgba(0, 0, 0, 0.6);
    position: fixed;
    text-align: center;
    padding: 0;
  }
}
@media all and (max-width: 900px) {
  .mfp-arrow {
    -webkit-transform: scale(0.75);
    transform: scale(0.75);
  }
  .mfp-arrow-left {
    -webkit-transform-origin: 0;
    transform-origin: 0;
  }
  .mfp-arrow-right {
    -webkit-transform-origin: 100%;
    transform-origin: 100%;
  }
  .mfp-container {
    padding-left: 6px;
    padding-right: 6px;
  }
}
/* overlay at start */
.mfp-fade.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* overlay animate in */
.mfp-fade.mfp-bg.mfp-ready {
  opacity: 0.8;
}

/* overlay animate out */
.mfp-fade.mfp-bg.mfp-removing {
  opacity: 0;
}

/* content at start */
.mfp-fade.mfp-wrap .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.15s ease-out;
  -moz-transition: all 0.15s ease-out;
  transition: all 0.15s ease-out;
}

/* content animate it */
.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

/* content animate out */
.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

/*
 *  Bootstrap TouchSpin - v4.2.5
 *  A mobile and touch friendly input spinner component for Bootstrap 3 & 4.
 *  http://www.virtuosoft.eu/code/bootstrap-touchspin/
 *
 *  Made by István Ujj-Mészáros
 *  Under Apache License v2.0 License
 */
/* This CSS file is unnecessary if you are not using vertical buttons functionality */
.bootstrap-touchspin .input-group-btn-vertical {
  position: absolute;
  right: 0;
  height: 100%;
  z-index: 11;
}

.bootstrap-touchspin.input-group {
  max-width: 68px;
  padding-right: 20px;
  margin-bottom: 0;
}

.bootstrap-touchspin .form-control {
  text-align: center;
  margin-bottom: 0;
  height: 4.2rem;
  max-width: 46px;
  padding: 1.1rem 1rem;
}
.bootstrap-touchspin .form-control:not(:focus) {
  border-color: #ccc;
}

.bootstrap-touchspin .input-group-btn-vertical > .btn {
  position: absolute;
  right: 0;
  height: 2rem;
  padding: 0;
  width: 2rem;
  text-align: center;
  font-size: 1.2rem;
}
.bootstrap-touchspin .input-group-btn-vertical > .btn:before {
  position: relative;
  margin: 0;
  width: auto;
  line-height: 1;
  width: auto;
  top: -0.1rem;
  margin-right: -0.2rem;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-up {
  border-radius: 0;
  top: 0;
}

.bootstrap-touchspin .input-group-btn-vertical .bootstrap-touchspin-down {
  border-radius: 0;
  bottom: 0;
}

/*! nouislider - 11.1.0 - 2018-04-02 11:18:13 */
/* Functional styling;
 * These styles are required for noUiSlider to function.
 * You don't need to change these rules to apply your design.
 */
.noUi-target,
.noUi-target * {
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  -webkit-user-select: none;
  -ms-touch-action: none;
  touch-action: none;
  -ms-user-select: none;
  -moz-user-select: none;
  user-select: none;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-target {
  position: relative;
  direction: ltr;
}

.noUi-base,
.noUi-connects {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
}

/* Wrapper for all connect elements.
 */
.noUi-connects {
  overflow: hidden;
  z-index: 0;
}

.noUi-connect,
.noUi-origin {
  will-change: transform;
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  -ms-transform-origin: 0 0;
  -webkit-transform-origin: 0 0;
  transform-origin: 0 0;
}

/* Offset direction
 */
html:not([dir=rtl]) .noUi-horizontal .noUi-origin {
  left: auto;
  right: 0;
}

/* Give origins 0 height/width so they don't interfere with clicking the
 * connect elements.
 */
.noUi-vertical .noUi-origin {
  width: 0;
}

.noUi-horizontal .noUi-origin {
  height: 0;
}

.noUi-handle {
  position: absolute;
}

.noUi-state-tap .noUi-connect,
.noUi-state-tap .noUi-origin {
  -webkit-transition: transform 0.3s;
  transition: transform 0.3s;
}

.noUi-state-drag * {
  cursor: inherit !important;
}

/* Slider size and handle placement;
 */
.noUi-horizontal {
  height: 0.3rem;
}

.noUi-horizontal .noUi-handle {
  width: 1.1rem;
  height: 1.1rem;
  left: -0.55rem;
  top: -0.3em;
}

.noUi-vertical {
  width: 0.3rem;
  height: 150px;
}

.noUi-vertical .noUi-handle {
  width: 1.1rem;
  height: 1.1rem;
  left: -0.4rem;
  top: -0.5rem;
}

html:not([dir=rtl]) .noUi-horizontal .noUi-handle {
  right: -0.55rem;
  left: auto;
}

/* Styling;
 * Giving the connect element a border radius causes issues with using transform: scale
 */
.noUi-target {
  background: #dfdfdf;
  border-radius: 0;
}

.noUi-connects {
  border-radius: 0;
}

.noUi-connect {
  background: #000;
}

/* Handles and cursors;
 */
.noUi-draggable {
  cursor: ew-resize;
}

.noUi-vertical .noUi-draggable {
  cursor: ns-resize;
}

.noUi-handle {
  border-radius: 50%;
  background: #000;
  cursor: pointer;
}

/* Disabled state;
 */
[disabled] .noUi-connect {
  background: #B8B8B8;
}

[disabled].noUi-target,
[disabled].noUi-handle,
[disabled] .noUi-handle {
  cursor: not-allowed;
}

/* Base;
 *
 */
.noUi-pips,
.noUi-pips * {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.noUi-pips {
  position: absolute;
  color: #999;
}

/* Values;
 *
 */
.noUi-value {
  position: absolute;
  white-space: nowrap;
  text-align: center;
}

.noUi-value-sub {
  color: #ccc;
  font-size: 10px;
}

/* Markings;
 *
 */
.noUi-marker {
  position: absolute;
  background: #CCC;
}

.noUi-marker-sub {
  background: #AAA;
}

.noUi-marker-large {
  background: #AAA;
}

/* Horizontal layout;
 *
 */
.noUi-pips-horizontal {
  padding: 10px 0;
  height: 80px;
  top: 100%;
  left: 0;
  width: 100%;
}

.noUi-value-horizontal {
  -webkit-transform: translate(-50%, 50%);
  transform: translate(-50%, 50%);
}

.noUi-rtl .noUi-value-horizontal {
  -webkit-transform: translate(50%, 50%);
  transform: translate(50%, 50%);
}

.noUi-marker-horizontal.noUi-marker {
  margin-left: -1px;
  width: 2px;
  height: 5px;
}

.noUi-marker-horizontal.noUi-marker-sub {
  height: 10px;
}

.noUi-marker-horizontal.noUi-marker-large {
  height: 15px;
}

/* Vertical layout;
 *
 */
.noUi-pips-vertical {
  padding: 0 10px;
  height: 100%;
  top: 0;
  left: 100%;
}

.noUi-value-vertical {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%, 0);
  padding-left: 25px;
}

.noUi-rtl .noUi-value-vertical {
  -webkit-transform: translate(0, 50%);
  transform: translate(0, 50%);
}

.noUi-marker-vertical.noUi-marker {
  width: 5px;
  height: 2px;
  margin-top: -1px;
}

.noUi-marker-vertical.noUi-marker-sub {
  width: 10px;
}

.noUi-marker-vertical.noUi-marker-large {
  width: 15px;
}

.noUi-tooltip {
  display: block;
  position: absolute;
  border: 1px solid #D9D9D9;
  border-radius: 3px;
  background: #fff;
  color: #000;
  padding: 5px;
  text-align: center;
  white-space: nowrap;
}

.noUi-horizontal .noUi-tooltip {
  -webkit-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  left: 50%;
  bottom: 120%;
}

.noUi-vertical .noUi-tooltip {
  -webkit-transform: translate(0, -50%);
  transform: translate(0, -50%);
  top: 50%;
  right: 120%;
}

.alert {
  margin-bottom: 2rem;
  padding: 1.4rem 1.5rem;
  border-radius: 0;
}
.alert.alert-intro {
  font-size: 1.5rem;
}

.banner {
  position: relative;
  font-size: 1.6rem;
}
.banner figure {
  margin: 0;
}
.banner img {
  width: 100%;
}
.banner h1,
.banner h2,
.banner h3,
.banner h4,
.banner h5,
.banner h6 {
  line-height: 1;
}
.banner .container:not(.banner-content) {
  position: absolute;
  bottom: 40px;
  height: 80px;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 100% !important;
}

.ibanner {
  font-size: 10px;
}

aside .banner {
  font-size: 1.6rem;
}

.banner-layer {
  position: absolute;
  left: 6%;
  right: 6%;
}

.banner-content {
  position: relative;
}

.banner-layer-top {
  top: 7%;
}

.banner-layer-bottom {
  bottom: 5%;
}

.banner-layer-space {
  left: 0;
  right: 0;
  padding: 1em 1.5625em;
}

.banner-layer-left {
  right: auto;
}

.banner-layer-right {
  left: auto;
}

.banner-layer-middle {
  top: 50%;
  transform: translateY(-50%);
}
@media (max-width: 512px) {
  .banner-layer-middle {
    top: 35%;
  }
}

@media (max-width: 1199px) {
  .banner {
    font-size: 1.4rem;
  }
  .ibanner {
    font-size: 1rem;
  }
}
@media (max-width: 767px) {
  .banner-md-vw-small {
    font-size: 1.8vw;
  }
  .banner-md-vw {
    font-size: 2.3vw;
  }
  .banner-md-vw-large {
    font-size: 3vw;
  }
}
@media (max-width: 575px) {
  .banner-md-vw-small {
    font-size: 2.3vw;
  }
  .banner-sm-vw {
    font-size: 3vw;
  }
  .banner-sm-vw-large {
    font-size: 4vw;
  }
}
.breadcrumb-nav {
  color: #323232;
  border-bottom: 1px solid #e7e7e7;
}
.breadcrumb {
  margin-bottom: 0;
  padding: 1.5rem 0;
  border-radius: 0;
  background-color: transparent;
}

.breadcrumb-item {
  font-weight: 600;
  font-size: 10px;
  letter-spacing: 0.09em;
  line-height: 24px;
  text-transform: uppercase;
}
.breadcrumb-item + .breadcrumb-item {
  padding-left: 11px;
}
.breadcrumb-item + .breadcrumb-item:before {
  color: inherit;
  padding-right: 11px;
  content: "\e81a";
  font-size: 12px;
  font-weight: 400;
  font-family: "porto";
  vertical-align: middle;
  margin-top: -0.5rem;
}
.breadcrumb-item a, .breadcrumb-item.active {
  color: inherit;
}

.btn {
  transition: all 0.3s;
  text-transform: uppercase;
  padding: 1.85rem 4.2rem;
  border-radius: 0;
  font-size: 1.4rem;
  font-weight: 700;
  font-family: Euclid, sans-serif;
  line-height: 1.429;
}
.btn:focus {
  box-shadow: none;
}

.btn-sm {
  padding: 1em 1.6em;
  font-size: 1.2rem;
}

.btn-md {
  padding: 1em 2.2em;
  font-size: 1.3rem;
}

.btn-lg {
  padding: 1em 2.15em;
  font-size: 1.4rem;
}

.btn-xl {
  padding: 1.125em 2.75em;
  font-size: 1.125em;
}

.btn-icon-left i {
  padding-right: 0.7rem;
}

.btn-icon-right i {
  padding-left: 0.7rem;
}

.btn-primary {
  border-color: #222529;
  background-color: #222529;
  color: #fff;
  box-shadow: none;
  font-weight: bold;
}
.btn-primary:hover, .btn-primary:focus, .btn-primary:not(:disabled):not(.disabled):active {
  border-color: #222529;
  background-color: #222529;
  color: #fff;
}

.btn-outline {
  border-color: #ccc;
  background-color: transparent;
  background-image: none;
  color: #ccc;
}
.btn-outline:hover, .btn-outline.focus {
  border-color: #e1ddc3;
  background-color: transparent;
  color: #e1ddc3;
  box-shadow: none;
}
.btn-outline.disabled, .btn-outline:disabled {
  background-color: transparent;
  color: #ccc;
}
.btn-outline:not(:disabled):not(.disabled):active, .btn-outline:not(:disabled):not(.disabled).active, .show > .btn-outline.dropdown-toggle {
  border-color: #e1ddc3;
  background-color: transparent;
  color: #e1ddc3;
}

.btn-outline-secondary {
  border-color: #ccc;
  background-color: transparent;
  background-image: none;
  color: #777;
}
.btn-outline-secondary:hover, .btn-outline-secondary.focus {
  border-color: #e1ddc3;
  background-color: #e1ddc3;
  color: #fff;
  box-shadow: none;
}
.btn-outline-secondary.disabled, .btn-outline-secondary:disabled {
  background-color: transparent;
  color: #777;
}
.btn-outline-secondary:not(:disabled):not(.disabled):active, .btn-outline-secondary:not(:disabled):not(.disabled).active, .show > .btn-outline-secondary.dropdown-toggle {
  border-color: #e1ddc3;
  background-color: #e1ddc3;
  color: #fff;
}

.btn-dark {
  border-color: #222529;
  background-color: #222529;
  color: #fff;
  box-shadow: none;
}
.btn-dark:hover, .btn-dark:focus, .btn-dark.focus {
  border-color: #34393f;
  background-color: #34393f;
  color: #fff;
  box-shadow: none;
}
.btn-dark.disabled, .btn-dark:disabled {
  border-color: #222529;
  background-color: #222529;
  color: #fff;
}
.btn-dark:not(:disabled):not(.disabled):active, .btn-dark:not(:disabled):not(.disabled).active, .show > .btn-dark.dropdown-toggle {
  border-color: #050606;
  background-color: #0b0c0d;
  color: #fff;
}

.btn-link {
  padding-top: 0;
  padding-bottom: 0;
  color: #e1ddc3;
  font-family: Euclid, sans-serif;
  font-size: 13px;
  letter-spacing: 0;
  text-transform: initial;
}
.btn-link:hover, .btn-link:focus {
  color: #e1ddc3;
  text-decoration: underline;
}

.btn-social-login {
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  background-color: #e1ddc3;
  font-family: Euclid, sans-serif;
  text-decoration: none;
  text-transform: uppercase;
}
.btn-social-login span,
.btn-social-login i {
  color: #fff;
}
.btn-social-login i {
  margin-top: -1px;
  padding-right: 6px;
}

.btn-facebook {
  background: #3a589d;
}

.btn-gplus {
  background: #dd4e31;
}

.btn-twitter {
  background: #1aa9e1;
}

.owl-dots .owl-dot,
.owl-nav .owl-prev,
.owl-nav .owl-next {
  outline: none;
}

a:focus {
  outline: none;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
.mfp-container .mfp-preloader,
.porto-loading-icon {
  content: "";
  display: inline-block;
  z-index: 2;
  width: 40px;
  height: 40px;
  margin: 0 auto;
  border: 2px solid transparent;
  border-radius: 50%;
  border-top-color: #e1ddc3;
  border-image: none;
  text-indent: -9999px;
  -webkit-animation: spin 0.75s infinite linear;
  animation: spin 0.75s infinite linear;
}

.mfp-container .mfp-preloader:before,
.porto-loading-icon:before {
  position: absolute;
  top: -2px;
  right: 0;
  left: -2px;
  width: inherit;
  height: inherit;
  border: inherit;
  border-radius: inherit;
  border-top-color: inherit;
  content: "";
  -webkit-animation: spin 1.5s infinite ease;
  animation: spin 1.5s infinite ease;
}

.btn.btn-filter {
  height: 50px;
  display: flex;
  padding: 12px 35px;
  align-items: center;
  flex-basis: 100%;
  justify-content: center;
  background-color: #df9a55;
  color: white;
}

.btn.btn-reset-filter {
  height: 50px;
  display: flex;
  padding: 12px 35px;
  align-items: center;
  border: 2px solid rgb(34, 37, 42);
  background-color: transparent;
}

.card {
  margin-bottom: 3rem;
  border: 1px solid #ddd;
  border-radius: 0;
  font-size: 1.4rem;
}

.card-header {
  margin: 0;
  padding: 1.2rem 1.5rem;
  border-radius: 0;
  border-color: #ddd;
  background-color: #f5f5f5;
  color: #000;
  font-weight: 700;
  line-height: 1.5;
  text-transform: uppercase;
}
.card-header:after {
  display: block;
  clear: both;
  content: "";
}
.card-header .card-edit {
  margin-top: 0.1rem;
  float: right;
  color: #e1ddc3;
  font-size: 1.3rem;
  font-weight: 400;
  text-transform: capitalize;
}

.card-body {
  min-height: 135px;
  padding: 2rem 1.5rem;
  border-top: 0;
  border-radius: 0;
}
.card-body a {
  text-decoration: underline;
}
.card-body h4 {
  margin-bottom: 0.7rem;
  color: #666;
}

.count-container {
  margin-bottom: 4rem;
}

.count-wrapper {
  margin-bottom: 0.8rem;
  color: #222529;
  font: 400 2.8rem/1 Euclid, sans-serif;
  text-transform: uppercase;
}

.count-title {
  margin-bottom: 0;
  color: #777;
  font: 600 1.6rem/1 Euclid, sans-serif;
}

@media (min-width: 992px) {
  .count-wrapper {
    font-size: 3.6rem;
  }
}
.feature-box {
  color: #7b858a;
  font-size: 1.5rem;
  line-height: 2;
  margin-bottom: 4rem;
}
.feature-box i {
  display: inline-block;
  margin-bottom: 2.2rem;
  color: #e1ddc3;
  font-size: 5rem;
  line-height: 1;
}
.feature-box i:before {
  margin: 0;
}
.feature-box h3 {
  margin-bottom: 2rem;
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.1;
  letter-spacing: 0;
}
.feature-box p {
  margin-bottom: 0;
}

.feature-box-content {
  color: #7b858a;
  font-size: 1.5rem;
  line-height: 1.8;
}

@font-face {
  font-family: "porto";
  src: url("/assets/fonts/porto.eot?64334846");
  src: url("/assets/fonts/porto.eot?64334846#iefix") format("embedded-opentype"), url("/assets/fonts/porto.woff2?64334846") format("woff2"), url("/assets/fonts/porto.woff?64334846") format("woff"), url("/assets/fonts/porto.ttf?64334846") format("truetype"), url("/assets/fonts/porto.svg?64334846#porto") format("svg");
  font-style: normal;
  font-weight: normal;
  font-display: swap;
}
/* Chrome hack: SVG is rendered more smooth in Windozze. 100% magic, uncomment if you need it. */
/* Note, that will break hinting! In other OS-es font will be not as sharp as it could be */
/*
@media (-webkit-min-device-pixel-ratio:0) {
	@font-face {
		font-family: 'porto';
		font-display: swap;
		src: url('/assets/fonts/porto.svg?64334846#porto') format('svg');
	}
}
*/
[class^=icon-]:before, [class*=" icon-"]:before {
  font-family: "porto";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;
  /* fix buttons height, for twitter bootstrap */
  line-height: 1;
  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */
  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* Uncomment for 3D effect */
  /* text-shadow: 1px 1px 1px rgba(127, 127, 127, 0.3); */
}

.icon-spin1:before {
  content: "\e800";
}
.icon-spin2:before {
  content: "\e801";
}
.icon-spin3:before {
  content: "\e802";
}
.icon-spin4:before {
  content: "\e803";
}
.icon-spin5:before {
  content: "\e804";
}
.icon-spin6:before {
  content: "\e805";
}
.icon-firefox:before {
  content: "\e806";
}
.icon-chrome:before {
  content: "\e807";
}
.icon-opera:before {
  content: "\e808";
}
.icon-ie:before {
  content: "\e809";
}
.icon-phone:before {
  content: "\e80a";
}
.icon-down-dir:before {
  content: "\e80b";
}
.icon-cart:before {
  content: "\e80c";
}
.icon-up-dir:before {
  content: "\e80d";
}
.icon-mode-grid:before {
  content: "\e80e";
}
.icon-mode-list:before {
  content: "\e80f";
}
.icon-compare:before {
  content: "\e810";
}
.icon-wishlist:before {
  content: "\e811";
}
.icon-search:before {
  content: "\e812";
}
.icon-left-dir:before {
  content: "\e813";
}
.icon-right-dir:before {
  content: "\e814";
}
.icon-down-open:before {
  content: "\e815";
}
.icon-left-open:before {
  content: "\e816";
}
.icon-right-open:before {
  content: "\e817";
}
.icon-up-open:before {
  content: "\e818";
}
.icon-angle-left:before {
  content: "\e819";
}
.icon-angle-right:before {
  content: "\e81a";
}
.icon-angle-up:before {
  content: "\e81b";
}
.icon-angle-down:before {
  content: "\e81c";
}
.icon-down:before {
  content: "\e81d";
}
.icon-left:before {
  content: "\e81e";
}
.icon-right:before {
  content: "\e81f";
}
.icon-up:before {
  content: "\e820";
}
.icon-angle-double-left:before {
  content: "\e821";
}
.icon-angle-double-right:before {
  content: "\e822";
}
.icon-angle-double-up:before {
  content: "\e823";
}
.icon-angle-double-down:before {
  content: "\e824";
}
.icon-mail:before {
  content: "\e825";
}
.icon-location:before {
  content: "\e826";
}
.icon-skype:before {
  content: "\e827";
}
.icon-right-open-big:before {
  content: "\e828";
}
.icon-left-open-big:before {
  content: "\e829";
}
.icon-down-open-big:before {
  content: "\e82a";
}
.icon-up-open-big:before {
  content: "\e82b";
}
.icon-cancel:before {
  content: "\e82c";
}
.icon-user:before {
  content: "\e82d";
}
.icon-mail-alt:before {
  content: "\e82e";
}
.icon-fax:before {
  content: "\e82f";
}
.icon-lock:before {
  content: "\e830";
}
.icon-company:before {
  content: "\e831";
}
.icon-city:before {
  content: "\e832";
}
.icon-post:before {
  content: "\e833";
}
.icon-country:before {
  content: "\e834";
}
.icon-calendar:before {
  content: "\e835";
}
.icon-doc:before {
  content: "\e836";
}
.icon-mobile:before {
  content: "\e837";
}
.icon-clock:before {
  content: "\e838";
}
.icon-chat:before {
  content: "\e839";
}
.icon-tag:before {
  content: "\e83a";
}
.icon-folder:before {
  content: "\e83b";
}
.icon-folder-open:before {
  content: "\e83c";
}
.icon-forward:before {
  content: "\e83d";
}
.icon-reply:before {
  content: "\e83e";
}
.icon-cog:before {
  content: "\e83f";
}
.icon-cog-alt:before {
  content: "\e840";
}
.icon-wrench:before {
  content: "\e841";
}
.icon-quote-left:before {
  content: "\e842";
}
.icon-quote-right:before {
  content: "\e843";
}
.icon-gift:before {
  content: "\e844";
}
.icon-dollar:before {
  content: "\e845";
}
.icon-euro:before {
  content: "\e846";
}
.icon-pound:before {
  content: "\e847";
}
.icon-rupee:before {
  content: "\e848";
}
.icon-yen:before {
  content: "\e849";
}
.icon-rouble:before {
  content: "\e84a";
}
.icon-try:before {
  content: "\e84b";
}
.icon-won:before {
  content: "\e84c";
}
.icon-bitcoin:before {
  content: "\e84d";
}
.icon-ok:before {
  content: "\e84e";
}
.icon-chevron-left:before {
  content: "\e84f";
}
.icon-chevron-right:before {
  content: "\e850";
}
.icon-export:before {
  content: "\e851";
}
.icon-star:before {
  content: "\e852";
}
.icon-star-empty:before {
  content: "\e853";
}
.icon-plus-squared:before {
  content: "\e854";
}
.icon-minus-squared:before {
  content: "\e855";
}
.icon-plus-squared-alt:before {
  content: "\e856";
}
.icon-minus-squared-alt:before {
  content: "\e857";
}
.icon-truck:before {
  content: "\e858";
}
.icon-lifebuoy:before {
  content: "\e859";
}
.icon-pencil:before {
  content: "\e85a";
}
.icon-users:before {
  content: "\e85b";
}
.icon-video:before {
  content: "\e85c";
}
.icon-menu:before {
  content: "\e85d";
}
.icon-desktop:before {
  content: "\e85e";
}
.icon-doc-inv:before {
  content: "\e85f";
}
.icon-circle:before {
  content: "\e860";
}
.icon-circle-empty:before {
  content: "\e861";
}
.icon-circle-thin:before {
  content: "\e862";
}
.icon-mini-cart:before {
  content: "\e863";
}
.icon-paper-plane:before {
  content: "\e864";
}
.icon-attention-alt:before {
  content: "\e865";
}
.icon-info:before {
  content: "\e866";
}
.icon-compare-link:before {
  content: "\e867";
}
.icon-cat-default:before {
  content: "\e868";
}
.icon-cat-computer:before {
  content: "\e869";
}
.icon-cat-couch:before {
  content: "\e86a";
}
.icon-cat-garden:before {
  content: "\e86b";
}
.icon-cat-gift:before {
  content: "\e86c";
}
.icon-cat-shirt:before {
  content: "\e86d";
}
.icon-cat-sport:before {
  content: "\e86e";
}
.icon-cat-toys:before {
  content: "\e86f";
}
.icon-tag-line:before {
  content: "\e870";
}
.icon-bag:before {
  content: "\e871";
}
.icon-search-1:before {
  content: "\e872";
}
.icon-plus:before {
  content: "\e873";
}
.icon-minus:before {
  content: "\e874";
}
.icon-search-2:before {
  content: "\e875";
}
.icon-bag-1:before {
  content: "\e876";
}
.icon-online-support:before {
  content: "\e877";
}
.icon-shopping-bag:before {
  content: "\e878";
}
.icon-us-dollar:before {
  content: "\e879";
}
.icon-shipped:before {
  content: "\e87a";
}
.icon-list:before {
  content: "\e87b";
}
.icon-money:before {
  content: "\e87c";
}
.icon-shipping:before {
  content: "\e87d";
}
.icon-support:before {
  content: "\e87e";
}
.icon-bag-2:before {
  content: "\e87f";
}
.icon-grid:before {
  content: "\e880";
}
.icon-bag-3:before {
  content: "\e881";
}
.icon-direction:before {
  content: "\e882";
}
.icon-home:before {
  content: "\e883";
}
.icon-magnifier:before {
  content: "\e884";
}
.icon-magnifier-add:before {
  content: "\e885";
}
.icon-magnifier-remove:before {
  content: "\e886";
}
.icon-phone-1:before {
  content: "\e887";
}
.icon-clock-1:before {
  content: "\e888";
}
.icon-heart:before {
  content: "\e889";
}
.icon-heart-1:before {
  content: "\e88a";
}
.icon-earphones-alt:before {
  content: "\e88b";
}
.icon-credit-card:before {
  content: "\e88c";
}
.icon-action-undo:before {
  content: "\e88d";
}
.icon-envolope:before {
  content: "\e88e";
}
.icon-chart:before {
  content: "\e88f";
}
.icon-category-home:before {
  content: "\e900";
}
.icon-category-motors:before {
  content: "\e901";
}
.icon-category-music:before {
  content: "\e902";
}
.icon-category-gifts:before {
  content: "\e903";
}
.icon-category-electronics:before {
  content: "\e904";
}
.icon-category-fashion:before {
  content: "\e905";
}
.icon-category-hot-deals:before {
  content: "\e906";
}
.icon-tag-percent:before {
  content: "\e907";
}
.icon-joystick:before {
  content: "\e908";
}
.icon-category-furniture:before {
  content: "\e909";
}
.icon-category-garden:before {
  content: "\e90a";
}
.icon-category-lanterns-lighting:before {
  content: "\e90b";
}
.icon-category-mechanics:before {
  content: "\e90c";
}
.icon-category-motorcycles:before {
  content: "\e90d";
}
.icon-category-sound-video:before {
  content: "\e90e";
}
.icon-category-steering:before {
  content: "\e90f";
}
.icon-category-external-accessories:before {
  content: "\e910";
}
.icon-category-fluids:before {
  content: "\e911";
}
.icon-category-internal-accessories:before {
  content: "\e912";
}
.icon-category-chains:before {
  content: "\e913";
}
.icon-category-frames:before {
  content: "\e914";
}
.icon-category-pedals:before {
  content: "\e915";
}
.icon-category-saddle:before {
  content: "\e916";
}
.icon-category-tools:before {
  content: "\e917";
}
.icon-search-3:before {
  content: "\e918";
}
.icon-secure-payment:before {
  content: "\e919";
}
.icon-user-2:before {
  content: "\e91a";
}
.icon-wishlist-2:before {
  content: "\e91b";
}
.icon-gift-2:before {
  content: "\e91c";
}
.icon-edit:before {
  content: "\e91d";
}
.icon-chef:before {
  content: "\e91e";
}
.icon-smiling-girl:before {
  content: "\e91f";
}
.icon-tshirt:before {
  content: "\e920";
}
.icon-boy-broad-smile:before {
  content: "\e921";
}
.icon-smiling-baby:before {
  content: "\e922";
}
.icon-bars:before {
  content: "\e923";
}
.icon-twitter:before {
  content: "\f099";
}
.icon-facebook:before {
  content: "\f09a";
}
.icon-spinner:before {
  content: "\f110";
}
.icon-instagram:before {
  content: "\f16d";
}
.icon-check-empty:before {
  content: "\f87a";
}
.icon-check:before {
  content: "\f87b";
}
.icon-shopping-cart:before {
  content: "\e8ba";
}
.icon-phone-2:before {
  content: "\e8bb";
}

form {
  margin-bottom: 3.5rem;
}
form h2 {
  margin-top: 3.4rem;
  margin-bottom: 2.3rem;
}

.form-group {
  margin-bottom: 1.4rem;
}
.form-group .form-control,
.form-group .select-custom {
  margin-bottom: 0;
}
.form-group .form-control + .form-control {
  margin-top: 1rem;
}

.form-group-sm {
  max-width: 480px;
  margin-bottom: 0.9rem;
}

.form-control {
  height: 6rem;
  margin-bottom: 1rem;
  padding: 2rem;
  transition: all 0.3s;
  border: 1px solid #dfdfdf;
  border-radius: 0;
  background-color: #fff;
  color: #777;
  font-family: Euclid, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1.5;
}
.form-control:focus {
  border-color: #dfdfdf;
  background-color: #fff;
  color: #777;
  box-shadow: none;
}

.form-control-sm {
  height: 4.6rem;
  padding: 0.8rem 1.2rem;
}

select.form-control:not([size]):not([multiple]) {
  height: 6rem;
}

select.form-control-sm:not([size]):not([multiple]) {
  height: 4.6rem;
}

.main .form-control::-webkit-input-placeholder,
.main .form-control::-moz-placeholder,
.main .form-control:-ms-input-placeholder,
.main .form-control::-ms-input-placeholder,
.main .form-control::placeholder {
  font-family: Euclid, sans-serif;
}

textarea.form-control {
  min-height: 112px;
}

.form-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 2rem;
  margin-bottom: 3.5rem;
  font-size: 1.2rem;
  vertical-align: middle;
}
.form-footer .form-footer-right {
  margin-right: 0;
  margin-left: auto;
}
.form-footer .form-footer-right .btn {
  margin: 0;
}
.form-footer .btn {
  margin-right: 3rem;
}

.forget-pass {
  line-height: 1.3;
}

label {
  margin: 0 0 0.6rem;
  color: #000;
  font-family: Euclid, sans-serif;
  font-size: 1.2rem;
  font-weight: 700;
}

.required-field > label:after {
  margin: 0 0 0 0.45rem;
  color: #e02b27;
  font-size: 1.2rem;
  content: "*";
}

.required {
  color: #e02b27;
  font-size: 1.2rem;
}
.required + .form-footer {
  margin-top: 0.8rem;
}

.select-custom {
  position: relative;
  margin-bottom: 1rem;
  background: #fff;
}
.select-custom select {
  -webkit-appearance: none;
  -moz-appearance: none;
  -ms-appearance: none;
  position: relative;
  z-index: 1;
  background: transparent;
}
.select-custom select:focus {
  background: transparent;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .select-custom select::-ms-expand {
    display: none;
  }
  .select-custom select:focus::-ms-value {
    background: transparent;
    color: currentColor;
  }
}
.select-custom:after {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 1.9rem;
  transform: translateY(-50%);
  font-family: "porto";
  font-size: 1.5rem;
  content: "\e81c";
}
.select-custom .form-control,
.select-custom .form-control-sm {
  padding-right: 4rem;
}

.form-group-custom-control {
  margin-top: 2rem;
}
.form-group-custom-control .custom-control {
  margin-top: 0;
  margin-bottom: 2rem;
}
.form-group-custom-control + .form-group-custom-control {
  margin-top: -0.5rem;
}
.form-group-custom-control .custom-control-label {
  font-family: Euclid, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  text-transform: initial;
}

.custom-control {
  position: relative;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-left: 3rem;
}

.custom-control-label {
  position: static;
  margin-top: 0.1rem;
  margin-bottom: 0;
}
.custom-control-label:before, .custom-control-label:after {
  top: 0;
  left: 0;
  width: 2rem;
  height: 2rem;
}
.custom-control-label:before {
  border: 1px solid #000;
  background-color: #fff;
}

.custom-checkbox .custom-control-label:before {
  border-radius: 0;
}

.custom-checkbox .custom-control-input:checked ~ .custom-control-label:before {
  background-color: #e1ddc3;
}

.custom-checkbox .custom-control-input:indeterminate ~ .custom-control-label:before {
  background-color: #e1ddc3;
}

.custom-checkbox .custom-control-input:disabled:checked ~ .custom-control-label:before {
  background-color: rgba(225, 221, 195, 0.5);
}

.custom-checkbox .custom-control-input:disabled:indeterminate ~ .custom-control-label:before {
  background-color: rgba(225, 221, 195, 0.5);
}

.form-control-tooltip {
  position: relative;
  padding-right: 4rem;
}
.form-control-tooltip .input-tooltip {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transition: all 0.3s;
  color: #777;
  font-size: 2.4rem;
}
.form-control-tooltip .input-tooltip:hover, .form-control-tooltip .input-tooltip:focus {
  color: #000;
}

.form-input {
  padding: 1rem 1.2rem;
  border: 1px solid #ddd;
  border-radius: 2px;
}

.form-wide {
  width: 100%;
}

@media (min-width: 768px) {
  form,
  .form-footer {
    margin-bottom: 4rem;
  }
  form h2 {
    margin-top: 4.4rem;
  }
}
@media (min-width: 992px) {
  form,
  .form-footer {
    margin-bottom: 5rem;
  }
}
.info-box {
  display: flex;
  align-items: center;
  width: 100%;
  color: #222529;
  padding: 2.5rem 2.4rem 2rem;
}
.info-box i {
  color: inherit;
  font-size: 3.7rem;
  text-align: center;
}
.info-box i:before {
  width: auto;
  margin: 0 0.1em;
}
.info-box h4 {
  color: inherit;
  margin-bottom: 0;
  font-size: 1.4rem;
  font-weight: 700;
  text-transform: uppercase;
  line-height: 1.1;
}
.info-box p {
  margin-bottom: 0;
  font-size: 1.3rem;
  letter-spacing: 0.01em;
  line-height: 1.6;
}

.info-box-icon-left {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  -ms-flex-pack: center;
}
.info-box-icon-left i {
  line-height: 0;
  margin-right: 1.4rem;
}

.modal form {
  margin: 0;
}
.modal form .form-group {
  max-width: 480px;
}
.modal form .form-control {
  max-width: 100%;
}

.modal-body {
  padding: 1.5rem;
}

.modal-content {
  border-radius: 0;
  box-shadow: 0 0 12px 2px rgba(0, 0, 0, 0.35);
}

.modal-header,
.modal-footer {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 80px;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
}

.modal-title {
  font-weight: 400;
}

.close {
  font-size: 2.2rem;
}

@media (min-width: 576px) {
  .modal-content {
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .modal-body {
    max-height: calc(100vh - 210px);
    padding-top: 2rem;
    overflow-y: auto;
  }
  .modal-header,
  .modal-footer,
  .modal-body {
    padding-right: 2em;
    padding-left: 2rem;
  }
}
@media (min-width: 992px) {
  .modal-dialog {
    max-width: 800px;
  }
  .modal-header,
  .modal-footer,
  .modal-body {
    padding-right: 3rem;
    padding-left: 3rem;
  }
}
@media (max-width: 479px) {
  .modal-open,
  .modal-open .modal {
    padding-right: 0 !important;
  }
}
.page-header {
  padding: 4.4rem 0 4.5rem;
  color: #fff;
  text-align: center;
}
.breadcrumb-nav + .page-header {
  margin-top: -2rem;
}

.page-header h1,
.page-title {
  margin-bottom: 0;
  color: #fff;
  font-family: Euclid, sans-serif;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .page-header {
    padding-top: 7rem;
    padding-bottom: 7rem;
  }
  .breadcrumb-nav + .page-header {
    margin-top: -3rem;
  }
}
@media (min-width: 992px) {
  .page-header {
    padding-top: 9.2rem;
    padding-bottom: 9rem;
  }
  .breadcrumb-nav + .page-header {
    margin-top: -4rem;
  }
}
.toolbox {
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  justify-content: space-between;
  -ms-flex-pack: justify;
  margin-bottom: 0.5rem;
  font-size: 1.2rem;
  line-height: 1.5;
}
.toolbox .select-custom:after {
  right: 1.5rem;
  margin-top: -1px;
  font-size: 1.6rem;
}
.toolbox .select-custom .form-control {
  max-width: 160px;
  padding-right: 2.5rem;
  font-size: 1.2rem;
}
.toolbox label {
  margin: 1px 1.2rem 0 0;
  color: #777;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: Euclid, sans-serif;
}
.toolbox .form-control {
  display: inline-block;
  margin-bottom: 0;
  padding: 0 0.8rem;
  color: #777;
}
.toolbox .form-control:focus {
  color: #777;
}
.toolbox select.form-control:not([size]):not([multiple]) {
  height: 34px;
}
.toolbox .toolbox-show .select-custom:after {
  right: 1rem;
}
.toolbox .toolbox-show:not(:last-child) {
  margin-right: 1.6rem;
}

.toolbox,
.toolbox-left,
.toolbox-right,
.toolbox-item {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.toolbox-item {
  margin-bottom: 10px;
}
.toolbox-item:not(:last-child) {
  margin-right: 10px;
}
.toolbox-item.toolbox-sort {
  margin-right: 1.5rem;
}
.toolbox-item .select-custom {
  margin-bottom: 0;
}

.toolbox-pagination {
  border-top: 1px solid #efefef;
  padding-top: 2.5rem;
  margin-bottom: 3.5rem;
}

.pagination,
.ngx-pagination {
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  color: #706f6c;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: Euclid, sans-serif;
  margin-left: auto;
}

.ngx-pagination li:not(:first-child) {
  margin-left: 0.5rem;
}
.ngx-pagination li.current {
  color: #706f6c;
  background-color: #e1ddc3;
  border: 1px solid #ccc;
  min-width: 2.2em;
  color: inherit;
  line-height: 2.1em;
  text-align: center;
  padding: 0 0.5em;
}
.ngx-pagination li.disabled {
  display: none;
}
.ngx-pagination a {
  border: 1px solid #ccc;
  padding: 0 0.5em !important;
  min-width: 2.2em;
  color: inherit !important;
  line-height: 2.1em;
  text-align: center;
}
.ngx-pagination a:hover, .ngx-pagination a:focus {
  color: #706f6c;
  background-color: transparent;
  border-color: #e1ddc3;
  box-shadow: none;
}
.ngx-pagination a i {
  font-size: 2rem;
}
.ngx-pagination .pagination-next a,
.ngx-pagination .pagination-previous a,
.ngx-pagination .ellipsis a {
  border: 0;
}
.ngx-pagination .pagination-next a:hover,
.ngx-pagination .pagination-previous a:hover,
.ngx-pagination .ellipsis a:hover {
  background: transparent !important;
}
.ngx-pagination .pagination-next a::after,
.ngx-pagination .pagination-previous a::after,
.ngx-pagination .ellipsis a::after {
  font-family: "porto";
  font-style: normal;
  font-weight: normal;
  speak: none;
  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  text-align: center;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
}
.ngx-pagination .pagination-next a:after {
  content: "\e81a" !important;
}
.ngx-pagination .pagination-previous a:after {
  content: "\e819" !important;
}
.ngx-pagination .pagination-previous a::before {
  content: none !important;
}

.page-item {
  margin-bottom: 0.5rem;
}
.page-item:not(:first-child) {
  margin-left: 0.5rem;
}
.page-item.active .page-link {
  color: #706f6c;
  background-color: transparent;
  border-color: #e1ddc3;
}
.page-item.disabled {
  display: none;
}

.page-link {
  border: 1px solid #ccc;
  padding: 0 0.5em;
  min-width: 2.2em;
  color: inherit;
  line-height: 2.1em;
  text-align: center;
}
.page-link:hover, .page-link:focus {
  color: #706f6c;
  background-color: transparent;
  border-color: #e1ddc3;
  box-shadow: none;
}
.page-link i {
  font-size: 2rem;
}

span.page-link,
.page-link-btn {
  border: 0;
}

.layout-btn {
  display: inline-block;
  width: 1.2em;
  color: #000;
  font-size: 1.6rem;
  line-height: 34px;
  text-align: center;
}
.layout-btn:not(:last-child) {
  margin-right: 4px;
}
.layout-btn.active {
  color: #e1ddc3 !important;
}

@media (min-width: 992px) {
  .toolbox-pagination {
    margin-bottom: 4rem;
  }
}
@media (max-width: 991px) {
  aside .toolbox-item {
    display: block;
  }
  aside .toolbox-item:after {
    content: normal;
  }
}
@media (max-width: 767px) {
  .toolbox label {
    display: none;
  }
}
.login-popup .mfp-content {
  width: 80%;
  max-width: 872px;
  background-color: #fff;
}
.login-popup .container {
  padding: 2.5rem 3rem;
}
.login-popup .title {
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2;
  text-transform: uppercase;
}
.login-popup form {
  display: block;
}
.login-popup label {
  color: #777;
  font-family: Euclid, sans-serif;
  font-size: 1.2rem;
  font-weight: 400;
  line-height: 1;
  text-transform: capitalize;
}
.login-popup .form-footer {
  margin: 0 0 2rem;
}
.login-popup .form-footer .custom-control {
  margin: 0 0 0 auto;
  font-size: 1.3rem;
}
.login-popup .forget-password {
  color: #e1ddc3;
  font-size: 1.3rem;
}
.login-popup .social-login-wrapper {
  padding: 2rem 2rem 2.5rem;
  background-color: #f4f4f2;
  text-align: center;
}
.login-popup .social-login-wrapper p {
  margin-bottom: 2rem;
  color: #121214;
  font-size: 12px;
  font-weight: 600;
  text-transform: uppercase;
}
.login-popup .btn-group {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-pack: distribute;
  justify-content: space-around;
}

.newsletter-popup {
  position: relative;
  width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding: 30px 30px 10px;
  border-radius: 0;
}

.mfp-close {
  text-indent: -9999px;
  -webkit-transform: rotateZ(45deg);
  transform: rotateZ(45deg);
  width: 41px;
  height: 41px;
  color: #838383;
}
.mfp-close:hover {
  opacity: 1;
}
.mfp-image-holder .mfp-close {
  width: 41px;
  color: #fff;
  text-align: left;
}
.mfp-close:after {
  content: "";
  position: absolute;
  height: 17px;
  top: 12px;
  left: 20px;
  border-left: 1px solid;
}
.mfp-close:before {
  content: "";
  position: absolute;
  width: 17px;
  top: 20px;
  left: 12px;
  border-top: 1px solid;
}

.newsletter-popup-content {
  max-width: 332px;
  text-align: center;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.newsletter-popup-content .form-control {
  height: auto;
  padding: 7px 12px;
  border-radius: 5px 0 0 5px;
  font-size: 13px;
  border-color: #ccc;
}
.newsletter-popup-content .btn {
  margin-left: -1px;
  padding: 0 12px;
  border: 1px solid #ccc;
  border-radius: 0 5px 5px 0;
  background-color: transparent;
  color: #555;
  font-size: 1.28rem;
  font-weight: 400;
  font-family: Euclid, sans-serif;
  text-align: center;
  text-transform: uppercase;
}
.newsletter-popup-content .btn:hover {
  background-color: #e6e6e6;
  border-color: rgba(0, 0, 0, 0.06);
}
.newsletter-popup-content img {
  width: 100px;
}

.logo-newsletter {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.newsletter-popup h2 {
  margin: 24px 0 16px;
  color: #313131;
  font-size: 16px;
  font-weight: 700;
  line-height: 2;
}

.newsletter-popup p {
  font-size: 1.4rem;
  line-height: 1.43;
  letter-spacing: 0.025em;
}

.newsletter-popup form {
  margin: 0 0 2.1rem;
}

.newsletter-subscribe {
  font-size: 1.1rem;
  text-align: left;
}
.newsletter-subscribe .checkbox {
  margin: 1.5rem 0;
}
.newsletter-subscribe input {
  margin-right: 0.5rem;
  vertical-align: middle;
}
.newsletter-subscribe label {
  color: inherit;
  font-size: 1.1rem;
  font-weight: 400;
  font-family: Euclid, sans-serif;
  text-transform: none;
}

.mfp-newsletter.mfp-removing {
  transition: opacity 0.35s ease-out;
  opacity: 0;
}

.mfp-ready .mfp-preloader {
  display: none;
}

.mfp-zoom-out-cur .mfp-bg {
  opacity: 0.8;
  background-color: #0b0b0b;
}
.mfp-zoom-out-cur .mfp-counter {
  color: #fff;
}
.mfp-zoom-out-cur .mfp-arrow-right:before {
  border-left: 0;
}
.mfp-zoom-out-cur .mfp-arrow-left:before {
  border-right: 0;
}

.mfp-ajax-product.mfp-bg,
.login-popup.mfp-bg {
  opacity: 0;
}

.mfp-ajax-product .product-single-container {
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.5);
}

.mfp-wrap .mfp-content {
  transition: all 0.35s ease-out;
  opacity: 0;
}

.mfp-ajax-product.mfp-wrap .mfp-content,
.login-popup.mfp-wrap .mfp-content {
  max-width: 872px;
}

.mfp-wrap.mfp-ready .mfp-content {
  opacity: 1;
}

.mfp-wrap.mfp-removing .mfp-content {
  opacity: 0;
}

.bg {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1042;
  overflow: hidden;
  position: fixed;
  background: #fff;
  opacity: 0.4;
}

.newsletter__container {
  text-align: center;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0 8px;
  box-sizing: border-box;
  transition: all 0.35s ease-out;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1043;
}
.newsletter__container .newsletter-popup {
  width: 70%;
}

@media (max-width: 479px) {
  .newsletter__container .newsletter-popup {
    width: 90%;
  }
}
@media (max-width: 991px) {
  .mfp-container {
    padding: 2rem;
  }
}
@media (min-width: 768px) {
  .login-popup .col-md-6 {
    padding: 0 2rem;
  }
  .login-popup .col-md-6:first-child {
    border-right: 1px solid #f5f6f6;
  }
}
.product-intro.owl-carousel.owl-theme .owl-nav.disabled + .owl-dots {
  margin: 0;
}
.product-intro.owl-carousel.owl-theme .owl-dots {
  top: -58px;
  position: absolute;
  right: 0;
}
.product-intro.owl-carousel.owl-theme .owl-dots .owl-dot span {
  position: relative;
  display: block;
  width: 14px;
  height: 14px;
  border: 2px solid;
  background: none;
  margin: 5px 2px;
  border-radius: 7px;
  border-color: rgba(0, 68, 102, 0.4);
  transition: opacity 0.2s;
}
.product-intro.owl-carousel.owl-theme .owl-dots .owl-dot.active span, .product-intro.owl-carousel.owl-theme .owl-dots .owl-dot:hover span {
  background: none;
  border-color: #e1ddc3;
}
.product-intro.owl-carousel.owl-theme .owl-dots .owl-dot.active span:before, .product-intro.owl-carousel.owl-theme .owl-dots .owl-dot:hover span:before {
  display: none;
}
.product-intro.owl-carousel.owl-theme .owl-dots .owl-dot.active span:after, .product-intro.owl-carousel.owl-theme .owl-dots .owl-dot:hover span:after {
  content: "";
  position: absolute;
  left: 3px;
  bottom: 3px;
  right: 3px;
  top: 3px;
  border-radius: 10px;
  background-color: #e1ddc3;
}
.product-intro.owl-carousel.owl-theme .owl-nav {
  color: #333;
  font-size: 2.4rem;
}
.product-intro.owl-carousel.owl-theme .owl-nav .owl-prev,
.product-intro.owl-carousel.owl-theme .owl-nav .owl-next {
  opacity: 0;
  transition: opacity 0.2s, transform 0.4s;
  top: 30%;
  width: 30px;
}
.product-intro.owl-carousel.owl-theme .owl-nav .owl-prev {
  text-align: left;
  left: -30px;
  padding-right: 30px;
  transform: translateX(-10px);
}
.product-intro.owl-carousel.owl-theme .owl-nav .owl-next {
  text-align: right;
  right: -30px;
  padding-left: 30px;
  transform: translateX(10px);
}
.product-intro.owl-carousel.owl-theme:hover .owl-prev,
.product-intro.owl-carousel.owl-theme:hover .owl-next {
  transform: translateX(0);
  opacity: 1;
}

.product-panel {
  margin-bottom: 3.5rem;
}
.product-panel .section-title {
  color: #313131;
  padding-bottom: 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  margin-bottom: 2.4rem;
}
.product-panel .section-title h2 {
  font-weight: 700;
  font-size: 1.6rem;
  font-family: Euclid, sans-serif;
  letter-spacing: -0.01em;
  line-height: 22px;
  text-transform: uppercase;
}

.product-default {
  color: #777;
  margin-bottom: 2rem;
}
.product-default a {
  color: inherit;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.product-default a:hover {
  color: #e1ddc3;
  text-decoration: none;
}
.product-default figure {
  margin-bottom: 1.6rem;
  position: relative;
  color: #fff;
}
.product-default figure img {
  transition: all 0.5s;
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: none;
}
.product-default figure img:last-child {
  opacity: 0;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  left: 0;
  display: block;
}
.product-default figure img:first-child {
  opacity: 1;
  position: relative;
  display: block;
}
.product-default figure img.bf-badge {
  width: 40%;
  height: auto;
  position: absolute;
  z-index: 1;
  right: 0;
}
.product-default .label-group {
  position: absolute;
  top: 0.8rem;
  left: 0.8rem;
}
.product-default .product-label {
  display: block;
  text-align: center;
  margin-bottom: 5px;
  text-transform: uppercase;
  padding: 5px 11px;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  line-height: 1;
}
.product-default .product-label.label-hot {
  background-color: #62b959;
}
.product-default .product-label.label-sale {
  background-color: #e27c7c;
}
.product-default .product-details {
  display: flex;
  display: -ms-flexbox;
  flex-direction: column;
  -ms-flex-direction: column;
  align-items: center;
  -ms-flex-align: center;
  justify-content: center;
  -ms-flex-pack: center;
}
.product-default .category-list {
  font-weight: 400;
  font-size: 1rem;
  font-family: Euclid, sans-serif;
  line-height: 1.7;
  opacity: 0.8;
  text-transform: uppercase;
  display: flex;
  flex-wrap: wrap;
}
.product-default .category-list a {
  margin-bottom: 0;
}
.product-default .product-title {
  max-width: 100%;
  font-weight: 400;
  font-size: 1.5rem;
  font-family: Euclid, sans-serif;
  line-height: 1.35;
  letter-spacing: 0.005em;
  margin-bottom: 0.72rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
.product-default .tooltiptext {
  visibility: hidden;
  position: absolute;
  background-color: #333;
  color: #fff;
  font-family: Euclid, sans-serif;
  font-weight: 400;
  letter-spacing: 0.01em;
  text-align: center;
  padding: 1rem 0.7rem;
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s;
  bottom: 125%;
  left: 50%;
  transform: translateX(-50%);
}
.product-default .product-action {
  color: #333;
  text-align: center;
}
.product-default .btn-icon-wish,
.product-default .btn-quickview {
  display: inline-block;
  border: 1px solid #ddd;
  margin: 0 2px;
  width: 36px;
  height: 36px;
  font-size: 1.6rem;
  text-align: center;
  opacity: 0;
  transition: all 0.25s ease;
  transform: translateX(200%);
}
.product-default .btn-icon-wish.checked,
.product-default .btn-quickview.checked {
  color: #e27c7c;
}
.product-default .btn-icon-wish.checked i:before,
.product-default .btn-quickview.checked i:before {
  content: "\e88a";
}
.product-default .btn-quickview {
  font-size: 1.4rem;
  transform: translateX(-200%);
}
.product-default .btn-add-cart {
  background: #fff;
  color: #333;
  border: 1px solid #ddd;
  padding: 0 1rem;
  font-size: 1.2rem;
  font-weight: 600;
  font-family: Euclid, sans-serif;
  line-height: 34px;
  text-align: center;
  vertical-align: top;
  cursor: pointer;
  transition: all 0.25s ease;
}
.product-default .btn-add-cart i {
  font-size: 1.5rem;
  margin-bottom: 2px;
  line-height: 0;
}
.product-default .btn-add-cart i:before {
  margin: 0 4px 0 0;
}
.product-default:hover {
  z-index: 2;
}
.product-default:hover figure {
  box-shadow: 0 25px 35px -5px rgba(0, 0, 0, 0.1);
}
.product-default:hover figure img:first-child {
  opacity: 0;
}
.product-default:hover figure img:last-child {
  opacity: 1;
}
.product-default:hover .btn-add-cart {
  background-color: #e1ddc3;
  border-color: #e1ddc3;
  color: #fff;
}
.product-default:hover .product-action a {
  transform: translateX(0);
  opacity: 1;
}

.tooltip-top:after {
  content: "";
  position: absolute;
  top: 96%;
  left: 50%;
  margin-left: -6px;
  border-width: 6px;
  border-style: solid;
  border-color: #333 transparent transparent transparent;
}

.old-price {
  text-decoration: line-through;
  font-size: 1.4rem;
  letter-spacing: 0.005em;
  color: #999;
  margin-right: 3px;
  color: #222529;
}

.product-price {
  color: #222529;
  font-size: 1.8rem;
  line-height: 0.8;
  margin-right: 8px;
}
.product-price.reduced {
  color: #F44336;
}

.price-box {
  margin-bottom: 1.5rem;
  font-weight: 600;
  font-family: Euclid, sans-serif;
  line-height: 1;
}

.ratings-container {
  line-height: 1;
  margin: 0 0 10px 1px;
  cursor: pointer;
  position: relative;
  display: inline-block;
}
.ratings-container .product-ratings,
.ratings-container .ratings {
  position: relative;
  display: inline-block;
  font-size: 11px;
  letter-spacing: 0.1em;
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
}
.ratings-container .product-ratings {
  height: 11px;
}
.ratings-container .product-ratings:hover .tooltiptext {
  visibility: visible;
  opacity: 1;
}
.ratings-container .product-ratings .star {
  position: relative;
  display: inline-block;
  font-size: 16px;
  color: #d3d3d3;
}
.ratings-container .product-ratings .full {
  color: #f3b804;
}
.ratings-container .product-ratings .half {
  position: absolute;
  display: inline-block;
  overflow: hidden;
  color: #f3b804;
}
.ratings-container .product-ratings .rate ngb-rating {
  margin-top: 20px;
  margin-right: 16px;
}
.ratings-container .product-ratings ngb-rating .ng-star-inserted {
  color: #f3b804;
}
.ratings-container .ratings {
  position: absolute;
  top: 0;
  left: 0;
  white-space: nowrap;
  overflow: hidden;
}
.ratings-container .ratings:before {
  content: "\f005\f005\f005\f005\f005";
  color: #6a6a6d;
}

.product-select-group {
  display: flex;
  display: -ms-flexbox;
  width: 100%;
}

.product-select {
  margin: 0 4px 0 0;
  cursor: pointer;
}
.product-select.type-image {
  width: 32px;
  height: 32px;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.09);
}
.product-select.type-image.checked, .product-select.type-image.hover {
  border: 1px solid #e1ddc3;
}
.product-select.two-colors {
  display: flex;
}
.product-select.two-colors span {
  flex: 1;
}
.product-select.type-check {
  margin: 5px;
  overflow: visible;
  display: block;
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.product-select.type-check:after {
  content: "";
  background-color: transparent;
  border: 1px solid black;
  position: absolute;
  left: -3px;
  top: -3px;
  border-radius: 50%;
  width: 18px;
  display: block;
  height: 18px;
}
.product-select.type-check.checked:before {
  font-size: 8px;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  text-indent: 0;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  color: #fff;
  height: 12px;
  line-height: 12px;
  margin-top: -6px;
  text-align: center;
}

.product-single-qty {
  display: inline-block;
  max-width: 104px;
  vertical-align: middle;
}
.product-single-qty .bootstrap-touchspin.input-group {
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  max-width: none;
  padding-right: 0;
}
.product-single-qty .bootstrap-touchspin .form-control {
  width: 2.5em;
  height: 36px;
  padding: 10px 2px;
  color: #222529;
  font-size: 1.4rem;
  font-family: Euclid, sans-serif;
}
.product-single-qty .bootstrap-touchspin .form-control,
.product-single-qty .bootstrap-touchspin .form-control:not(:focus),
.product-single-qty .btn-outline:not(:disabled):not(.disabled):active {
  border-color: #dae2e6;
}
.product-single-qty .btn {
  width: 2em;
  padding: 0;
}
.product-single-qty .btn.btn-down-icon:hover:before, .product-single-qty .btn.btn-down-icon:hover:after,
.product-single-qty .btn.btn-up-icon:hover:before,
.product-single-qty .btn.btn-up-icon:hover:after {
  background-color: #e1ddc3;
}
.product-single-qty .btn.btn-outline {
  border-color: #dae2e6;
}
.product-single-qty .btn.btn-down-icon:after,
.product-single-qty .btn.btn-up-icon:before,
.product-single-qty .btn.btn-up-icon:after {
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 11px;
  height: 1px;
  margin-left: -0.55rem;
  background: #222529;
  content: "";
}
.product-single-qty .btn.btn-up-icon:before {
  transform: rotate(90deg);
}

.config-swatch-list {
  margin: 1.5rem 0 0;
  padding: 0;
  font-size: 0;
  list-style: none;
}
.config-swatch-list li a {
  position: relative;
  display: block;
  width: 2.6rem;
  height: 2.6rem;
  margin: 3px 6px 3px 0;
  box-shadow: 0 0 3px 0 rgba(0, 0, 0, 0.2);
}
.config-swatch-list li .color-panel {
  display: inline-block;
  width: 1.7rem;
  height: 1.7rem;
  border: 1px solid #fff;
  transition: all 0.3s;
  margin-right: 1.5rem;
}
.config-swatch-list li span:last-child {
  cursor: pointer;
}
.config-swatch-list li:hover span:last-child {
  color: #e1ddc3;
}
.config-swatch-list li.active a:before {
  display: inline-block;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  color: #fff;
  font-family: "porto";
  font-size: 1.1rem;
  line-height: 1;
  content: "\e84e";
}
.config-swatch-list a:hover .color-panel,
.config-swatch-list a:focus .color-panel,
.config-swatch-list li.active .color-panel {
  box-shadow: 0 0 0 0.1rem #dfdfdf;
}

.modal#addCartModal {
  width: 340px;
  top: calc((100% - 320px) / 2);
  left: calc((100% - 320px) / 2);
  padding: 10px !important;
  overflow: hidden;
  opacity: 1;
  display: block;
}
.modal#addCartModal .modal-dialog {
  margin: 0;
}
.modal#addCartModal .modal-content {
  margin: 0;
  border: none;
  box-shadow: none;
}

.add-cart-box {
  padding: 19px 10px 20px !important;
  border-top: 4px solid #e1ddc3;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.6);
}
.add-cart-box h4 {
  font-weight: 500;
  color: #e1ddc3;
  margin-bottom: 1.8rem;
}
.add-cart-box img {
  margin: 0 auto 10px;
  width: 120px;
}
.add-cart-box .btn-actions {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-around;
  -ms-flex-pack: distribute;
}
.add-cart-box .btn-actions .btn-primary {
  width: 140px;
  padding: 8px 0;
  font: 500 16px Euclid, sans-serif;
  border: none;
  cursor: pointer;
}
.add-cart-box .btn-actions .btn-primary:focus, .add-cart-box .btn-actions .btn-primary:active, .add-cart-box .btn-actions .btn-primary:active:focus {
  outline: none;
  border: none;
  box-shadow: none;
}

.divide-line > .col-1:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-1:nth-child(12n) {
  border-right: none;
}
.divide-line > .col-2:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-2:nth-child(6n) {
  border-right: none;
}
.divide-line > .col-3:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-3:nth-child(4n) {
  border-right: none;
}
.divide-line > .col-4:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-4:nth-child(3n) {
  border-right: none;
}
.divide-line > .col-5:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-5:nth-child(2n) {
  border-right: none;
}
.divide-line > .col-6:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-6:nth-child(2n) {
  border-right: none;
}
.divide-line > .col-7:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-7:nth-child(1n) {
  border-right: none;
}
.divide-line > .col-8:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-8:nth-child(1n) {
  border-right: none;
}
.divide-line > .col-9:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-9:nth-child(1n) {
  border-right: none;
}
.divide-line > .col-10:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-10:nth-child(1n) {
  border-right: none;
}
.divide-line > .col-11:nth-child(n) {
  border-right: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.divide-line > .col-11:nth-child(1n) {
  border-right: none;
}
.divide-line:not(.up-effect) .product-default .btn-quickview {
  width: calc(100% - 30px);
  margin: 0 15px;
}
.divide-line:not(.up-effect) .product-default .product-details {
  padding: 0 1.5rem;
}
.divide-line.up-effect {
  margin-top: -2rem;
}
.divide-line.up-effect .product-default {
  padding-top: 5rem;
  margin: 0;
  transition: 0.3s;
}
.divide-line.up-effect .product-default .product-action {
  transition: 0.3s;
  opacity: 0;
}
.divide-line.up-effect .product-default:hover {
  padding-top: 1rem;
  padding-bottom: 4rem;
}
.divide-line.up-effect .product-default:hover .product-action {
  opacity: 1;
}
.divide-line .product-default {
  margin-bottom: 0;
}
.divide-line .product-default:hover {
  box-shadow: 0 25px 35px -5px rgba(0, 0, 0, 0.1);
}
.divide-line .product-default:hover figure {
  box-shadow: none;
}

.inner-quickview figure {
  position: relative;
}
.inner-quickview figure .btn-quickview {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 1.4rem;
  color: #fff;
  background-color: #9E9993;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.025em;
  font-family: Euclid, sans-serif;
  text-transform: uppercase;
  visibility: hidden;
  opacity: 0;
  transform: none;
  margin: 0;
  border: none;
  transition: 0.25s;
}
.inner-quickview figure .btn-quickview:hover {
  opacity: 1;
}
.inner-quickview .product-details {
  align-items: flex-start;
  -ms-flex-align: start;
}
.inner-quickview .category-wrap {
  display: flex;
  display: -ms-flexbox;
  justify-content: space-between;
  -ms-flex-pack: justify;
  align-items: center;
  -ms-flex-align: center;
  width: 100%;
}
.inner-quickview .category-wrap .btn-icon-wish {
  transform: none;
  opacity: 1;
  width: auto;
  height: auto;
  border: none;
  overflow: visible;
  font-size: 1.5rem;
  line-height: 0;
}
.inner-quickview .category-wrap .btn-icon-wish .fas.fa-heart {
  color: #F44336;
}
.inner-quickview:hover .btn-quickview {
  visibility: visible;
  opacity: 0.95;
}

.inner-icon figure {
  position: relative;
}
.inner-icon figure .btn-icon-group {
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
}
.inner-icon figure .btn-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 50%;
  margin: 0 0 5px;
  width: 36px;
  height: 36px;
  padding: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
  transform: none;
}
.inner-icon figure .btn-icon i {
  font-size: 1.6rem;
  margin-bottom: 0;
}
.inner-icon figure .btn-icon i:before {
  margin: 0;
}
.inner-icon figure .btn-icon i.icon-bag {
  font-size: 1.8rem;
}
.inner-icon figure .btn-icon:hover {
  background-color: #e1ddc3;
  border-color: #e1ddc3;
  color: #fff;
}
.inner-icon:hover .btn-icon {
  background-color: #fff;
  border-color: #ddd;
  color: black;
  visibility: visible;
  opacity: 1;
}

.left-details .product-details {
  -ms-flex-align: start;
  align-items: flex-start;
}
.left-details .btn-icon-wish,
.left-details .btn-quickview,
.left-details .btn-add-cart {
  background-color: #f4f4f4;
  border-color: #f4f4f4;
  color: black;
}
.left-details .btn-icon-wish:hover,
.left-details .btn-quickview:hover,
.left-details .btn-add-cart:hover {
  background-color: #e1ddc3;
  border-color: #e1ddc3;
  color: #fff;
}
.left-details .btn-icon-wish,
.left-details .btn-quickview {
  transform: none;
}
.left-details .btn-add-cart {
  margin-left: 0;
  padding: 0 1.5rem;
}

.hidden-description {
  position: relative;
}
.hidden-description:hover figure {
  box-shadow: none;
}
.hidden-description:hover .btn-add-cart {
  background-color: #f4f4f4;
}
.hidden-description:hover .product-details {
  opacity: 1;
  transform: translateY(0);
}
.hidden-description figure {
  margin-bottom: 0;
}
.hidden-description figure .btn-icon-group {
  top: 1rem;
  right: 1rem;
}
.hidden-description .product-details {
  position: absolute;
  width: 100%;
  bottom: 46px;
  padding: 15px 20px 0;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  opacity: 0;
  transform: translateY(5px);
  transition: all 0.3s ease;
}
.hidden-description .product-action {
  position: absolute;
  left: 0;
  bottom: -46px;
  width: 100%;
}
.hidden-description .btn-quickview {
  transform: none;
  opacity: 0.85;
  background-color: #e1ddc3;
  color: #fff;
  width: 50%;
  margin: 0;
  border: none;
  height: 45px;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.025em;
  font-family: Euclid, sans-serif;
  text-transform: uppercase;
}
.hidden-description .btn-quickview:hover {
  opacity: 1;
  color: #fff;
}
.hidden-description .btn-add-cart {
  z-index: 3;
  justify-content: center;
  margin: 0;
  width: 50%;
  height: 45px;
  border: none;
  background: #f4f4f4;
  font-size: 1.3rem;
  font-weight: 400;
  letter-spacing: 0.025em;
  font-family: Euclid, sans-serif;
  text-transform: uppercase;
}
.hidden-description .btn-add-cart:hover {
  background-color: #e1ddc3;
  color: #fff;
}

.full-width {
  padding-left: 10px;
  padding-right: 10px;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
}
.full-width [class*=col-] {
  padding-right: 10px;
  padding-left: 10px;
}

.no-gaps {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  padding-right: 0;
}
.no-gaps [class*=col-] {
  padding-right: 0;
  padding-left: 0;
}
.no-gaps .product-details {
  padding: 0 1rem;
}
.no-gaps .product-default {
  margin-bottom: 0;
}
.no-gaps .product-default:nth-child(even) figure > a:first-child:after {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(33, 37, 41, 0.01);
}

.inner-icon-inline figure .btn-icon-group {
  display: flex;
  flex-direction: row;
}
.inner-icon-inline figure .btn-icon {
  margin-left: 5px;
}

.overlay figure {
  margin: 0;
}
.overlay figure > a:first-child:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(27, 27, 23, 0);
  transition: all 0.25s;
}
.overlay figure .btn-quickview,
.overlay figure .btn-icon-group {
  z-index: 1;
}
.overlay figure .btn-icon {
  border-color: #fff;
  border-width: 2px;
  color: #fff;
  background-color: #4d4d4a;
  opacity: 0;
}
.overlay .product-details {
  align-items: center;
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  opacity: 0;
  transform: scale(0.8);
  transition: all 0.4s;
}
.overlay .product-details .product-category,
.overlay .product-details .product-title a,
.overlay .product-details .product-price {
  color: #fff;
}
.overlay .product-details a:hover {
  color: #e1ddc3;
}
.overlay .product-details .ratings-container .product-ratings:before {
  color: rgba(255, 255, 255, 0.6);
}
.overlay .product-details .price-box {
  margin-bottom: 0;
}
.overlay:hover figure, .overlay:nth-child(even):hover figure {
  box-shadow: none;
}
.overlay:hover figure > a:first-child:after, .overlay:nth-child(even):hover figure > a:first-child:after {
  background-color: rgba(27, 27, 23, 0.6);
}
.overlay:hover figure .btn-quickview,
.overlay:hover figure .btn-icon, .overlay:nth-child(even):hover figure .btn-quickview,
.overlay:nth-child(even):hover figure .btn-icon {
  opacity: 0.85;
  visibility: visible;
}
.overlay:hover figure .btn-quickview:hover,
.overlay:hover figure .btn-icon:hover, .overlay:nth-child(even):hover figure .btn-quickview:hover,
.overlay:nth-child(even):hover figure .btn-icon:hover {
  opacity: 1;
}
.overlay:hover figure .btn-icon, .overlay:nth-child(even):hover figure .btn-icon {
  border-color: #fff;
  border-width: 2px;
  color: #fff;
  background-color: #4d4d4a;
  opacity: 0.85;
}
.overlay:hover .product-details, .overlay:nth-child(even):hover .product-details {
  opacity: 1;
  transform: scale(1);
}

.overlay-dark figure {
  margin: 0;
}
.overlay-dark figure > a:first-child:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(27, 27, 23, 0.3);
  transition: all 0.25s;
}
.overlay-dark figure .btn-quickview,
.overlay-dark figure .btn-icon-group {
  z-index: 1;
}
.overlay-dark figure .btn-icon {
  border-color: #fff;
  border-width: 2px;
  color: #fff;
  background-color: #4d4d4a;
  opacity: 0;
  margin-left: 8px;
}
.overlay-dark figure .btn-quickview {
  border: 2px solid #fff;
  background-color: #4d4d4a;
  border-radius: 2rem;
  padding: 1rem 2.3rem;
  width: auto;
  height: auto;
  left: 50%;
  bottom: 50%;
  transform: translate(-50%, 50%);
  opacity: 0;
  transition: all 0.1s;
}
.overlay-dark .product-details {
  position: absolute;
  width: 100%;
  left: 2rem;
  bottom: 4rem;
  opacity: 0;
  transform: translateY(10px);
  transition: all 0.4s;
}
.overlay-dark .product-details .product-category,
.overlay-dark .product-details .product-title a,
.overlay-dark .product-details .product-price {
  color: #fff;
}
.overlay-dark .product-details a:hover {
  color: #e1ddc3;
}
.overlay-dark .product-details .ratings-container .product-ratings:before {
  color: rgba(255, 255, 255, 0.6);
}
.overlay-dark .product-details .price-box {
  margin-bottom: 0;
}
.overlay-dark:hover figure, .overlay-dark:nth-child(even):hover figure {
  box-shadow: none;
}
.overlay-dark:hover figure > a:first-child:after, .overlay-dark:nth-child(even):hover figure > a:first-child:after {
  background-color: rgba(27, 27, 23, 0.7);
}
.overlay-dark:hover figure .btn-quickview,
.overlay-dark:hover figure .btn-icon, .overlay-dark:nth-child(even):hover figure .btn-quickview,
.overlay-dark:nth-child(even):hover figure .btn-icon {
  opacity: 0.85;
}
.overlay-dark:hover figure .btn-quickview:hover,
.overlay-dark:hover figure .btn-icon:hover, .overlay-dark:nth-child(even):hover figure .btn-quickview:hover,
.overlay-dark:nth-child(even):hover figure .btn-icon:hover {
  background-color: #4d4d4a;
  opacity: 1;
}
.overlay-dark:hover figure .btn-icon, .overlay-dark:nth-child(even):hover figure .btn-icon {
  border-color: #fff;
  border-width: 2px;
  color: #fff;
  background-color: #4d4d4a;
  opacity: 0.85;
}
.overlay-dark:hover .product-details, .overlay-dark:nth-child(even):hover .product-details {
  opacity: 1;
  transform: translateY(0);
}

.creative-grid > div:not(:last-child) .product-default {
  height: 600px;
}
.creative-grid > div:last-child .product-default {
  height: 300px;
}
.creative-grid .product-default {
  padding-bottom: 2rem;
  margin-bottom: 0;
  position: relative;
}
.creative-grid .product-default figure {
  height: 100%;
}
.creative-grid .product-default figure img {
  position: absolute;
  object-fit: cover;
  height: 100%;
  transition: opacity 0.3s linear;
}
.creative-grid .product-default figure img:last-child {
  opacity: 0;
}
.creative-grid .product-default figure img:first-child {
  opacity: 1;
}
.creative-grid .product-default:hover figure img:first-child {
  opacity: 0;
}
.creative-grid .product-default:hover figure img:last-child {
  opacity: 1;
}

.inner-btn figure .btn-icon-group {
  top: auto;
  left: auto;
  right: 1.5rem;
  bottom: 1.5rem;
}
.inner-btn figure .btn-icon {
  position: relative;
  margin-bottom: 0;
}
.inner-btn figure .btn-quickview {
  background-color: #fff;
}
.inner-btn figure .btn-quickview i {
  font-size: 1.4rem;
}

.quantity-input .product-details {
  align-items: center;
}
.quantity-input .product-single-qty {
  margin: 0 0 1rem;
}
.quantity-input .btn-add-cart {
  margin: 0 0 1rem 2px;
}
.quantity-input .btn-add-cart:hover {
  background-color: #e1ddc3;
  border-color: #e1ddc3;
  color: #fff;
}

.product-list {
  display: flex;
  display: -ms-flexbox;
}
.product-list figure {
  max-width: 250px;
  margin-right: 20px;
  margin-bottom: 0;
}
.product-list figure img {
  object-fit: cover;
  height: 100%;
}
.product-list .product-details {
  max-width: calc(100% - 270px);
}
.product-list .product-title {
  font-weight: 600;
  font-size: 1.8rem;
  font-family: Euclid, sans-serif;
}
.product-list .product-description {
  display: -webkit-box;
  max-width: 100%;
  font-weight: 400;
  font-size: 1.4rem;
  font-family: Euclid, sans-serif;
  line-height: 24px;
  margin-bottom: 1em;
  overflow: hidden;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}
.product-list .btn-add-cart {
  margin: 0 3px 5px 0;
  padding: 0 1rem;
  background-color: #e1ddc3;
  border-color: #e1ddc3;
  color: #fff;
}
.product-list .btn-icon-wish,
.product-list .btn-quickview {
  opacity: 1;
  background-color: #fff;
  color: #333;
  border-color: #ddd;
  margin: 0 0 5px;
  line-height: 32px;
}
.product-list:hover figure {
  box-shadow: none;
}

.product-widget {
  display: flex;
  display: -ms-flexbox;
  margin-bottom: 1.5rem;
}
.product-widget figure {
  max-width: 84px;
  margin-right: 2rem;
  margin-bottom: 0;
}
.product-widget figure img {
  object-fit: cover;
  height: 100%;
}
.product-widget .ratings-container {
  margin-bottom: 0.5rem;
}
.product-widget .product-details {
  max-width: calc(100% - 104px);
}
.product-widget .product-title {
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
}
.product-widget .price-box {
  margin-bottom: 0;
}
.product-widget .product-price {
  font-size: 1.5rem;
}
.product-widget .old-price {
  font-size: 1.2rem;
}
.product-widget:hover figure {
  box-shadow: none;
}

.product-quick-view {
  background-color: #fff;
  padding: 3rem 3rem 0;
}
.product-quick-view.product-single-container {
  padding: 3rem 3rem 0;
}
.product-quick-view .product-single-details .product-action {
  color: #fff;
  margin-bottom: 2.1rem;
}
.product-quick-view .product-single-filter {
  padding-bottom: 2.2rem;
}

.image-bg-white {
  filter: brightness(1.08);
}

.products-slider > .owl-stage-outer {
  margin: -10px -20px;
  padding: 10px 20px;
}

@media (max-width: 1280px) {
  .products-slider > .owl-stage-outer {
    margin: -10px -15px;
    padding: 10px 15px;
  }
}
@media (max-width: 1200px) {
  .product-intro.owl-carousel.owl-theme .owl-nav .owl-prev {
    left: 10px;
  }
  .product-intro.owl-carousel.owl-theme .owl-nav .owl-next {
    right: 10px;
  }
}
@media (max-width: 1159px) {
  .product-intro.owl-carousel.owl-theme .owl-nav .owl-prev {
    left: -30px;
  }
  .product-intro.owl-carousel.owl-theme .owl-nav .owl-next {
    right: -30px;
  }
}
@media (max-width: 1000px) {
  .product-intro.owl-carousel.owl-theme .owl-nav .owl-prev {
    left: 10px;
  }
  .product-intro.owl-carousel.owl-theme .owl-nav .owl-next {
    right: 10px;
  }
}
@media (min-width: 576px) {
  .divide-line > .col-sm-1:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-1:nth-child(12n) {
    border-right: none;
  }
  .divide-line > .col-sm-2:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-2:nth-child(6n) {
    border-right: none;
  }
  .divide-line > .col-sm-3:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-3:nth-child(4n) {
    border-right: none;
  }
  .divide-line > .col-sm-4:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-4:nth-child(3n) {
    border-right: none;
  }
  .divide-line > .col-sm-5:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-5:nth-child(2n) {
    border-right: none;
  }
  .divide-line > .col-sm-6:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-6:nth-child(2n) {
    border-right: none;
  }
  .divide-line > .col-sm-7:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-7:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-sm-8:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-8:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-sm-9:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-9:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-sm-10:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-10:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-sm-11:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-sm-11:nth-child(1n) {
    border-right: none;
  }
}
@media (min-width: 768px) {
  .divide-line > .col-md-1:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-1:nth-child(12n) {
    border-right: none;
  }
  .divide-line > .col-md-2:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-2:nth-child(6n) {
    border-right: none;
  }
  .divide-line > .col-md-3:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-3:nth-child(4n) {
    border-right: none;
  }
  .divide-line > .col-md-4:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-4:nth-child(3n) {
    border-right: none;
  }
  .divide-line > .col-md-5:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-5:nth-child(2n) {
    border-right: none;
  }
  .divide-line > .col-md-6:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-6:nth-child(2n) {
    border-right: none;
  }
  .divide-line > .col-md-7:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-7:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-md-8:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-8:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-md-9:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-9:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-md-10:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-10:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-md-11:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-md-11:nth-child(1n) {
    border-right: none;
  }
}
@media (min-width: 992px) {
  .divide-line > .col-lg-1:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-1:nth-child(12n) {
    border-right: none;
  }
  .divide-line > .col-lg-2:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-2:nth-child(6n) {
    border-right: none;
  }
  .divide-line > .col-lg-3:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-3:nth-child(4n) {
    border-right: none;
  }
  .divide-line > .col-lg-4:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-4:nth-child(3n) {
    border-right: none;
  }
  .divide-line > .col-lg-5:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-5:nth-child(2n) {
    border-right: none;
  }
  .divide-line > .col-lg-6:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-6:nth-child(2n) {
    border-right: none;
  }
  .divide-line > .col-lg-7:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-7:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-lg-8:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-8:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-lg-9:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-9:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-lg-10:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-10:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-lg-11:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-lg-11:nth-child(1n) {
    border-right: none;
  }
}
@media (min-width: 1200px) {
  .divide-line > .col-xl-1:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-1:nth-child(12n) {
    border-right: none;
  }
  .divide-line > .col-xl-2:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-2:nth-child(6n) {
    border-right: none;
  }
  .divide-line > .col-xl-3:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-3:nth-child(4n) {
    border-right: none;
  }
  .divide-line > .col-xl-4:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-4:nth-child(3n) {
    border-right: none;
  }
  .divide-line > .col-xl-5:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-5:nth-child(2n) {
    border-right: none;
  }
  .divide-line > .col-xl-6:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-6:nth-child(2n) {
    border-right: none;
  }
  .divide-line > .col-xl-7:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-7:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-xl-8:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-8:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-xl-9:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-9:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-xl-10:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-10:nth-child(1n) {
    border-right: none;
  }
  .divide-line > .col-xl-11:nth-child(n) {
    border-right: 1px solid rgba(0, 0, 0, 0.09);
    border-bottom: 1px solid rgba(0, 0, 0, 0.09);
  }
  .divide-line > .col-xl-11:nth-child(1n) {
    border-right: none;
  }
  .col-xl-7col .product-default .product-title,
  .col-xl-8col .product-default .product-title {
    font-size: 1.3rem;
  }
  .col-xl-7col .old-price,
  .col-xl-8col .old-price {
    font-size: 1.2rem;
  }
  .col-xl-7col .product-price,
  .col-xl-8col .product-price {
    font-size: 1.5rem;
  }
}
@media (max-width: 575px) {
  .product-list {
    flex-direction: column;
    -ms-flex-direction: column;
  }
  .product-list figure {
    max-width: none;
    margin-right: 0;
    margin-bottom: 2rem;
  }
  .product-list .product-details {
    max-width: none;
  }
}
.product-select-group {
  display: flex;
  display: -ms-flexbox;
  margin: 8px 0;
  width: 100%;
  justify-content: flex-start;
  position: relative;
  flex-wrap: wrap;
}

.product-select {
  margin: 0 4px 0 0;
  cursor: pointer;
}
.product-select.type-image {
  width: 18px;
  height: 18px;
  background-size: contain;
  border: 1px solid #ccc;
  margin-bottom: 1.6rem;
}
.product-select.type-image.checked, .product-select.type-image.hover {
  border: 1px solid #2f3946;
}
.product-select.type-check {
  margin: 5px;
  overflow: visible;
  display: block;
  position: relative;
  width: 12px;
  height: 12px;
  border-radius: 50%;
}
.product-select.type-check:after {
  content: "";
  background-color: transparent;
  border: 1px solid black;
  position: absolute;
  left: -3px;
  top: -3px;
  border-radius: 50%;
  width: 18px;
  display: block;
  height: 18px;
}
.product-select.type-check.checked:before {
  font-size: 8px;
  content: "\f00c";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  -webkit-font-smoothing: antialiased;
  text-indent: 0;
  position: absolute;
  left: 0;
  top: 50%;
  width: 100%;
  color: #fff;
  height: 12px;
  line-height: 12px;
  margin-top: -6px;
  text-align: center;
}

.sizes {
  display: flex;
  margin: 0;
}
.sizes li a {
  padding: 0 4px;
}

.product-select-group .sizes {
  margin-left: auto;
}

.product-category-panel {
  margin-bottom: 35px;
}
.product-category-panel .owl-carousel {
  margin-top: -10px;
  padding-top: 10px;
}
.product-category-panel .owl-carousel .owl-nav button.owl-prev, .product-category-panel .owl-carousel .owl-nav button.owl-next {
  width: 30px;
  font-size: 24px;
  color: #333;
  line-height: 22px;
}
.product-category-panel .owl-carousel .owl-nav button.owl-prev {
  left: -41px;
}
.product-category-panel .owl-carousel .owl-nav button.owl-next {
  right: -41px;
}
.product-category-panel .section-title {
  padding-bottom: 1rem;
  border-bottom: 1px solid #dbdbdb;
  margin-bottom: 2.5rem;
}
.product-category-panel .section-title h2 {
  font-weight: 700;
  font-size: 1.6rem;
  line-height: 1.2;
  font-family: Euclid, sans-serif;
  letter-spacing: -0.05em;
  color: #282d3b;
  text-transform: uppercase;
}

.product-category {
  color: #1d2127;
  margin-bottom: 2rem;
  position: relative;
}
.product-category a:hover {
  color: inherit;
}
.product-category figure {
  margin-bottom: 0;
  position: relative;
}
.product-category figure:after {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: transparent;
  transition: all 0.3s;
  z-index: 1;
  content: "";
}
.product-category:hover figure:after {
  background-color: rgba(27, 27, 23, 0.15);
}
.owl-item > .product-category {
  margin-bottom: 0;
}

.category-content {
  padding: 2rem;
  display: flex;
  display: -ms-flex-box;
  flex-direction: column;
  -ms-flex-direction: column;
  align-items: center;
  -ms-flex-align: center;
}
.category-content h3 {
  font-weight: 700;
  font-size: 1.8rem;
  line-height: 1.35;
  font-family: Euclid, sans-serif;
  letter-spacing: -0.005em;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.category-content span {
  font-weight: 400;
  font-size: 10.2px;
  line-height: 1.8;
  font-family: Euclid, sans-serif;
  letter-spacing: normal;
  margin-top: -10px;
  text-transform: uppercase;
  opacity: 0.7;
}
.category-content span mark {
  padding: 0;
  background-color: transparent;
  color: inherit;
}

.content-left-center .category-content,
.content-left-bottom .category-content,
.content-center .category-content,
.content-center-bottom .category-content {
  padding: 20.4px 25.5px;
  position: absolute;
  width: 100%;
  transform: translateY(-50%);
  z-index: 2;
}
.content-left-center .category-content h3,
.content-left-center .category-content span,
.content-left-bottom .category-content h3,
.content-left-bottom .category-content span,
.content-center .category-content h3,
.content-center .category-content span,
.content-center-bottom .category-content h3,
.content-center-bottom .category-content span {
  color: #fff;
}

.content-left-center .category-content,
.content-center .category-content {
  left: 0;
  top: 50%;
}

.content-left-center .category-content {
  align-items: flex-start;
}

.content-left-bottom .category-content {
  align-items: flex-start;
  left: 0;
  bottom: 0;
  transform: none;
}

.content-center-bottom figure {
  min-height: 90px;
}
.content-center-bottom .category-content {
  bottom: 0;
  transform: none;
  padding: 20.4px 0;
}
.content-center-bottom .category-content h3,
.content-center-bottom .category-content span {
  margin-bottom: 0;
  color: #1d2127;
}

.overlay-lighter figure:after {
  background-color: rgba(27, 27, 23, 0);
}
.overlay-lighter:hover figure:after {
  background-color: rgba(27, 27, 23, 0.15);
}

.overlay-darker figure:after {
  background-color: rgba(27, 27, 23, 0.25);
}
.overlay-darker:hover figure:after {
  background-color: rgba(27, 27, 23, 0.4);
}

.overlay-light figure:after {
  background-color: rgba(27, 27, 23, 0.75);
}
.overlay-light:hover figure:after {
  background-color: rgba(27, 27, 23, 0.6);
}

.hidden-count .category-content span {
  max-height: 10px;
  transition: all 0.5s;
  transform: translateY(20%);
  opacity: 0;
}
.hidden-count:hover .category-content span {
  max-height: 30px;
  transform: none;
  opacity: 0.7;
}

.creative-grid .product-category {
  height: 100%;
  margin-bottom: 0;
  padding-bottom: 20px;
}
.creative-grid .product-category.content-left-bottom .category-content {
  margin-bottom: 20px;
}
.creative-grid .product-category figure {
  height: 100%;
}
.creative-grid .product-category figure img {
  object-fit: cover;
}

.height-600 {
  height: 600px;
}

.height-400 {
  height: 400px;
}

.height-300 {
  height: 300px;
}

.height-200 {
  height: 200px;
}

@media (min-width: 1160px) {
  .col-5col-1 {
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (max-width: 767px) {
  .height-600 {
    height: 400px;
  }
  .height-300 {
    height: 200px;
  }
}
@media (max-width: 450px) {
  .content-center-bottom .category-content {
    padding: 16.8px 21px;
    text-align: center;
    flex-wrap: wrap;
  }
}
@media (max-width: 400px) {
  .content-center-bottom .category-content {
    padding-bottom: 1rem;
  }
}
@media (max-width: 1200px) {
  .product-category-panel .owl-carousel .owl-nav button.owl-next, .product-category-panel .owl-carousel .owl-nav button.owl-prev {
    width: 15px;
  }
  .product-category-panel .owl-carousel .owl-nav button.owl-next {
    right: -18px;
  }
  .product-category-panel .owl-carousel .owl-nav button.owl-prev {
    left: -18px;
  }
}
@media (max-width: 1159px) {
  .product-category-panel .owl-carousel .owl-nav button.owl-next, .product-category-panel .owl-carousel .owl-nav button.owl-prev {
    width: 30px;
  }
  .product-category-panel .owl-carousel .owl-nav button.owl-next {
    right: -41px;
  }
  .product-category-panel .owl-carousel .owl-nav button.owl-prev {
    left: -41px;
  }
}
@media (max-width: 1024px) {
  .product-category-panel .owl-carousel .owl-nav button.owl-next, .product-category-panel .owl-carousel .owl-nav button.owl-prev {
    width: 15px;
  }
  .product-category-panel .owl-carousel .owl-nav button.owl-next {
    right: -18px;
  }
  .product-category-panel .owl-carousel .owl-nav button.owl-prev {
    left: -18px;
  }
}
.social-icon {
  display: inline-block;
  width: 3.2rem;
  color: #fff;
  background-color: #e1ddc3;
  font-size: 1.4rem;
  line-height: 3.2rem;
  text-align: center;
  text-decoration: none;
  opacity: 1;
}
.social-icon + .social-icon {
  margin-left: 0.6rem;
}
.social-icons .social-icon:hover, .social-icons .social-icon:focus {
  color: #fff;
  text-decoration: none;
  opacity: 0.85;
}
.social-icon.social-facebook {
  background-color: #3b5a9a;
}
.social-icon.social-twitter {
  background-color: #1aa9e1;
}
.social-icon.social-instagram {
  background-color: #7c4a3a;
}
.social-icon.social-linkedin {
  background-color: #0073b2;
}
.social-icon.social-gplus {
  background-color: #dd4b39;
}
.social-icon.social-mail {
  background-color: #dd4b39;
}

.nav-tabs {
  margin: 0;
  border: 0;
  border-bottom: 2px solid #e7e7e7;
}
.nav-tabs .nav-item {
  margin-bottom: -2px;
}
.nav-tabs .nav-item .nav-link {
  padding: 1.2rem 0;
  border: 0;
  border-bottom: 2px solid transparent;
  color: #282d3b;
  font-weight: 700;
  font-size: 1.4rem;
  line-height: 1;
  font-family: Euclid, sans-serif;
  letter-spacing: 0.01rem;
  text-transform: uppercase;
}
.nav-tabs .nav-item .nav-link:hover {
  color: #e1ddc3;
}
.nav-tabs .nav-item:not(:last-child) {
  margin-right: 3.4rem;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-item .nav-link.active {
  border-bottom-color: #e1ddc3;
  color: #e1ddc3;
}

.tooltip {
  font-family: Euclid, sans-serif;
  font-size: 1.3rem;
}
.tooltip.show {
  opacity: 1;
}
.tooltip .arrow {
  width: 1rem;
  height: 1rem;
}

.bs-tooltip-top,
.bs-tooltip-auto[x-placement^=top] {
  padding: 1rem 0;
}

.bs-tooltip-top .arrow:before,
.bs-tooltip-auto[x-placement^=top] .arrow:before {
  margin-left: -0.5rem;
  border-width: 1rem 1rem 0;
  border-top-color: #ddd;
}

.bs-tooltip-right,
.bs-tooltip-auto[x-placement^=right] {
  padding: 0 1rem;
}

.bs-tooltip-right .arrow,
.bs-tooltip-auto[x-placement^=right] .arrow {
  width: 1rem;
  height: 2rem;
}

.bs-tooltip-right .arrow:before,
.bs-tooltip-auto[x-placement^=right] .arrow:before {
  border-width: 1rem 1rem 1rem 0;
  border-right-color: #ddd;
}

.bs-tooltip-bottom,
.bs-tooltip-auto[x-placement^=bottom] {
  padding: 1rem 0;
}

.bs-tooltip-bottom .arrow:before,
.bs-tooltip-auto[x-placement^=bottom] .arrow:before {
  margin-left: -0.5rem;
  border-width: 0 1rem 1em;
  border-bottom-color: #ddd;
}

.bs-tooltip-left,
.bs-tooltip-auto[x-placement^=left] {
  padding: 0 1rem;
}

.bs-tooltip-left .arrow,
.bs-tooltip-auto[x-placement^=left] .arrow {
  width: 1rem;
  height: 1rem;
}

.bs-tooltip-left .arrow:before,
.bs-tooltip-auto[x-placement^=left] .arrow:before {
  border-width: 1rem 0 1rem 1rem;
  border-left-color: #ddd;
}

.tooltip-inner {
  max-width: 270px;
  padding: 1.2rem 1.5rem;
  border: 1px solid #ddd;
  border-radius: 0.1rem;
  background-color: #f4f4f4;
  color: #777;
  text-align: left;
}

.ngx-slider {
  margin: 0 0 35px !important;
}
.ngx-slider .ngx-slider-bar {
  border-radius: 0 !important;
  background: #eee !important;
}
.ngx-slider .ngx-slider-selection {
  background: none !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.38) inset;
}
.ngx-slider .ngx-slider-pointer {
  background: none !important;
  width: 12px !important;
  height: 12px !important;
  top: -6px !important;
}
.ngx-slider .ngx-slider-pointer::after {
  width: 12px !important;
  height: 12px !important;
  top: 2px !important;
  left: 0 !important;
  border-radius: 6px !important;
  background: #e1ddc3 !important;
}
.ngx-slider .ngx-slider-pointer:focus, .ngx-slider .ngx-slider-pointer:focus-visible {
  outline: none !important;
}
.ngx-slider .ngx-slider-bubble {
  font-size: 14px !important;
  color: #777 !important;
}

.ngx-dropdown-container .ngx-dropdown-button {
  height: 6rem;
  margin-bottom: 1rem;
  padding: 2rem !important;
  transition: all 0.3s;
  border: 1px solid #dfdfdf !important;
  border-radius: 0;
  background-color: #fff;
  color: #777 !important;
  font-family: Poppins, sans-serif;
  font-size: 1.2rem !important;
  font-weight: 400;
  line-height: 1.5 !important;
  border-radius: 0 !important;
}

.toast-center-center {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%;
}

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%;
}

.toast-top-left {
  top: 12px;
  left: 12px;
}

.toast-top-right {
  top: 12px;
  right: 12px;
}

.toast-bottom-right {
  right: 12px;
  bottom: 12px;
}

.toast-bottom-left {
  bottom: 12px;
  left: 12px;
}

/* toast styles */
.toast-title {
  font-weight: bold;
}

.toast-message {
  word-wrap: break-word;
}

.toast-message a,
.toast-message label {
  color: #FFFFFF;
}

.toast-message a:hover {
  color: #CCCCCC;
  text-decoration: none;
}

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #FFFFFF;
  text-shadow: 0 1px 0 #ffffff;
  /* opacity: 0.8; */
}

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
}

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
}

.toast-container {
  pointer-events: none;
  position: fixed;
  z-index: 999999;
}

.toast-container * {
  box-sizing: border-box;
}

.toast-container .ngx-toastr {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  background-size: 24px;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  color: #FFFFFF;
}

.toast-container .ngx-toastr:hover {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  opacity: 1;
  cursor: pointer;
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/info-circle.svg */
.toast-info {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119.043 8 8 119.083 8 256c0 136.997 111.043 248 248 248s248-111.003 248-248C504 119.083 392.957 8 256 8zm0 110c23.196 0 42 18.804 42 42s-18.804 42-42 42-42-18.804-42-42 18.804-42 42-42zm56 254c0 6.627-5.373 12-12 12h-88c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h12v-64h-12c-6.627 0-12-5.373-12-12v-24c0-6.627 5.373-12 12-12h64c6.627 0 12 5.373 12 12v100h12c6.627 0 12 5.373 12 12v24z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/times-circle.svg */
.toast-error {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M256 8C119 8 8 119 8 256s111 248 248 248 248-111 248-248S393 8 256 8zm121.6 313.1c4.7 4.7 4.7 12.3 0 17L338 377.6c-4.7 4.7-12.3 4.7-17 0L256 312l-65.1 65.6c-4.7 4.7-12.3 4.7-17 0L134.4 338c-4.7-4.7-4.7-12.3 0-17l65.6-65-65.6-65.1c-4.7-4.7-4.7-12.3 0-17l39.6-39.6c4.7-4.7 12.3-4.7 17 0l65 65.7 65.1-65.6c4.7-4.7 12.3-4.7 17 0l39.6 39.6c4.7 4.7 4.7 12.3 0 17L312 256l65.6 65.1z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/check.svg */
.toast-success {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 512 512' width='512' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z'/%3E%3C/svg%3E");
}

/* https://github.com/FortAwesome/Font-Awesome-Pro/blob/master/advanced-options/raw-svg/regular/exclamation-triangle.svg */
.toast-warning {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 576 512' width='576' height='512'%3E%3Cpath fill='rgb(255,255,255)' d='M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z'/%3E%3C/svg%3E");
}

.toast-container.toast-top-center .ngx-toastr,
.toast-container.toast-bottom-center .ngx-toastr {
  width: 300px;
  margin-left: auto;
  margin-right: auto;
}

.toast-container.toast-top-full-width .ngx-toastr,
.toast-container.toast-bottom-full-width .ngx-toastr {
  width: 96%;
  margin-left: auto;
  margin-right: auto;
}

.ngx-toastr {
  background-color: #030303;
  pointer-events: auto;
}

.toast-success {
  background-color: #f3b804;
}

.toast-error {
  background-color: #BD362F;
}

.toast-info {
  background-color: #2F96B4;
}

.toast-warning {
  background-color: #F89406;
}

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
}

/* Responsive Design */
@media all and (max-width: 240px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 11em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 241px) and (max-width: 480px) {
  .toast-container .ngx-toastr.div {
    padding: 8px 8px 8px 50px;
    width: 18em;
  }
  .toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em;
  }
}
@media all and (min-width: 481px) and (max-width: 768px) {
  .toast-container .ngx-toastr.div {
    padding: 15px 15px 15px 50px;
    width: 25em;
  }
}
.my-account {
  margin-top: 40px;
}

.sidebar {
  position: relative;
  font-size: 13px;
}

.dashboard-content {
  margin-bottom: 10px;
}

.dashboard-content h2,
#account-chage-pass h3 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #2f333a;
}

@media screen and (min-width: 992px) {
  form,
  .form-footer {
    margin-bottom: 50px;
  }
}
@media screen and (min-width: 768px) {
  form,
  .form-footer {
    margin-bottom: 40px;
  }
}
.sidebar .widget {
  margin-bottom: 30px;
}

.widget-title {
  margin: 5px 0 13px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
}

.widget-dashboard .widget-title {
  margin-top: 2px;
  font-size: 17px;
  font-weight: 600;
}

.widget-dashboard .list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.widget-dashboard .list li.active {
  font-weight: 600;
}
.widget-dashboard .list li {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
}
.widget-dashboard .list li a {
  display: block;
  position: relative;
  padding: 9px 0 7px 20px;
  border-bottom: 1px solid #ededde;
  color: #000;
  font-size: 13px;
}
.widget-dashboard .list li a::before {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  margin: 9px 0 0 -10px;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #333;
  content: "";
  top: 0;
  bottom: 0;
  margin: auto;
  left: 0;
}
.widget-dashboard .list li a:hover,
.widget-dashboard .list li a:focus {
  background-color: #eee;
  text-decoration: none;
}

.required {
  color: #e02b27;
  font-size: 12px;
}

.custom-control.material-checkbox .material-control-input:checked ~ .material-control-indicator {
  border: 2px solid #2f333a;
  border-top: 0px;
  border-left: 0px;
}

.custom-control.material-checkbox .material-control-indicator {
  border: 1px solid #2f333a;
}

.custom-control {
  padding-left: 0;
  display: flex;
  align-items: center;
}
.custom-control input {
  margin-right: 1.6rem;
}

.required-field > label::after {
  margin: 0 0 0 0.45rem;
  color: #e02b27;
  font-size: 1.2rem;
  content: "*";
}

.form-footer-right {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
}

.skeleton-box {
  display: flex;
  height: 100%;
  position: relative;
  overflow: hidden;
  background-color: #DDDBDD;
}
.skeleton-box::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transform: translateX(-100%);
  background-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0, rgba(255, 255, 255, 0.2) 20%, rgba(255, 255, 255, 0.5) 60%, rgba(255, 255, 255, 0));
  animation: shimmer 2s infinite;
  content: "";
}
@keyframes shimmer {
  100% {
    transform: translateX(100%);
  }
}
.skeleton-box.line {
  height: 1.6rem;
  margin-bottom: 1rem;
}
.skeleton-box.line:last-child {
  margin-bottom: 2.4rem;
}

.o-media__figure {
  height: 200px;
  margin-bottom: 1.6rem;
}

.iti {
  width: 100%;
}
.iti__selected-flag {
  padding-left: 2rem;
}
.iti input#country-search-box {
  height: 40px;
  border-radius: 0;
  font-size: 14px;
}
.iti input#country-search-box:focus {
  outline: none;
}

.is-invalid .iti input {
  border-color: #dc3545;
}

.iti__country {
  font-size: 14px;
}
.iti__country.iti__preferred {
  margin-top: 1.6rem;
}

.checkout-steps li {
  margin-bottom: 0 !important;
}

html {
  overflow: hidden;
  font-size: 62.5%;
  font-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

body {
  color: #777;
  background: #fff;
  font-size: 1.4rem;
  font-weight: 400;
  line-height: 1.4;
  font-family: Euclid, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  overflow: hidden;
}
body:not(.loaded) > *:not(.loading-overlay) {
  visibility: hidden;
}

::-moz-selection {
  background-color: #e1ddc3;
  color: #fff;
}

::selection {
  background-color: #e1ddc3;
  color: #fff;
}

p {
  margin-bottom: 1.5rem;
}

ul,
ol {
  margin: 0 0 2.25rem;
  padding: 0;
  list-style: none;
}

b,
strong {
  font-weight: 700;
}

em,
i {
  font-style: italic;
}

hr {
  max-width: 1730px;
  margin: 5.5rem auto 5.2rem;
  border: 0;
  border-top: 1px solid #e7e7e7;
}

sub,
sup {
  font-size: 70%;
}

sup {
  font-size: 50%;
}

sub {
  bottom: -0.25em;
}

img {
  display: block;
  max-width: 100%;
  height: auto;
}

button:focus {
  outline: none;
}

body.modal-open {
  padding-right: 0 !important;
}

@keyframes rotating {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(359deg);
  }
}
@keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@-webkit-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}
.loading-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.5s ease-in-out;
  background: #fff;
  opacity: 1;
  visibility: visible;
  z-index: 999999;
}
.loaded > .loading-overlay {
  opacity: 0;
  visibility: hidden;
}

.bounce-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 70px;
  margin: -9px 0 0 -35px;
  transition: all 0.2s;
  text-align: center;
  z-index: 10000;
}
.bounce-loader .bounce1,
.bounce-loader .bounce2,
.bounce-loader .bounce3 {
  display: inline-block;
  width: 18px;
  height: 18px;
  border-radius: 100%;
  background-color: #CCC;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.15);
  -webkit-animation: 1.4s ease-in-out 0s normal both infinite bouncedelay;
  animation: 1.4s ease-in-out 0s normal both infinite bouncedelay;
}
.bounce-loader .bounce1 {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.bounce-loader .bounce2 {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}

@media (max-width: 767px) {
  html {
    font-size: 9px;
  }
}
a:not([href]):not([tabindex]):focus,
a:not([href]):not([tabindex]):hover {
  color: inherit;
  text-decoration: none;
  cursor: pointer;
}

.not-exist {
  display: none !important;
}

.exist {
  width: 80px !important;
  margin-right: 8px;
}

.custom-modal .modal-content {
  width: auto;
}

.ngx-dropdown-container .ngx-dropdown-button {
  overflow: hidden;
}

.angular-editor-toolbar {
  font-size: 16px !important;
}

.modal {
  z-index: 1599;
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  margin-bottom: 1.8rem;
  color: #222529;
  font-weight: 700;
  line-height: 1.1;
  font-family: Euclid, sans-serif;
}

h1, .h1 {
  font-size: 3.6rem;
  font-weight: 400;
  line-height: 1.223;
}

h2, .h2 {
  font-size: 3rem;
  line-height: 1.5;
}

h3, .h3 {
  font-size: 2.5rem;
  line-height: 1.28;
}

h4, .h4 {
  font-size: 2rem;
  line-height: 1.35;
}

h5, .h5 {
  font-size: 1.4rem;
  line-height: 1.429;
}

h6, .h6 {
  font-size: 1.3rem;
  line-height: 1.385;
  font-weight: 600;
}

a {
  transition: all 0.3s;
  color: #e1ddc3;
  text-decoration: none;
}

a:hover,
a:focus {
  color: #e1ddc3;
  text-decoration: none;
}

.heading {
  margin-bottom: 3rem;
  color: #222529;
}
.heading .title {
  margin-bottom: 1.6rem;
}
.heading p {
  letter-spacing: -0.015em;
}
.heading p:last-child {
  margin-bottom: 0;
}

.light-title {
  margin-bottom: 2rem;
  font-weight: 300;
}

.section-title {
  text-transform: uppercase;
  font-size: 1.8rem;
}

.section-sub-title {
  font-size: 1.6rem;
  text-transform: uppercase;
}

.text-transform-none {
  text-transform: none;
}

.ls-10 {
  letter-spacing: 0.01em !important;
}

.ls-0 {
  letter-spacing: 0 !important;
}

.ls-n-10 {
  letter-spacing: -0.01em !important;
}

.ls-n-15 {
  letter-spacing: -0.015em !important;
}

.ls-n-20 {
  letter-spacing: -0.02em !important;
}

.ls-n-25 {
  letter-spacing: -0.025em !important;
}

.bg-gray {
  background-color: #f4f4f4;
}

.bg-dark {
  background-color: #222529 !important;
}

.bg-dark2 {
  background-color: #2f3946 !important;
}

.bg-primary {
  background-color: #e1ddc3 !important;
}

.bg-secondary {
  background-color: #61605a !important;
}

.text-dark {
  color: #222529 !important;
}

.text-primary {
  color: #e1ddc3 !important;
}

.text-secondary {
  color: #61605a !important;
}

.text-body {
  color: #777 !important;
}

.line-height-1 {
  line-height: 1 !important;
}

.font1 {
  font-family: Euclid, sans-serif !important;
}

.font2 {
  font-family: Euclid, sans-serif !important;
}

@media (max-width: 767px) {
  .w-md-100 {
    width: 100% !important;
  }
  .w-md-50 {
    width: 50% !important;
  }
}
@media (max-width: 575px) {
  .w-sm-100 {
    width: 100% !important;
  }
  .w-sm-50 {
    width: 50% !important;
  }
}
@media (max-width: 479px) {
  .w-xs-100 {
    width: 100% !important;
  }
}
@media (max-width: 480px) {
  .w-xs-100 {
    width: 100% !important;
  }
}
@media (max-width: 400px) {
  .w-xxs-100 {
    width: 100% !important;
  }
}
.round-images img {
  border-radius: 50%;
}

@media (min-width: 768px) {
  h1, .h1 {
    font-size: 4.5rem;
  }
  h2, .h2 {
    font-size: 2.5rem;
  }
  .heading {
    margin-bottom: 4rem;
  }
}
@media (min-width: 992px) {
  h1, .h1 {
    font-size: 5rem;
  }
  h2, .h2 {
    font-size: 3rem;
  }
  .heading {
    margin-bottom: 5rem;
  }
  .w-lg-max {
    flex: 1;
    -ms-flex: 1;
    max-width: 100%;
  }
}
@font-face {
  font-family: "Euclid";
  src: url("../../fonts/euclid_circular_a_light-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
.page-wrapper {
  position: relative;
  transition: transform 0.25s;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}
.mmenu-active .page-wrapper {
  transform: translateX(250px);
}
.page-wrapper.admin .main {
  height: 100%;
  padding: 0;
}

.main {
  flex: 1 1 auto;
  padding-top: 140px;
}
@media (max-width: 479px) {
  .main {
    padding-top: 195px;
  }
}

.row {
  margin-left: -10px;
  margin-right: -10px;
}

[class*=col-] {
  padding-left: 10px;
  padding-right: 10px;
}

.row-sparse {
  margin-left: -15px;
  margin-right: -15px;
}
.row-sparse > [class*=col-] {
  padding-left: 15px;
  padding-right: 15px;
}

.row-joined {
  margin-left: 0;
  margin-right: 0;
}
.row-joined > [class*=col-] {
  padding-left: 0;
  padding-right: 0;
}

.container :not(.sticky-header) > .container,
.container :not(.sticky-header) > .container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.ajax-overlay {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -ms-flex-align: center;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(68, 70, 69, 0.65);
  z-index: 1041;
}

@media (max-width: 1280px) {
  .container-fluid {
    padding-left: 20px;
    padding-right: 20px;
  }
}
@media (min-width: 1220px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1200px) {
  .col-xl-5col {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
  .col-xl-7col {
    -ms-flex: 0 0 14.2857%;
    flex: 0 0 14.2857%;
    max-width: 14.2857%;
  }
  .col-xl-8col {
    -ms-flex: 0 0 12.5%;
    flex: 0 0 12.5%;
    max-width: 12.5%;
  }
  .row-xl-tight {
    margin-left: -5px;
    margin-right: -5px;
  }
  .row-xl-tight > [class*=col-] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
@media (min-width: 992px) {
  .container {
    padding-left: 10px;
    padding-right: 10px;
  }
}
@media (max-width: 991px) {
  .container {
    max-width: none;
  }
}
@media (min-width: 768px) {
  .row-md-tight {
    margin-left: -5px;
    margin-right: -5px;
  }
  .row-md-tight > [class*=col-] {
    padding-left: 5px;
    padding-right: 5px;
  }
}
.owl-carousel .owl-dots .owl-dot span:before {
  width: 7px;
  height: 7px;
  margin-top: -3.5px;
  margin-left: -3.5px;
}
.owl-carousel.dots-m-0 .disabled + .owl-dots {
  margin: 0;
}
.owl-carousel.dots-mt-1 .disabled + .owl-dots {
  margin-top: 1rem;
}
.owl-carousel.nav-big .owl-nav {
  font-size: 3.7rem;
}
.owl-carousel.nav-big .owl-nav i {
  padding: 4px 7px;
}
.owl-carousel.nav-large .owl-nav {
  font-size: 4.5rem;
}
.owl-carousel.nav-large .owl-nav i {
  padding: 4px 2px;
}
.owl-carousel.nav-image-center .owl-nav button {
  top: 35%;
}
.owl-carousel.show-nav-hover .owl-nav {
  opacity: 0;
  transition: opacity 0.2s, color 0.2s;
}
.owl-carousel.show-nav-hover:hover .owl-nav {
  opacity: 1;
}
.owl-carousel .owl-nav .owl-prev {
  left: 1vw;
}
.owl-carousel .owl-nav .owl-next {
  right: 1vw;
}
@media (min-width: 992px) {
  .owl-carousel.nav-outer .owl-prev {
    left: -1.7vw;
  }
  .owl-carousel.nav-outer .owl-next {
    right: -1.7vw;
  }
  .owl-carousel.nav-outer.nav-large .owl-prev {
    left: -2.3vw;
  }
  .owl-carousel.nav-outer.nav-large .owl-next {
    right: -2.3vw;
  }
}
.owl-carousel.dots-top .owl-dots {
  position: absolute;
  right: 0;
  bottom: 100%;
  margin: 0 0 2.5rem;
}
.owl-carousel.dots-small .owl-dots span {
  width: 14px;
  height: 14px;
}
.owl-carousel.dots-small .owl-dots span:before {
  width: 4px;
  height: 4px;
  margin-left: -2px;
  margin-top: -2px;
}
.owl-carousel.images-center img {
  width: auto;
  margin: auto;
}

.dots-left .owl-dots {
  text-align: left;
}

.owl-carousel-lazy {
  display: block;
}
.owl-carousel-lazy .home-slide:first-child,
.owl-carousel-lazy .category-slide:first-child,
.owl-carousel-lazy .owl-item:first-child .home-slide,
.owl-carousel-lazy .owl-item:first-child .category-slide {
  display: block;
}
.owl-carousel-lazy:not(.owl-loaded) > *:not(:first-child) {
  display: none;
}

.home-slide,
.category-slide {
  width: 100%;
}

div.slide-bg {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

img.slide-bg {
  object-fit: cover;
  object-position: center top;
}

.noUi-target {
  background: #eee;
}

.noUi-handle {
  background: #e1ddc3;
}

.noUi-connect {
  background: none;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.38) inset;
}

.sticky-header {
  background: #fff;
  transition: none;
}
.sticky-header.fixed {
  position: fixed;
  right: 0;
  left: 0;
  padding: 0;
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.08);
  z-index: 1040;
  animation: fromTop 0.5s ease-out;
}
.sticky-header.fixed .logo {
  padding: 0;
}
.sticky-header.fixed .logo img {
  height: 50px;
}
.sticky-header.scroll-down {
  transform: translateY(-115px);
  transition: all 1s ease 0s;
}
.sticky-header.scroll-up {
  transform: translateY(0);
  transition: all 1s ease 0s;
}
.sticky-header.deprecated-sticky:not(.fixed) .logo,
.sticky-header.deprecated-sticky:not(.fixed) .cart-dropdown {
  display: none;
}
.sticky-header.deprecated-sticky.fixed .main-nav {
  width: auto;
}
.main .sticky-header:not(.fixed) > .container {
  padding-left: 0;
  padding-right: 0;
}
.main .sticky-header.fixed {
  min-height: 38px;
  padding-top: 15px;
  padding-bottom: 15px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  z-index: 1039;
}
.main .sticky-header.fixed .container {
  margin: 0 auto;
}

@keyframes fromTop {
  from {
    top: -80px;
  }
  to {
    top: 0;
  }
}
header {
  position: relative;
  color: #fff;
}
header .container,
header .container-fluid {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -ms-flex-pack: justify;
  position: relative;
}
header p {
  margin-bottom: 0;
}
header a {
  color: inherit;
}

.header-top {
  font-size: 1.2rem;
}
.header-middle {
  padding-top: 2.8rem;
  padding-bottom: 2.8rem;
}
.header-left,
.header-center,
.header-right {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.header-center,
.header-right {
  margin-left: auto;
}

.header-transparent {
  position: absolute;
  right: 0;
  left: 0;
  z-index: 1040;
}

.logo {
  max-width: 100px;
}

.header-contact {
  align-items: center;
  text-transform: uppercase;
}
.header-contact i {
  margin-right: 1.1rem;
  font-size: 2.9rem;
}
.header-contact img {
  margin-right: 0.8rem;
}
.header-contact h6 {
  margin: 0;
  font-size: 1.1rem;
  line-height: 1.3;
  color: #777;
}
.header-contact a {
  display: block;
  font-size: 1.8rem;
  font-weight: 700;
  white-space: nowrap;
}

.mobile-menu-toggler {
  border: 0;
  background: transparent;
  color: #222529;
  padding: 1rem 0.5rem;
  margin-right: 0.5rem;
  font-size: 20px;
  line-height: 0;
  cursor: pointer;
}

.badge-circle {
  position: absolute;
  top: 1px;
  right: 0;
  width: 1.6rem;
  border-radius: 50%;
  color: #fff;
  background: #ff5b5b;
  font-weight: 600;
  font-size: 1.1rem;
  line-height: 1.6rem;
  font-family: Euclid, sans-serif;
  text-align: center;
}

header .social-icon {
  border-radius: 50%;
  font-size: 1.28rem;
  width: 2.6rem;
  height: 2.6rem;
  line-height: 2.6rem;
}
header .social-icon:not(:hover):not(:active):not(:focus) {
  background-color: transparent;
}
header .social-icon + .social-icon {
  margin-left: 2px;
}
header .separator {
  height: 20px;
  border-left: 1px solid rgba(0, 0, 0, 0.04);
  margin: 0 10px;
}

.top-notice {
  position: relative;
  padding: 1rem 2rem;
  font-size: 1.3rem;
  font-family: Euclid, sans-serif;
  line-height: 2;
  letter-spacing: 0.025em;
}
.top-notice h5 {
  color: inherit;
  font-size: inherit;
  font-weight: 500;
}
.top-notice small {
  font-size: 0.8461em;
  letter-spacing: 0.025em;
  opacity: 0.5;
}
.top-notice a {
  color: inherit;
  font-weight: 700;
}
.top-notice .category {
  display: inline-block;
  padding: 0.3em 0.8em;
  background: #151719;
  font-size: 1rem;
}
.top-notice .mfp-close {
  top: 50%;
  transform: translateY(-50%) rotateZ(45deg) translateZ(0);
  color: inherit;
  opacity: 0.7;
  z-index: 10;
}
.top-notice .mfp-close:hover {
  opacity: 1;
}

.header-icon:not(:last-child) {
  margin-right: 0;
}
.header-icon i {
  font-size: 2.7rem;
}

@media (min-width: 992px) {
  .mobile-menu-toggler {
    display: none;
  }
}
@media (max-width: 991px) {
  .main-nav {
    display: none;
  }
}
@media (max-width: 767px) {
  .header .separator {
    display: none;
  }
}
@media (max-width: 575px) {
  .top-notice {
    line-height: 1.5;
  }
  .header-icon:not(:last-child) {
    margin-right: 1.2rem;
  }
}
.dropdownmenu-wrapper {
  position: relative;
  background-color: #fff;
  padding: 1rem 1.5rem 0.5rem;
  box-shadow: 0 5px 8px rgba(0, 0, 0, 0.15);
}
.dropdownmenu-wrapper .empty {
  padding: 20px;
}
.dropdownmenu-wrapper .dropdown-cart-products {
  max-height: 350px;
  overflow: auto;
}

.cart-dropdown .dropdown-menu,
.compare-dropdown .dropdown-menu {
  display: block;
  left: 0;
  top: -999rem;
  width: 300px;
  padding-top: 10px;
  z-index: 100;
  font-size: 1.1rem;
  color: #696969;
  border: 0;
  margin: 0;
  background-color: transparent;
  box-shadow: none;
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
}
.cart-dropdown .dropdown-menu.show,
.compare-dropdown .dropdown-menu.show {
  opacity: 1;
  visibility: visible;
}
.cart-dropdown:hover .dropdown-menu,
.compare-dropdown:hover .dropdown-menu {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.dropdown-toggle i {
  font-size: 2.8rem;
}
.dropdown-toggle:not(.dropdown-arrow):after {
  display: none;
}

.dropdown-arrow:after {
  content: "\e81c";
  border: 0;
  margin: -5px 2px 0 11px;
  font-size: 15px;
  font-family: porto;
  width: auto;
  height: auto;
}
.dropdown-arrow .badge-circle {
  top: 3px;
  left: 19px;
}

.header-dropdowns {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -ms-flex-align: center;
}

.header-menu:before {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  width: 0;
  height: 0;
  z-index: 1151;
  margin-left: -9px;
  margin-top: -1rem;
  border-width: 0 9px 10px;
  border-style: solid;
  border-color: transparent transparent #fff;
  visibility: hidden;
  opacity: 1;
  transform: translateY(-1rem);
}

.header-dropdown {
  position: relative;
  font-weight: 500;
  line-height: 1.5;
  padding: 5px 0;
}
.header-dropdown > a {
  padding: 0 5px;
}
.header-dropdown > a:after {
  content: "\e81c";
  font-family: "porto";
  font-weight: 400;
  margin-left: 0.5rem;
}
.header-dropdown img {
  display: inline-block;
  max-width: none;
  margin: -2px 6px 0 1px;
}
.header-dropdown ul {
  position: absolute;
  top: -300rem;
  z-index: 1150;
  min-width: 100%;
  margin: 0;
  padding: 0.5rem 0;
  background: #fff;
  box-shadow: 0 29px 29px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s ease-out;
  opacity: 0;
  visibility: hidden;
  transform: translateY(-1rem);
}
.header-dropdown ul a {
  display: block;
  padding: 0.5em 1.5em;
  color: #777;
  white-space: nowrap;
}
.header-dropdown ul a:hover {
  background: #e1ddc3;
}
.header-dropdown + .header-dropdown {
  margin-left: 1.4rem;
}
.header-dropdown:hover .header-menu:before,
.header-dropdown:hover ul {
  top: 100%;
  opacity: 1;
  visibility: visible;
  transform: none;
}

.cart-dropdown .dropdown-menu {
  right: 0;
  left: auto;
  padding-top: 8px;
}
.cart-dropdown .dropdown-menu:before {
  content: "";
  position: absolute;
  top: -12px;
  right: 28px;
  border: 10px solid;
  border-color: transparent transparent #fff;
}
.cart-dropdown .dropdownmenu-wrapper:before {
  right: 28px;
  left: auto;
}
.cart-dropdown .dropdownmenu-wrapper:after {
  right: 29px;
  left: auto;
}
.cart-dropdown .product {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 !important;
  padding: 1.6rem 1rem;
  border-bottom: 1px solid #e6ebee;
  box-shadow: none !important;
}
.cart-dropdown .product-image-container {
  position: relative;
  max-width: 80px;
  margin: 0;
  margin-left: auto;
  border: 1px solid #ededed;
}
.cart-dropdown .product-image-container img {
  object-fit: cover;
}
.cart-dropdown .product-image-container a:after {
  display: none;
}
.cart-dropdown .product-title {
  padding-right: 1.5rem;
  margin-bottom: 8px;
  font-size: 1.3rem;
  line-height: 19px;
  display: flex;
}
.cart-dropdown .product-title a {
  color: #696969;
}
.cart-dropdown .product-title .type-image {
  padding: 2px 4px;
  margin-left: 8px;
  min-width: 18px;
  height: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ccc;
  border: 1px solid #ccc;
}
.cart-dropdown .product-details {
  font-size: 1.3rem;
}
.cart-dropdown .btn-remove {
  position: absolute;
  top: -9px;
  right: -3px;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  color: inherit;
  background-color: #fff;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.5);
  text-align: center;
  line-height: 2rem;
}
.cart-dropdown .btn-remove:hover, .cart-dropdown .btn-remove:focus {
  color: #e1ddc3;
}
.sticky-header .cart-dropdown {
  margin-top: 0;
  margin-bottom: 0;
}

.dropdown-cart-header,
.dropdown-cart-total {
  padding: 0.3rem 1rem;
  color: #474747;
  font-weight: 700;
  line-height: 38px;
  text-transform: uppercase;
}

.dropdown-cart-total {
  margin-bottom: 1.1rem;
  font-size: 1.4rem;
}
.dropdown-cart-total .cart-total-price {
  margin-left: auto;
  font-size: 1.5rem;
}

.dropdown-cart-header {
  border-bottom: 1px solid #e6ebee;
  color: #696969;
  font-size: 1.3rem;
}
.dropdown-cart-header a {
  color: #474747;
}
.dropdown-cart-header a:hover {
  text-decoration: underline;
}

.dropdown-cart-action {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 0.2rem 1rem 1.5rem;
}
.dropdown-cart-action .btn {
  padding: 1.4rem 2.5rem;
  border-radius: 0.2rem;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
  font-family: Euclid, sans-serif;
  letter-spacing: 0.025em;
}

.compare-dropdown .dropdown-toggle {
  text-transform: uppercase;
}
.compare-dropdown .dropdown-toggle i {
  margin-top: -0.2rem;
  margin-right: 0.2rem;
}
.compare-dropdown .dropdown-toggle i:before {
  margin: 0;
}
.compare-dropdown .dropdown-toggle:after {
  display: none;
}

.compare-products {
  margin: 0;
  padding: 0;
  list-style: none;
}
.compare-products .product {
  position: relative;
  margin: 0;
  padding: 0.5rem 0;
  box-shadow: none !important;
}
.compare-products .product:hover {
  box-shadow: none;
}
.compare-products .product-title {
  margin: 0;
  color: #696969;
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1.35;
  text-transform: uppercase;
}
.compare-products .btn-remove {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 50%;
  right: 0;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 2.3rem;
  height: 2.3rem;
  margin-top: -1.2rem;
  padding: 0.5rem 0;
  color: #777;
  font-size: 1.3rem;
  line-height: 1;
  text-align: center;
  overflow: hidden;
}

.compare-actions {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 2rem;
}
.compare-actions .action-link {
  display: inline-block;
  color: #777;
  font-size: 1.1rem;
  text-transform: uppercase;
}
.compare-actions .btn {
  min-width: 110px;
  margin-left: auto;
  padding: 0.9rem 1rem;
  border: 0;
  border-radius: 0.2rem;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.025rem;
  text-align: center;
  text-transform: uppercase;
}

@media (min-width: 992px) {
  .dropdown-expanded > a,
  .dropdown-expanded .header-menu:before {
    display: none;
  }
  .dropdown-expanded li + li {
    margin-left: 3rem;
  }
  .dropdown-expanded ul {
    position: static;
    display: flex;
    display: -ms-flexbox;
    padding: 0;
    border: 0;
    background-color: transparent;
    box-shadow: none;
    opacity: 1;
    visibility: visible;
  }
  .dropdown-expanded ul a {
    padding: 0;
    color: inherit;
  }
  .dropdown-expanded ul a:hover {
    background-color: transparent;
  }
  .dropdown-expanded .header-menu ul {
    transform: none;
  }
}
@media (max-width: 575px) {
  .compare-dropdown {
    display: none;
  }
}
@media (max-width: 480px) {
  .cart-dropdown .dropdown-menu,
  .compare-dropdown .dropdown-menu {
    width: 260px;
  }
  .cart-dropdown .dropdownmenu-wrapper,
  .compare-dropdown .dropdownmenu-wrapper {
    padding: 1rem 1.4rem;
  }
}
.header-search {
  position: relative;
}
.header-search form {
  margin: 0;
}
.header-search .form-control,
.header-search select {
  margin: 0;
  border: 0;
  color: inherit;
  font-size: 1.3rem;
  height: 100%;
  box-shadow: none;
}
.header-search .form-control,
.header-search .select-custom {
  background: #f4f4f4;
}
@media (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .header-search .form-control {
    flex: 1;
  }
}
.header-search .form-control::placeholder {
  color: #a8a8a8;
}
.header-search:not(.header-search-category) .form-control {
  border-radius: 5rem;
}
.header-search:not(.header-search-category) .btn {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  border: 0;
  padding: 0 0.8em;
  color: #333;
}

.search-toggle:after {
  content: "";
  position: absolute;
  right: calc(50% - 10px);
  bottom: -10px;
  border: 10px solid transparent;
  border-bottom-color: #222529;
}
.header-search:not(.show) .search-toggle:after {
  display: none;
}

.search-toggle i:before {
  font-size: 21px;
  font-weight: 600;
}

.header-search-category .form-control {
  border-radius: 5rem 0 0 5rem;
}
.header-search-category .btn {
  border-radius: 0 5rem 5rem 0;
}

.header-search-wrapper {
  display: flex;
  display: -ms-flexbox;
  position: absolute;
  right: -2.3rem;
  z-index: 999;
  margin-top: 10px;
  color: #8d8d8d;
  box-sizing: content-box;
  height: 50px;
  border-radius: 5rem;
  border: 0 solid #222529;
}
.header-search-wrapper:after {
  display: block;
  clear: both;
  content: "";
}
.header-search:not(.show) .header-search-wrapper {
  display: none;
}
.header-search-wrapper .select-custom {
  margin: 0;
  min-width: 13rem;
  flex: 0 0 13rem;
}
.header-search-wrapper .select-custom:after {
  font-size: 1.4rem;
  line-height: 0;
  right: 1.7rem;
}
.header-search-wrapper select {
  width: 100%;
  border-left: 1px solid #e7e7e7;
  padding-left: 1rem;
  padding-right: 1.7rem;
  line-height: 36px;
  color: inherit;
  -moz-appearance: none;
  -webkit-appearance: none;
}
.header-search-wrapper select:focus {
  outline: none;
}
.header-search-wrapper .btn {
  position: relative;
  padding: 0 0 3px 0;
  border: 0;
  border-left: 1px solid #e7e7e7;
  min-width: 63px;
  color: #fff;
  font-size: 2.4rem;
  background: #f4f4f4;
}

.header-search-popup .form-control {
  min-width: 266px;
  padding: 4px 22px;
  font-size: 1.4rem;
  line-height: 20px;
}
.header-search-popup .form-control:focus {
  border: #e7e7e7;
}

.header-search-inline .form-control {
  min-width: 21rem;
  padding: 1rem 2rem;
}
@media (min-width: 992px) {
  .header-search-inline .search-toggle,
  .header-search-inline .btn:after {
    display: none;
  }
  .header-search-inline.header-search .header-search-wrapper {
    display: flex;
    display: -ms-flex;
    position: static;
    margin: 0;
    border-width: 0;
  }
}

@media (max-width: 767px) {
  .header-search .form-control {
    min-width: 17rem;
  }
}
@media (max-width: 575px) {
  .header-search-wrapper {
    left: 0;
    right: auto;
    position: fixed;
  }
  .results {
    left: 0;
    right: 0;
    width: 100vh;
    position: fixed;
    top: 15rem;
  }
}
.popup-menu {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2000;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  transition: background-color 0.25s;
}
.popup-menu:not(.open) {
  visibility: hidden;
}
.popup-menu.open {
  background-color: rgba(51, 51, 51, 0.99);
}
.popup-menu nav {
  width: 100%;
  max-width: 600px;
  height: 80%;
  overflow: hidden;
}

.popup-menu-ul {
  position: relative;
  margin-bottom: 0;
  line-height: 22px;
  text-align: center;
  height: 100%;
  overflow: scroll;
}
.popup-menu-ul::-webkit-scrollbar {
  width: 0;
  height: 0;
}
.popup-menu-ul a {
  display: inline-block;
  padding: 0.4rem 1.6rem;
  color: #a8a8a8;
  transition: color 0.25s, margin-left 0.25s;
}
.popup-menu-ul a:hover {
  margin-left: 1rem;
  color: #fff;
}
.popup-menu-ul > li > a {
  padding: 0.9rem 1.2rem;
  color: #fff;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 1.25;
  text-transform: uppercase;
}
.popup-menu-ul ul {
  padding: 1rem 0;
  font-size: 1.8rem;
}
.popup-menu-ul ul:not(.open) {
  display: none;
}
.popup-menu-ul ul ul {
  font-size: 0.8em;
  opacity: 0.7;
}
.popup-menu-ul .tip {
  position: absolute;
  margin-top: 2px;
  opacity: 1;
}

.popup-menu-toggler {
  margin-right: 2.1rem;
  color: #333 !important;
  font-size: 12px;
  font-weight: 600;
  line-height: 1.3;
  text-transform: uppercase;
}
.popup-menu-toggler .toggler-icon {
  display: block;
  float: left;
  width: 28px;
  height: 8px;
  border-top: 2px solid #333;
  border-bottom: 2px solid #333;
  margin-top: 1px;
  margin-right: 1rem;
}
.popup-menu-toggler .toggler-icon:after {
  content: "";
  display: block;
  margin-top: 10px;
  height: 2px;
  background: #333;
}

.popup-menu-close {
  position: absolute;
  top: 3rem;
  right: 3rem;
  color: #fff;
}
.popup-menu-close:active {
  top: 3rem;
}

@media (min-width: 992px) {
  .popup-menu nav {
    width: 50%;
    height: 50%;
  }
}
.top-menu li {
  float: left;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.top-menu li a {
  display: block;
  position: relative;
  padding: 1.4rem 5px;
  color: #fff;
  font-size: 1.2rem;
  font-weight: 600;
  line-height: 1.84;
  white-space: nowrap;
  text-transform: uppercase;
  transition: color 0.25s;
}
.top-menu li:hover a {
  color: #e1ddc3;
}
.top-menu li.active a {
  padding-left: 1.9rem;
  padding-right: 1.9rem;
  z-index: 1;
  color: #000;
}
.top-menu li.active a:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: -2px;
  right: -2px;
  bottom: 0;
  transform: skew(-25deg);
  background: #fff;
}
.top-menu li.active a:after {
  content: "\e81f";
  margin-left: 3px;
  font-family: "porto";
}

@media (min-width: 576px) {
  .top-menu li + li {
    margin-left: 1rem;
  }
}
@media (min-width: 768px) {
  .top-menu li + li {
    margin-left: 3rem;
  }
}
@media (max-width: 479px) {
  .top-menu li.active a {
    padding-left: 0.8rem;
    padding-right: 0.8rem;
  }
}
.menu,
.menu li,
.menu ul,
.menu ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

.menu {
  font-size: 12px;
  font-weight: 600;
  line-height: 1.5;
}
.menu:after {
  display: block;
  clear: both;
  content: "";
}
.menu .show > ul,
.menu .show > .megamenu {
  opacity: 1;
}
.menu li > a {
  display: block;
  padding: 0.8rem 1.8rem;
  transition: 0.2s ease-out;
  color: #777;
}
.menu li:hover > a, .menu li.show > a, .menu li.active > a {
  background: #f4f4f4;
}
.menu > li {
  float: left;
  position: relative;
  margin-right: 2.8rem;
}
.menu > li > a {
  padding: 1rem 0;
  font-size: 13px;
  font-weight: 400;
  color: #555;
}
.menu > li:hover > a, .menu > li.show > a, .menu > li.active > a {
  color: #e1ddc3;
  background: transparent;
}
.menu > li > .sf-with-ul:before {
  content: "";
  position: absolute;
  z-index: 1000;
  left: 50%;
  bottom: 0;
  margin-left: -14px;
  border: 10px solid;
  border-color: transparent transparent #fff;
  opacity: 0;
  transition: opacity ease-in 0.08s;
}
.menu > li.show > .sf-with-ul:before {
  opacity: 1;
  visibility: visible;
}
.menu .megamenu {
  display: none;
  position: absolute;
  z-index: 999;
  background-color: #fff;
  box-shadow: 0 1rem 2.5rem rgba(0, 0, 0, 0.15);
  border: 1px solid #eee;
  border-top: 3px solid #e1ddc3;
  left: 15px;
  padding: 10px 20px;
  width: 770px;
}
.menu .megamenu.megamenu-3cols {
  width: 600px;
}
.menu .megamenu .row > div {
  padding-top: 1.5rem;
}
.menu .megamenu img {
  width: 300px;
  height: 100%;
  object-fit: cover;
}
.menu .megamenu .submenu {
  margin: 0;
  padding-top: 0;
  border-top: none;
  display: block;
  position: static;
  box-shadow: none;
  min-width: 0;
}
.menu .megamenu .submenu a {
  padding: 7px 8px 8px 0;
}
.menu .megamenu .submenu li:hover a {
  text-decoration: underline;
  background: transparent;
}
.menu .nolink {
  cursor: default;
  display: inline-block;
  padding-bottom: 6px;
  color: #333;
}
.menu ul {
  display: none;
  position: absolute;
  min-width: 200px;
  padding: 5px 0;
  border-top: 3px solid #e1ddc3;
  top: 100%;
  left: 0;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0 29px 29px rgba(0, 0, 0, 0.1);
}
.menu ul ul {
  top: -5px;
  left: 100%;
}
.menu.sf-arrows .sf-with-ul + ul > li {
  position: relative;
}
.menu.sf-arrows .sf-with-ul:after {
  position: absolute;
  right: 1rem;
  content: "\e81a";
  font-family: "porto";
}
.menu.sf-arrows > li > .sf-with-ul:after {
  content: "\e81c";
  position: static;
  margin-left: 5px;
  font-weight: 400;
}

.main-nav .menu {
  text-transform: uppercase;
  font-size: 12px;
}
.main-nav .menu > li {
  margin-right: 2.9rem;
}
.main-nav .menu > li > a {
  font-size: 12px;
  font-weight: 700;
  padding: 19px 0;
}
.main-nav .menu > li:first-child a {
  padding-left: 0;
}
.main-nav .menu > li:not(.float-right):last-child, .main-nav .menu > li:not(.float-right) + li.float-right {
  margin-right: 0;
}
.main-nav .menu.sf-arrows ul {
  border-top: none;
}
.main-nav .menu > li > ul {
  left: -15px;
}
.main-nav .menu .megamenu {
  top: 100%;
  left: -15px;
  border-top: none;
}
.main-nav .menu .megamenu img {
  height: 100%;
  object-fit: cover;
}
.tip {
  display: inline-block;
  position: relative;
  margin: -2px 0 0 1rem;
  padding: 3px 4px;
  border-radius: 2px;
  color: #fff;
  font-family: Euclid, sans-serif;
  font-size: 1rem;
  line-height: 1;
  text-transform: uppercase;
  vertical-align: middle;
  z-index: 1;
}
.tip:before {
  position: absolute;
  top: 50%;
  right: 100%;
  left: auto;
  margin-top: -3px;
  border: 3px solid transparent;
  content: "";
}

.tip-new {
  background-color: #0fc567;
}
.tip-new:not(.tip-top):before {
  border-right-color: #0fc567;
}
.tip-new.tip-top:before {
  border-top-color: #0fc567;
}

.tip-hot {
  background-color: #eb2771;
}
.tip-hot:not(.tip-top):before {
  border-right-color: #eb2771;
}
.tip-hot.tip-top:before {
  border-right-color: #eb2771;
}

.tip-top {
  position: absolute;
  top: 0;
  left: 50%;
  margin-top: 6px;
  margin-left: -2px;
  transform: translate(-50%);
}
.tip-top:before {
  top: 100%;
  right: 60%;
  margin: 0;
}

.mobile-menu-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  max-width: 250px;
  transform: translateX(-100%);
  transition: all 0.25s;
  background-color: #1D1E20;
  font-size: 1.2rem;
  line-height: 1.5;
  box-shadow: 0.1rem 0 0.6rem 0 rgba(50, 50, 50, 0.65);
  visibility: hidden;
  z-index: 1001;
  overflow-y: auto;
}
.mmenu-active .mobile-menu-container {
  transform: translateX(0);
  visibility: visible;
}
.mobile-menu-container .social-icons {
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 0;
}
.mobile-menu-container .social-icon {
  width: 3rem;
  height: 3rem;
  margin-bottom: 0;
  border: 0;
  background-color: transparent;
  font-size: 1.5rem;
}
.mobile-menu-container .social-icon + .social-icon {
  margin-left: 0.3rem;
}
.mobile-menu-container .social-icon:hover, .mobile-menu-container .social-icon:focus {
  background-color: transparent;
  color: #fafafa;
}

.mobile-menu-wrapper {
  position: relative;
  padding: 4.2rem 0 3rem;
}

.mobile-menu-close {
  position: absolute;
  top: 1rem;
  right: 1rem;
  padding: 0.4rem;
  color: #fff;
  font-size: 1.3rem;
  line-height: 1;
  cursor: pointer;
  z-index: 9;
}

.mobile-menu-overlay {
  display: block;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  transition: all 0.25s;
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  visibility: hidden;
  z-index: 1000;
}

.mmenu-active .mobile-menu-overlay {
  opacity: 1;
  visibility: visible;
}

.mmenu-active .sidebar-product {
  display: none;
}

.mobile-nav {
  margin: 0 0 2rem;
  padding: 0;
}

.mobile-menu {
  margin: 0;
  padding: 0;
  list-style: none;
}
.mobile-menu > li > a {
  text-transform: uppercase;
}
.mobile-menu li {
  display: block;
  position: relative;
  border-bottom: 1px solid #242527;
}
.mobile-menu li a {
  display: block;
  position: relative;
  margin-right: 4.5rem;
  padding: 1.1rem 0 1.1rem 1.5rem;
  color: #fff;
}
.mobile-menu li a:hover, .mobile-menu li a:focus {
  color: #b7b7b7;
  text-decoration: none;
}
.mobile-menu li.open > a, .mobile-menu li.active > a {
  color: #b7b7b7;
}
.mobile-menu li ul {
  display: none;
  margin: 0;
  padding: 0;
}
.mobile-menu li ul li a {
  padding-left: 2.5rem;
}
.mobile-menu li ul ul li a {
  padding-left: 3.5rem;
}

.mmenu-btn {
  display: block;
  position: absolute;
  top: 50%;
  right: -3.8rem;
  width: 3rem;
  height: 3rem;
  margin-top: -1.5rem;
  text-align: center;
  border-radius: 0;
  outline: none;
  background-color: transparent;
  color: #fff;
  font-size: 1.5rem;
  line-height: 3rem;
  cursor: pointer;
}
.mmenu-btn:after {
  display: inline-block;
  margin-top: -2px;
  font-family: "porto";
  content: "\e81c";
}
.open > a > .mmenu-btn:after {
  content: "\e81b";
}

footer {
  font-size: 1.3rem;
  line-height: 24px;
}
footer .container {
  position: relative;
}
footer p {
  color: inherit;
}

footer a {
  color: inherit;
}

footer a:hover,
footer a:focus {
  color: #e1ddc3;
}

footer .social-icon {
  border-radius: 50%;
  width: 1.7rem;
  height: 1.7rem;
  color: #fff;
  font-size: 1.8rem;
  line-height: 1.7rem;
}
footer .social-icon:not(:hover):not(:active):not(:focus) {
  background: transparent;
}
footer .widget {
  margin-bottom: 3rem;
}
footer .widget-title {
  font-size: 1.6rem;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 1.7rem;
}
footer .tagcloud a {
  padding: 0.6em;
  margin: 0 0.8rem 0.8rem 0;
  border: 1px solid #313438;
  color: inherit;
  font-size: 11px;
  background: transparent;
}
footer .tagcloud a:hover {
  border-color: #fff;
  background: transparent;
}
footer .contact-info {
  margin: 0;
  padding: 0;
}
footer .contact-info li {
  position: relative;
  margin-bottom: 1rem;
  line-height: 1.4;
}
footer .contact-info-label {
  display: block;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 1px;
}

#scroll-top {
  height: 40px;
  position: fixed;
  right: 15px;
  width: 40px;
  z-index: 9999;
  bottom: 0;
  color: #fff;
  background-color: #43494e;
  font-size: 16px;
  text-align: center;
  line-height: 1;
  padding: 11px 0;
  visibility: hidden;
  opacity: 0;
  border-radius: 0 0 0 0;
  transition: all 0.3s, margin-right 0s;
  transform: translateY(40px);
}
#scroll-top:hover, #scroll-top:focus {
  background-color: #3a4045;
}
#scroll-top.fixed {
  transform: translateY(0);
  opacity: 1;
  visibility: visible;
}

.header-top .header-right {
  -ms-flex-align: stretch;
  align-items: stretch;
}
.header-top a {
  color: #fff;
}

.header-middle {
  padding-top: 0;
  padding-bottom: 0;
}
.header-middle.fixed {
  padding: 1rem;
}
.header-middle.fixed .sizes-alert {
  left: -16px;
  right: -16px;
  width: calc(100% + 32px);
  top: -12px;
}
.header-transparent .header-middle:not(.fixed) {
  background-color: transparent;
  background-image: none;
}
.header-middle .container,
.header-middle .container-fluid {
  position: relative;
}
.header-middle .sizes-alert {
  position: relative;
  padding: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #F44336;
  font-size: 1.6rem;
}

.header-dropdowns {
  border-left: 1px solid #2c3035;
  margin-left: 1rem;
  letter-spacing: 0.01em;
}

.cart-count {
  background: #222529;
}

.cart-dropdown {
  padding-left: 1.9rem;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}
.cart-dropdown .dropdown-menu:before {
  right: 16px;
}
.cart-dropdown .dropdown-toggle {
  display: block;
  position: relative;
  color: #fff;
  transform: translateY(1px);
}
.cart-dropdown .dropdown-toggle i {
  padding-right: 1.1rem;
  line-height: 1.25;
  color: #333;
}
.cart-dropdown .dropdown-toggle .dropdown-cart-text {
  margin-left: 1rem;
}
.cart-dropdown .dropdown-toggle:hover, .cart-dropdown .dropdown-toggle:focus {
  text-decoration: none;
}
.cart-dropdown .product-image-container {
  border: 0;
}
.cart-dropdown .product-title {
  font-weight: 500;
}

.dropdown-cart-action .btn {
  color: #fff;
  font-weight: bold;
}

.search-toggle,
.header-icon {
  display: inline-block;
  padding: 0 1.68rem;
  color: #333;
  font-size: 2.8rem;
  line-height: 1.25;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
}

.search-toggle i {
  color: #333;
}
.search-toggle i:before {
  margin: 0 6px 0 8px;
  transform: translateY(-2px);
}

.header-search-wrapper {
  padding-right: 1.68rem;
  right: -2rem;
}

@media (min-width: 768px) {
  .header-dropdowns {
    margin-left: 2.5rem;
  }
}
@media (max-width: 991px) {
  .logo {
    max-width: 100px;
    padding: 15px;
  }
  .header-top .container {
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
  }
  .header-top .header-left,
  .header-top .header-right {
    width: 100%;
    justify-content: center;
    -ms-flex-pack: center;
    text-align: center;
  }
}
@media (max-width: 575px) {
  .logo {
    max-width: 100px;
    padding: 0;
  }
  .logo img {
    height: 75px;
    padding: 6px;
  }
  .top-menu li + li {
    margin-left: 1rem;
  }
  .header-dropdown,
  .header-dropdowns {
    margin-left: 0;
  }
}
@media (max-width: 479px) {
  .top-menu li + li {
    margin-left: 0;
  }
  .header-icon {
    display: none;
  }
  .sizes-alert {
    flex-direction: column;
  }
}
.footer {
  padding-top: 3rem;
}
.footer .widget-title {
  margin-bottom: 2.7rem;
  color: #fff;
}
.footer .links {
  margin-bottom: 0;
}
.footer .links li {
  position: relative;
  margin-bottom: 2.2rem;
  padding-left: 1.3rem;
}
.footer .links li:before {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  font-family: "porto";
  font-size: 1.2rem;
  content: "\e814";
}
.footer .contact-info li {
  position: relative;
  margin-bottom: 2.4rem;
  padding-left: 6.4rem;
  line-height: 1.6;
}
.footer .contact-info li:not(:last-child) {
  padding-bottom: 2.4rem;
  border-bottom: 1px solid #2c2f33;
}
.footer .contact-info li i {
  position: absolute;
  left: 2rem;
  width: auto;
  height: 4rem;
  float: none;
  background-color: transparent;
  font-size: 2.6rem;
}

.footer-middle {
  padding: 2rem 0 2.5rem;
}

.contact-info-label {
  display: block;
  color: #fff;
  font-weight: 600;
  text-transform: uppercase;
}

.widget-newsletter p {
  line-height: 2;
  letter-spacing: 0.005em;
}
.widget-newsletter form {
  position: relative;
  max-width: 290px;
}
.widget-newsletter .form-control {
  height: 4.8rem;
  padding: 1.35rem 7rem 1.35rem 2rem;
  border: 0;
  background-color: #2C2F33;
  color: #fff;
  font-size: 1.1rem;
  font-weight: 600;
  font-style: italic;
  letter-spacing: 0.01em;
}
.widget-newsletter .form-control::placeholder {
  font-family: Euclid, sans-serif;
  color: rgba(255, 255, 255, 0.3);
}
.widget-newsletter .btn {
  display: inline-block;
  position: absolute;
  top: 0;
  right: 0;
  min-width: 50px;
  padding: 1.6rem 1rem;
  background-color: #333;
  color: #fff;
  font-size: 1.4rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: 0.01em;
}

.footer-bottom {
  padding-top: 2rem;
  padding-bottom: 2rem;
  border-top: 1px solid #2c2f33;
}

.footer-copyright {
  font-size: 1.2rem;
  line-height: 1.5;
}

@media (min-width: 992px) {
  .footer {
    padding-top: 3.6rem;
  }
  .footer-middle {
    padding-top: 3.4rem;
    padding-bottom: 0.4rem;
  }
}
.sidebar-shop {
  font-size: 1.3rem;
}
.sidebar-shop .widget {
  padding: 2rem;
  border: 1px solid #e7e7e7;
}
.sidebar-shop .widget:after {
  display: block;
  clear: both;
  content: "";
}
.sidebar-shop .widget:not(:last-child) {
  border-bottom: 0;
}
.sidebar-shop .widget .config-swatch-list {
  margin-top: 0.3rem;
}
.sidebar-shop .widget .config-swatch-list li {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-right: 0;
  margin-bottom: 0.5rem;
  font-size: 1.3rem;
}
.sidebar-shop .widget .config-swatch-list li.active {
  font-weight: bold;
}
.sidebar-shop .widget .config-swatch-list li:last-child {
  margin-bottom: 0;
}
.sidebar-shop .widget .config-swatch-list li a {
  margin-right: 1.5rem;
}
.sidebar-shop .widget-title {
  margin: 0;
  color: #313131;
  font-size: 12px;
  font-weight: 600;
  font-family: Euclid, sans-serif;
  line-height: 1.4;
  text-transform: uppercase;
}
.sidebar-shop .widget-title a {
  display: block;
  position: relative;
  color: inherit;
}
.sidebar-shop .widget-title a:hover, .sidebar-shop .widget-title a:focus {
  text-decoration: none;
}
.sidebar-shop .widget-title a:before, .sidebar-shop .widget-title a:after {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0;
  width: 10px;
  height: 2px;
  margin-top: -1px;
  transition: all 0.35s;
  background: #222529;
  content: "";
}
.sidebar-shop .widget-title a.collapsed:after {
  transform: rotate(-90deg);
}
.sidebar-shop .widget-body {
  padding: 1.5rem 0;
}
.sidebar-shop .widget-featured {
  position: relative;
}
.sidebar-shop .widget-featured .widget-body {
  padding-top: 2.3rem;
}
.sidebar-shop .widget-featured .product-sm:last-child {
  margin-bottom: 0;
}

.widget .owl-carousel .owl-nav {
  position: absolute;
  top: -4.3rem;
  right: -0.4rem;
  line-height: 0;
}
.widget .owl-carousel .owl-nav button.owl-next,
.widget .owl-carousel .owl-nav button.owl-prev {
  padding: 0 0.4rem !important;
  border-radius: 0;
  color: #222529;
  font-size: 2rem;
  line-height: 1;
}
.widget .owl-carousel .owl-nav i:before {
  width: auto;
  margin: 0;
}

.cat-list {
  margin: 0;
  padding: 0;
  list-style: none;
  overflow: auto;
}
.cat-list li {
  margin-bottom: 1.4rem;
}
.cat-list li:last-child {
  margin-bottom: 0;
}
.cat-list li a {
  color: #777;
}
.cat-list li a:hover, .cat-list li a:focus {
  color: #e1ddc3;
}
.cat-list li strong {
  margin-bottom: 1.4rem;
  display: block;
}
.cat-list li ul {
  background: #f5f5f5;
  padding: 1.2rem;
}
.cat-list li ul li {
  font-size: 1.2rem;
}
.cat-list li ul li.active a {
  font-weight: bold;
  color: #e1ddc3;
}
.cat-list li.active a {
  font-weight: bold;
}

.config-size-list {
  margin: 0;
  padding: 0;
  font-size: 0;
  list-style: none;
}

.config-size-list li {
  display: -ms-inline-flexbox;
  display: inline-flex;
  margin-bottom: 0.8rem;
}
.config-size-list li:not(:last-child) {
  margin-right: 0.8rem;
}

.config-size-list a {
  display: block;
  position: relative;
  width: 4.4rem;
  height: 3rem;
  transition: all 0.3s;
  border: 1px solid #e9e9e9;
  color: #777;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 2.8rem;
  text-align: center;
  text-decoration: none;
}
.config-size-list a:hover, .config-size-list a:focus, .config-size-list a.active {
  border-color: #e1ddc3;
  background-color: #e1ddc3;
  color: #61605a;
  text-decoration: none;
}

.price-slider-wrapper {
  padding-top: 2rem;
}

.filter-price-action {
  margin-top: 2.5rem;
  padding-bottom: 0.4rem;
}
.filter-price-action .btn {
  padding: 3px 0.7em;
  font-size: 1.2rem;
  font-weight: 400;
}
.filter-price-action .filter-price-text {
  font-size: 1.2rem;
  line-height: 2;
}

.widget-block {
  font-size: 1.5rem;
  line-height: 1.42;
}
.widget-block h5 {
  margin-bottom: 2rem;
  color: #7a7d82;
  font-size: 1.4rem;
  font-weight: 600;
  font-family: Euclid, sans-serif;
}
.widget-block p {
  color: #21293c;
  max-width: 210px;
}

.horizontal-filter {
  margin-bottom: 2rem;
  padding: 12px 12px 2px;
  background-color: #f4f4f4;
}
.horizontal-filter .filter-price-form {
  font-family: Euclid, sans-serif;
  font-size: 1.36rem;
}
.horizontal-filter .filter-price-form .btn {
  font-family: inherit;
  padding: 0.7rem 1.2rem;
  font-size: 1.2rem;
  font-weight: 400;
}
.horizontal-filter .input-price {
  display: block;
  width: 50px;
  padding: 6px;
  line-height: 1.45;
  outline: none;
  border: 1px solid rgba(0, 0, 0, 0.09);
}
.horizontal-filter select {
  border: 0;
}

.sort-menu-trigger {
  display: block;
  color: #313131;
  font-size: 12px;
  line-height: 1.4;
  text-transform: uppercase;
}

.sort-list li {
  padding: 1rem 0;
  font-size: 12px;
  text-transform: uppercase;
}
.sort-list li a {
  color: inherit;
  font-weight: 600;
}

.filter-toggle span {
  color: #777;
  font-size: 1.3rem;
}
.filter-toggle a {
  display: inline-block;
  position: relative;
  width: 46px;
  height: 26px;
  margin-left: 8px;
  border-radius: 13px;
  background: #e6e6e6;
  text-decoration: none;
}
.filter-toggle a:before {
  position: absolute;
  left: 0;
  width: 42px;
  height: 22px;
  -webkit-transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
  transition: all 0.3s linear;
  border-radius: 11px;
  background-color: #fff;
  content: "";
}
.filter-toggle a:after {
  position: absolute;
  left: 0;
  width: 22px;
  height: 22px;
  -webkit-transform: translate3d(2px, 2px, 0);
  transform: translate3d(2px, 2px, 0);
  transition: all 0.2s ease-in-out;
  border-radius: 11px;
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(0, 0, 0, 0.24);
  content: "";
}
.filter-toggle.opened a {
  background-color: #e1ddc3;
}
.filter-toggle.opened a:before {
  -webkit-transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
  transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
}
.filter-toggle.opened a:after {
  -webkit-transform: translate3d(22px, 2px, 0);
  transform: translate3d(22px, 2px, 0);
}

.btn-loadmore {
  box-shadow: none;
  padding: 1.3rem 3rem;
  border: 1px solid #e7e7e7;
  font-size: 1.2rem;
  font-family: Euclid, sans-serif;
  color: #555;
}
.btn-loadmore:hover {
  border-color: #ccc;
}

@media (min-width: 992px) {
  .horizontal-filter:not(.filter-sorts) {
    padding-left: 20px;
    padding-right: 20px;
  }
  .filter-sorts .toolbox-left {
    position: relative;
  }
  .filter-sorts .toolbox-item.toolbox-sort {
    margin-left: 0;
    margin-right: 1rem;
    background-color: #fff;
  }
  .filter-sorts select {
    border: 0;
    text-transform: uppercase;
  }
  .filter-sorts .mobile-sidebar.sidebar-shop {
    left: 0;
    padding: 0;
    visibility: visible;
    z-index: 2;
  }
  .sort-list {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    min-width: 220px;
    margin-top: 10px;
    padding: 10px 15px;
    background: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.15);
    z-index: 99;
  }
  .sort-list:before, .sort-list:after {
    content: "";
    position: absolute;
    bottom: 100%;
    border-right: 10px solid transparent;
    border-bottom: 10px solid #fff;
    border-left: 10px solid transparent;
  }
  .sort-list:before {
    left: 21px;
    z-index: 999;
  }
  .sort-list:after {
    left: 20px;
    border-right-width: 11px;
    border-bottom: 11px solid #e8e8e8;
    border-left-width: 11px;
  }
  .sort-menu-trigger {
    min-width: 140px;
    height: 34px;
    padding-left: 0.8rem;
    color: #777;
    line-height: 34px;
    z-index: 9;
  }
  .sort-menu-trigger:hover, .sort-menu-trigger:focus {
    text-decoration: none;
  }
  .toolbox-item.opened .sort-list {
    display: block;
  }
}
@media (max-width: 991px) {
  .sort-menu-trigger {
    margin-bottom: 1.5rem;
    font-weight: 700;
  }
  .sidebar-shop .widget {
    padding: 2rem 0;
    border: 0;
  }
  .sidebar-shop .widget:first-child {
    padding-top: 0;
  }
  .sidebar-shop .widget:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
  }
}
@media (min-width: 992px) and (max-width: 1200px) {
  .sidebar-shop .product-widget figure {
    max-width: 70px;
    margin-right: 1.5rem;
  }
}
.add-cart {
  padding: 11px 28px;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: -0.015em;
  line-height: 24px;
}
.add-cart:before {
  font-size: 1.8rem;
  line-height: 0;
  vertical-align: middle;
  margin-right: 8px;
  font-weight: 900;
}

.add-wishlist {
  display: inline-block;
  padding: 16px 8px;
  color: #222529;
  font-size: 1.2rem;
  font-weight: 700;
  font-family: Euclid, sans-serif;
  letter-spacing: -0.015em;
  text-transform: uppercase;
  white-space: nowrap;
}
.add-wishlist:before {
  content: "\e91b";
  margin-right: 0.5rem;
  display: inline-block;
  font-size: 1.8rem;
  font-family: "porto";
  line-height: 0;
  transform: translateY(2px);
}
.add-wishlist.favorite:before {
  content: "\f004";
  color: red;
  font-family: "Font Awesome 5 Free";
}

.add-compare:before {
  content: "\e810";
  font-size: 1.8rem;
  font-family: "porto";
  margin-right: 6px;
}

.product-single-container {
  margin-bottom: 3rem;
  padding: 0;
}
@media (max-width: 575px) {
  .product-single-container {
    margin: -1.6rem -1.6rem 0 -1.6rem;
  }
}

.product-single-details {
  margin-top: -0.6rem;
  margin-bottom: 3rem;
}
.product-single-details .product-title {
  margin-bottom: 0.5rem;
  color: #222529;
  font-size: 3rem;
  font-weight: 700;
  letter-spacing: -0.01em;
}
.product-single-details .price-box {
  margin-bottom: 2.4rem;
  color: #e1ddc3;
  font-weight: 700;
  font-family: Euclid, sans-serif;
}
.product-single-details .product-price {
  font-size: 2.4rem;
  letter-spacing: -0.02em;
  vertical-align: middle;
}
.product-single-details .old-price {
  color: #777;
  font-size: 2rem;
  vertical-align: middle;
}
.product-single-details .old-price + .product-price {
  margin-left: 1rem;
}
.product-single-details .short-divider {
  width: 40px;
  height: 0;
  border-top: 2px solid #e7e7e7;
  margin: 0 0 1.5rem;
  text-align: left;
}
.product-single-details .divider {
  border-top: 1px solid #e7e7e7;
  margin: 2rem 0;
}
.product-single-details .divider + .product-action {
  margin-top: -0.5rem;
}
.product-single-details .product-action + .divider {
  margin-top: 1.5rem;
}
.product-single-details .ratings-container {
  margin-bottom: 2.1rem;
}
.product-single-details .ratings-container .product-ratings,
.product-single-details .ratings-container .ratings {
  font-size: 1.4rem;
}
.product-single-details .ratings-container .product-ratings {
  height: 14px;
}
.product-single-details .ratings-container .product-ratings:before {
  color: #999;
}
.product-single-details .ratings-container .ratings:before {
  color: #FD5B5A;
}
.product-single-details .rating-link {
  color: #777;
  font-size: 1.4rem;
  font-weight: 400;
}
.product-single-details .rating-link:hover {
  text-decoration: underline;
}
.product-single-details .product-desc {
  margin-bottom: 4.2rem;
  font-size: 1.6rem;
  letter-spacing: -0.015em;
  line-height: 1.92;
}
.product-single-details .product-desc a {
  color: #222529;
  border-bottom: 1px solid #222529;
}
.product-single-details .container {
  align-items: center;
  -ms-flex-align: center;
}
.product-single-details .container img {
  max-width: 5rem;
  max-height: 5rem;
  margin-right: 2rem;
}
.product-single-details .product-single-qty {
  margin: 0.5rem 4px 0.5rem 0;
}
.product-single-details .product-single-qty .form-control {
  height: 4.8rem;
  font-size: 1.6rem;
  font-weight: 700;
}

main .sticky-header:not(.fixed) .sticky-img,
main .sticky-header:not(.fixed) .sticky-detail {
  display: none;
}
main .sticky-header:not(.fixed).container {
  padding-left: 0;
  padding-right: 0;
}
main .sticky-header.fixed .container,
main .sticky-header.fixed .container-fluid {
  display: flex;
  display: -ms-flexbox;
  align-items: center;
  -ms-flex-align: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}
main .sticky-header.fixed .product-action {
  flex: 1;
  -ms-flex: 1;
  border: 0;
  margin: 0;
  padding: 0;
  min-width: 260px;
  text-align: right;
}
main .sticky-header .sticky-img img {
  max-width: 60px;
}
main .sticky-header .sticky-detail {
  display: flex;
  display: -ms-flexbox;
}
main .sticky-header .product-title {
  margin-right: 2rem;
  margin-bottom: 0;
  font-size: 16px;
  font-weight: 600;
}
main .sticky-header .price-box {
  margin: 0;
  color: #2b2b2d;
  font-family: "Oswald", "Poppins";
  font-weight: 400;
}
main .sticky-header .old-price {
  font-size: 1.4rem;
}
main .sticky-header .product-price {
  font-size: 1.8rem;
}
main .sticky-header .ratings-container {
  line-height: 24px;
}
main .sticky-header .product-ratings:before {
  color: rgba(0, 0, 0, 0.16);
}

.product-both-info .product-single-details {
  margin-top: 0;
}
.product-both-info .product-single-details .product-desc {
  border-bottom: 0;
}

.single-info-list {
  margin-bottom: 2rem;
  padding: 0;
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0.005em;
  text-transform: uppercase;
}
.single-info-list li {
  margin-bottom: 1rem;
  letter-spacing: 0.005em;
}
.single-info-list li strong {
  color: #4c4c4c;
}

.product-single-filter {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 2.1rem;
}
.product-single-filter label {
  margin-right: 1.3rem;
  margin-bottom: 0;
  color: #777;
  font-weight: 400;
  font-family: "Euclid", sans-serif;
  letter-spacing: 0.005em;
  text-transform: uppercase;
}
.product-single-filter .config-swatch-list {
  display: inline-flex;
  margin: 0;
}
.product-single-filter .config-size-list li {
  margin-bottom: 0;
}
.product-single-filter .config-size-list li:not(:last-child) {
  margin-right: 0.7rem;
}
.product-single-filter .config-size-list li a {
  min-width: 3rem;
  height: 3rem;
  background-color: #f4f4f4;
  color: #21293c;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 2.8rem;
}
.product-single-filter .config-size-list li a:hover, .product-single-filter .config-size-list li a:focus {
  border-color: #61605a;
  background-color: #f4f4f4;
  color: #21293c;
}
.product-single-filter .config-size-list li.active a {
  border: 1px solid #61605a;
  outline: none;
  background-color: #f4f4f4;
  color: #21293c;
}
.product-single-filter.product-single-qty {
  max-width: 148px;
  max-height: 7.5rem;
  border-bottom: 0;
}

.product-single-qty label {
  color: #222529;
  font-weight: 600;
  font-size: 1.5rem;
}

.product-single-share {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
}
.product-single-share label {
  margin-right: 1.2rem;
  margin-bottom: 0.5rem;
  color: #222529;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.1;
  font-family: "Euclid", sans-serif;
  letter-spacing: 0.005em;
  text-transform: uppercase;
}
.product-single-share .social-icon {
  border-radius: 50%;
  line-height: 2em;
  border: 2px solid transparent;
  margin: 0.2857em 0.4285em 0.2857em 0;
}
.product-single-share .social-icon:not(:hover):not(:active):not(:focus) {
  color: #222529;
  background-color: transparent;
  border-color: #e7e7e7;
}

.product-single-gallery {
  margin-bottom: 3rem;
}
.product-single-gallery .sticky-slider {
  position: relative;
}
.product-single-gallery a {
  display: block;
}
.product-single-gallery img {
  display: block;
  width: 100%;
  max-width: none;
  height: auto;
}
.product-single-gallery .owl-nav {
  font-size: 2.8rem;
}
.product-single-gallery .owl-nav .owl-prev {
  left: 2.5rem;
}
.product-single-gallery .owl-nav .owl-next {
  right: 2.5rem;
}
.product-single-gallery .owl-nav button {
  transition: opacity 0.5s;
  opacity: 0.5;
}
.product-single-gallery .product-item {
  position: relative;
}
.product-single-gallery .product-item:not(:last-child) {
  margin-bottom: 4px;
}
.product-single-gallery .product-single-grid {
  margin-bottom: 3.6rem;
}

.prod-thumbnail {
  display: flex;
  display: -ms-flexbox;
  margin: 3px -3px 0;
}
.prod-thumbnail > .owl-dot {
  flex: 1;
  -ms-flex: 1 1 100px;
  margin: 3px;
}
.prod-thumbnail img {
  width: 100%;
  cursor: pointer;
}
.prod-thumbnail img:hover {
  border: 2px solid #21293c;
}

.transparent-dots {
  position: absolute;
  top: 15px;
  left: 15px;
  width: 110px;
  margin: 0;
  padding: 0;
  z-index: 99;
}
.transparent-dots .owl-dot {
  margin: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  transition: border-color 0.2s;
}
.transparent-dots .owl-dot img {
  border: 0;
  opacity: 0.5;
}
.transparent-dots .owl-dot.active, .transparent-dots .owl-dot:hover {
  border-color: #e1ddc3;
}

.product-slider-container {
  position: relative;
}
.product-slider-container:hover .prod-full-screen,
.product-slider-container:hover .owl-prev,
.product-slider-container:hover .owl-next {
  opacity: 1;
}

.prod-full-screen {
  position: absolute;
  right: 2rem;
  bottom: 1.7rem;
  transition: all 0.5s;
  outline: none;
  opacity: 0;
  z-index: 1;
}
.prod-full-screen i {
  color: #000;
  font-size: 1.4rem;
  cursor: pointer;
}

.product-single-tabs {
  margin-bottom: 5.5rem;
}
.product-single-tabs .tab-pane {
  padding-top: 3.5rem;
  color: #7b858a;
  line-height: 1.92;
}
.product-single-tabs .sticky-header {
  height: auto;
}
.product-single-tabs .nav.nav-tabs .nav-item .nav-link {
  color: #818692;
}
.product-single-tabs .nav.nav-tabs .nav-item .nav-link.active {
  color: #222529;
}
.product-single-tabs .nav.nav-tabs .nav-link {
  font-family: "Euclid", sans-serif;
}
.product-single-tabs .nav.nav-tabs .nav-link:hover, .product-single-tabs .nav.nav-tabs .nav-link.active {
  border-bottom-color: #222529;
}

.scrolling-box .tab-pane + .tab-pane {
  margin-top: 3.5rem;
  border-top: 2px solid #dae2e6;
}

.product-desc-content p {
  margin-bottom: 2.2rem;
  letter-spacing: -0.015em;
}
.product-desc-content ul,
.product-desc-content ol {
  margin-bottom: 2.2rem;
  padding-left: 2.1rem;
  letter-spacing: 0.005em;
}
.product-desc-content li {
  margin-bottom: 0.4rem;
}
.product-desc-content li i {
  margin-right: 1.2rem;
  color: #21293c;
  font-size: 1.5rem;
}
.product-desc-content img.float-right,
.product-desc-content img.float-left {
  max-width: 50%;
}
.product-desc-content .feature-box i {
  float: none;
  display: inline-block;
}
.product-desc-content .feature-box-content {
  margin-left: 0;
}

.product-tags-content h4 {
  margin: 0 0 2rem;
  font-size: 1.8rem;
  font-weight: 700;
  text-transform: uppercase;
}
.product-tags-content form {
  margin-bottom: 2rem;
}
.product-tags-content .form-group {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: stretch;
  align-items: stretch;
}
.product-tags-content .form-control {
  margin-right: 10px;
}
.product-tags-content .btn {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.product-reviews-content {
  line-height: 1.92;
}
.product-reviews-content .reviews-title {
  font-size: 1.6rem;
  font-weight: 600;
  letter-spacing: -0.015em;
  margin-bottom: 0;
}

.comment-container {
  display: flex;
  display: -ms-flexbox;
  padding: 29px 0 8px;
}
.comment-container:not(:first-child) {
  border-top: 1px solid #e7e7e7;
}
.comment-container .comment-avatar {
  flex: 1 0 auto;
  padding: 0 22px 5px 8px;
}
.comment-container img {
  border-radius: 10rem;
}
.comment-container .ratings-container {
  margin-bottom: 6px;
}
.comment-container .product-ratings,
.comment-container .ratings {
  font-size: 14px;
}
.comment-container .product-ratings:before {
  color: #999;
}
.comment-container .ratings:before {
  color: #FD5B5A;
}
.comment-container .comment-info {
  font-family: "Euclid", sans-serif;
  font-size: 1.4rem;
  line-height: 1;
  letter-spacing: -0.02em;
}
.comment-container .avatar-name {
  display: inline;
  font-family: inherit;
  font-size: inherit;
}
.comment-container .comment-text {
  letter-spacing: -0.015em;
}

.add-product-review {
  background-color: #f4f4f4;
  padding: 3rem 2rem 3.5rem;
}
.add-product-review h3 {
  font-size: 1.6rem;
  margin-bottom: 2.4rem;
}
.add-product-review label {
  display: block;
  font-size: 1.4rem;
  font-weight: 600;
  line-height: 1;
  margin-bottom: 1.1rem;
}
.add-product-review .form-control {
  font-size: 1.4rem;
  margin-bottom: 3rem;
  max-width: 100%;
}
.add-product-review .btn {
  padding: 0.93em 1.78em;
}

.rating-stars {
  display: flex;
  display: -ms-flexbox;
  position: relative;
  height: 14px;
  font-size: 1.4rem;
  margin-bottom: 2.8rem;
}
.rating-stars a {
  color: #999;
  text-indent: -9999px;
  letter-spacing: 1px;
  width: 16px;
}
.rating-stars a:before {
  content: "";
  position: absolute;
  left: 0;
  height: 14px;
  line-height: 1;
  font-family: "Font Awesome 5 Free";
  text-indent: 0;
  overflow: hidden;
  white-space: nowrap;
}
.rating-stars a.active:before,
.rating-stars a:hover:before {
  content: "\f005\f005\f005\f005\f005";
  font-weight: 900;
}
.rating-stars .star-1 {
  z-index: 10;
}
.rating-stars .star-2 {
  z-index: 9;
}
.rating-stars .star-3 {
  z-index: 8;
}
.rating-stars .star-4 {
  z-index: 7;
}
.rating-stars .start-5 {
  z-index: 6;
}
.rating-stars .star-1:before {
  width: 16px;
}
.rating-stars .star-2:before {
  width: 32px;
}
.rating-stars .star-3:before {
  width: 48px;
}
.rating-stars .star-4:before {
  width: 64px;
}
.rating-stars .star-5:before {
  content: "\f005\f005\f005\f005\f005";
}

.products-section {
  padding-top: 3.8rem;
  padding-bottom: 3rem;
}
.products-section h2 {
  padding-bottom: 1rem;
  border-bottom: 1px solid #e7e7e7;
  margin-bottom: 2.4rem;
  font-size: 1.8rem;
  font-family: "Euclid", sans-serif;
  line-height: 22px;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}
.products-section.pt-sm {
  padding-top: 2.5rem;
}

@media (max-width: 1599px) {
  .transparent-dots {
    width: 90px;
  }
}
@media (min-width: 768px) {
  .products-section {
    padding-top: 4.8rem;
    padding-bottom: 4rem;
  }
  .product-both-info .product-single-share {
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .add-product-review {
    padding-left: 3rem;
    padding-right: 3rem;
  }
}
@media (min-width: 992px) {
  .product-single-container .col-lg-7 {
    -ms-flex: 0 0 55.56%;
    flex: 0 0 55.56%;
    max-width: 55.56%;
  }
  .product-single-container .col-lg-5 {
    -ms-flex: 0 0 44.44%;
    flex: 0 0 44.44%;
    max-width: 44.44%;
  }
}
@media (max-width: 991px) {
  .transparent-dots {
    top: 10px;
    left: 10px;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .product-all-icons.product-action .product-single-qty {
    margin-right: 50%;
    margin-bottom: 1.2rem;
  }
}
@media (min-width: 576px) {
  .product-tags-content .form-control {
    width: 250px;
  }
}
@media (max-width: 575px) {
  .transparent-dots {
    width: 70px;
  }
}
.product-single-extended {
  margin-bottom: 6rem;
}
.product-single-extended .product-single-gallery {
  position: relative;
}
.product-single-extended .product-single-gallery .owl-item:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: transparent;
  transition: background-color 1s;
}
.product-single-extended .product-single-gallery .owl-item.center:before {
  background-color: rgba(0, 0, 0, 0.07);
}
.product-single-extended .product-title {
  font-size: 3.5rem;
  line-height: 1.15;
}
.product-single-extended .price-box {
  margin-bottom: 1rem;
  border: 0;
}
.product-single-extended .product-price {
  font-size: 3.1rem;
}
.product-single-extended .old-price {
  font-size: 2.4rem;
}
.product-single-extended .old-price + .product-price {
  margin-left: 1.5rem;
}
.product-single-extended .widget-area .widget.widget-info {
  margin-bottom: 0;
}
.product-single-extended .product-filters-container {
  border-top: 1px solid #e7e7e7;
}

.product-desc .view-more {
  color: #e1ddc3;
  font-weight: 700;
  text-transform: uppercase;
}

.product-single-header {
  margin-bottom: 3.2rem;
  padding-bottom: 1.4rem;
  border-bottom: 1px solid #dae2e6;
}

.single-qty-wrapper {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}
.single-qty-wrapper label {
  margin-right: 1.3rem;
  margin-bottom: 0;
  color: #21293c;
  font-weight: 600;
  font-size: 1.5rem;
  line-height: 1.1;
  font-family: "Euclid", sans-serif;
  letter-spacing: 0.005em;
  text-transform: uppercase;
}
.single-qty-wrapper .product-single-qty .horizontal-quantity {
  padding: 0.2rem;
  height: 24px;
  width: 36px;
  background-color: #f4f4f4;
  font-size: 1.3rem;
}
.single-qty-wrapper .product-single-qty .btn {
  border-style: none;
}

.product-single-row {
  display: -ms-flexbox;
  display: flex;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  height: 700px;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #7b858a;
  font-size: 1.4rem;
  letter-spacing: 0.005em;
  line-height: 1.92;
}
.product-single-row .single-row-entire,
.product-single-row .single-row-bg {
  position: absolute;
  top: 0;
  bottom: 0;
  background-color: #ccc;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}
.product-single-row .single-row-entire {
  width: 100%;
}
.product-single-row .single-row-bg {
  left: 50%;
  width: 50%;
}
.product-single-row.single-row-reverse .single-row-bg {
  left: 0;
}
.product-single-row h5 {
  margin-bottom: 1.1rem;
  color: #7b858a;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.product-single-row h2 {
  margin-bottom: 2.8rem;
  font-size: 4.5rem;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.product-single-row p {
  margin-bottom: 0;
}
.product-single-row ul,
.product-single-row ol {
  margin-bottom: 0;
}
.product-single-row ul li,
.product-single-row ol li {
  margin-bottom: 0.4rem;
}
.product-single-row ul li i,
.product-single-row ol li i {
  margin-right: 2rem;
  color: #21293c;
  font-size: 1.5rem;
}
.product-single-row ul li i:before,
.product-single-row ol li i:before {
  margin: 0;
}

.product-single-video {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  height: 300px;
  background-color: #4d4d4d;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  color: #7b858a;
  text-align: center;
}
.product-single-video h3 {
  margin-bottom: 1.2rem;
  color: #21293c;
  font-size: 3.5rem;
  font-weight: 600;
  letter-spacing: -0.01em;
}
.product-single-video .video-btn {
  color: #7b858a;
  font-size: 2rem;
  font-weight: 600;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}
.product-single-video .video-btn img {
  display: inline-block;
  max-width: 38px;
  height: auto;
  margin-left: 0.7rem;
}

@media (max-width: 991px) {
  .product-single-row h2 {
    font-size: 3.5rem;
  }
}
@media (max-width: 767px) {
  .product-single-row {
    display: block;
  }
  .product-single-row .single-row-bg {
    position: relative;
    left: 0;
    width: 100%;
    height: 50%;
  }
  .product-single-row .single-row-entire {
    position: relative;
    height: 50%;
  }
  .product-single-row .container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    height: 50%;
  }
  .product-single-extended .product-price {
    font-size: 2.4rem;
  }
  .product-single-extended .product-title {
    font-size: 3rem;
  }
}
@media (max-width: 575px) {
  .product-single-row h2 {
    font-size: 3rem;
  }
  .product-single-extended .single-qty-wrapper {
    width: 100%;
    margin-bottom: 2rem;
  }
}
.sidebar-product .widget.widget-collapse {
  margin-bottom: 3rem;
  padding: 1.8rem 1.5rem 1.3rem;
  border: 1px solid #dae2e6;
}
.sidebar-product .widget.widget-collapse .widget-title {
  margin: 0;
}
.sidebar-product .widget.widget-collapse .widget-title a {
  display: block;
  position: relative;
  padding: 0.2rem 0 0.6rem;
  color: inherit;
}
.sidebar-product .widget.widget-collapse .widget-title a:hover, .sidebar-product .widget.widget-collapse .widget-title a:focus {
  color: #222529;
  text-decoration: none;
}
.sidebar-product .widget.widget-collapse .widget-title a:after {
  display: inline-block;
  position: absolute;
  top: 42%;
  right: 0.1rem;
  transform: translateY(-50%);
  transition: all 0.35s;
  font-family: "porto";
  font-size: 1.7rem;
  font-weight: 400;
  content: "\e81b";
}
.sidebar-product .widget.widget-collapse .widget-title a.collapsed:after {
  content: "\e81c";
}
.sidebar-product .widget.widget-collapse .widget-body {
  padding-top: 1.3rem;
  padding-bottom: 0.5rem;
}
.sidebar-product .widget.widget-collapse .widget-body:after {
  display: block;
  clear: both;
  content: "";
}

.sidebar-product {
  margin-bottom: 2.8rem;
}
.sidebar-product .widget:not(:last-child) {
  margin-bottom: 3rem;
}
.sidebar-product .widget-title {
  margin: 0;
  color: #222529;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  border-bottom-width: 1px;
}
.sidebar-product .widget-subtitle {
  color: #7a7d82;
  margin-bottom: 3rem;
  font-size: 1.3rem;
  font-weight: 400;
}
.sidebar-product .widget-body {
  padding-left: 0;
  padding-top: 2.3rem;
}
.sidebar-product .widget-body p {
  line-height: 27px;
  font-size: 1.3rem;
  color: #222529;
  letter-spacing: 0.01em;
  font-weight: 500;
  margin-bottom: 3rem;
}

.widget-info ul {
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  justify-content: space-between;
  -ms-flex-pack: justify;
  flex-wrap: wrap;
  -ms-flex-wrap: wrap;
  margin: 0;
}
aside .widget-info ul {
  display: block;
}
.widget-info li {
  margin-bottom: 2rem;
}
.widget-info li:not(:last-child) {
  margin-right: 2.5rem;
}
aside .widget-info li:not(:last-child) {
  border-bottom: 1px solid #dee5e8;
  padding-bottom: 2.2rem;
  margin-right: 0;
}
.widget-info i {
  min-width: 40px;
  margin-right: 15px;
  color: #e1ddc3;
  font-size: 4rem;
  line-height: 1;
}
.widget-info i:before {
  margin: 0;
}
aside .widget-info i {
  margin-left: 7px;
}
.widget-info h4 {
  display: inline-block;
  margin-bottom: 0;
  color: #6b7a83;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.286;
  font-family: "Euclid", sans-serif;
  text-transform: uppercase;
}

.product-single-collapse {
  line-height: 1.9;
  margin-bottom: 2rem;
}
.product-single-collapse p {
  margin-bottom: 2.5rem;
}
.product-single-collapse .product-desc-content ul, .product-single-collapse .product-desc-content ol {
  margin-bottom: 2.5rem;
}

.product-collapse-title {
  margin: 0;
  font-size: 1.4rem;
  line-height: 1;
  text-transform: uppercase;
}
.product-collapse-title a {
  display: block;
  position: relative;
  padding: 1.5rem;
  border-bottom: 1px solid #ddd;
  color: inherit;
}
.product-collapse-title a:hover, .product-collapse-title a:focus {
  color: inherit;
  text-decoration: none;
}
.product-collapse-title a:before {
  content: "\e81b";
  margin-right: 1rem;
  font-family: "porto";
  font-size: 2rem;
  font-weight: 400;
}
.product-collapse-title a:after {
  display: block;
  position: absolute;
  bottom: -0.2rem;
  left: 0;
  width: 100%;
  height: 0.2rem;
  transform-origin: left center;
  transform: scale(1, 1);
  transition: transform 0.4s;
  background-color: #e1ddc3;
  content: "";
}
.product-collapse-title a.collapsed:before {
  content: "\e81c";
}
.product-collapse-title a.collapsed:after {
  transform-origin: right center;
  transform: scale(0, 1);
}

.collapse-body-wrapper {
  padding: 3rem 0 1.5rem 2rem;
}

.sidebar-toggle {
  position: fixed;
  top: 20%;
  left: 0;
  width: 40px;
  height: 40px;
  transition: left 0.2s ease-in-out 0s;
  border: #dcdcda solid 1px;
  border-left-width: 0;
  background: #fff;
  font-size: 17px;
  line-height: 38px;
  text-align: center;
  cursor: pointer;
  z-index: 999;
  margin-top: 50px;
}
.sidebar-opened .sidebar-toggle {
  left: 260px;
  z-index: 9000;
}
.sidebar-opened .sidebar-toggle i:before {
  content: "\f00d";
}

@media (min-width: 992px) {
  .main-content-wrap {
    overflow: hidden;
  }
  .main-content-wrap .main-content {
    margin-left: -25%;
    transition: 0.15s linear;
  }
  .main-content-wrap .sidebar-shop {
    left: -25%;
    transition: 0.15s linear;
    visibility: hidden;
    z-index: -1;
  }
  .sidebar-opened .main-content-wrap > .sidebar-shop {
    left: 0;
    visibility: visible;
    z-index: 0;
  }
  .sidebar-opened .main-content-wrap > .main-content {
    margin-left: 0;
  }
  main:not(.sidebar-opened) .main-content-wrap > .main-content {
    max-width: 100%;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
  }
  .sidebar-toggle {
    display: none;
  }
}
@media (min-width: 576px) {
  .sidebar-product .widget.widget-collapse {
    padding: 2.2rem 3rem;
  }
}
@media (max-width: 991px) {
  .mobile-sidebar {
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 260px;
    padding: 2rem;
    margin: 0;
    transform: translate(-260px);
    transition: transform 0.2s ease-in-out 0s;
    background-color: #fff;
    z-index: 9999;
    overflow-y: auto;
  }
  .sidebar-opened .mobile-sidebar {
    transform: none;
  }
  .sidebar-opened .sidebar-overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: #000;
    opacity: 0.35;
    z-index: 8999;
  }
}
@media (max-width: 575px) {
  .widget-info ul {
    display: block;
  }
}
.product-single-grid .product-single-gallery .product-item {
  margin-bottom: 2rem;
}
.product-single-grid .inner {
  position: relative;
}
.product-single-grid .product-item:hover .prod-full-screen {
  opacity: 1;
}

.product-size-content img {
  margin-right: auto;
  margin-bottom: 2rem;
  margin-left: auto;
}

.table.table-size thead tr th,
.table.table-size tbody tr td {
  border: 0;
  color: #21293c;
  font-size: 1.5rem;
  letter-spacing: 0.005em;
  text-transform: uppercase;
}
.table.table-size thead tr th {
  padding: 2.8rem 1.5rem 1.7rem;
  background-color: #f4f4f2;
  font-weight: 600;
}
.table.table-size tbody tr td {
  padding: 1rem 1.5rem;
  background-color: #fff;
  font-weight: 700;
}
.table.table-size tbody tr td:first-child {
  font-weight: 600;
}
.table.table-size tbody tr:nth-child(2n) td {
  background-color: #ebebeb;
}

@media (min-width: 992px) {
  .table.table-size thead tr th {
    padding-top: 4.2rem;
    padding-bottom: 2.8rem;
  }
  .table.table-size thead tr th,
  .table.table-size tbody tr td {
    padding-right: 3.5rem;
    padding-left: 3.5rem;
  }
}
.cart-table-container {
  margin-bottom: 2.5rem;
}
.cart-table-container .product-image img {
  max-width: 18rem;
}

.table.table-cart tr th,
.table.table-cart tr td {
  vertical-align: middle;
}
.table.table-cart tr th:not(.product-col),
.table.table-cart tr td:not(.product-col) {
  text-align: center;
}
.table.table-cart tr th {
  padding: 2.4rem 1.5rem 1.2rem;
  border: 0;
  font-weight: 600;
}
.table.table-cart tr td {
  padding: 2rem 1.5rem;
  border-top: 1px solid #ccc;
}
.table.table-cart tr td.product-col {
  padding: 2rem 0.8rem 1.8rem 0;
}
.table.table-cart tr.product-action-row td {
  padding: 0 0 2.2rem;
  border: 0;
}
.table.table-cart .product-title {
  margin-bottom: 0;
}
.table.table-cart tfoot td {
  padding: 2rem 0 1rem;
}
.table.table-cart tfoot .btn {
  padding: 0.85rem 2rem;
  font-family: Euclid, sans-serif;
  letter-spacing: 0;
  text-transform: initial;
}
.table.table-cart tfoot .btn + .btn {
  margin-left: 1rem;
}
.table.table-cart .bootstrap-touchspin.input-group {
  margin-right: auto;
  margin-left: auto;
}

.btn-remove:hover, .btn-remove:focus,
.btn-edit:hover,
.btn-edit:focus,
.btn-move:hover,
.btn-move:focus {
  color: #e1ddc3;
  text-decoration: none;
}

.btn-edit {
  margin-right: 1rem;
  font-size: 1.3rem;
}

.btn-move {
  font-size: 1.3rem;
  line-height: 2.5rem;
}
.btn-move:hover, .btn-move:focus {
  text-decoration: underline;
}

.qty-col {
  min-width: 98px;
}

tbody .product-col {
  font-size: 0;
}
.product-col .product-image-container {
  display: table-cell;
  padding-right: 1.8rem;
  margin-bottom: 0;
  vertical-align: middle;
}
.product-col .product-image img {
  border: 1px solid #ccc;
}
.product-col .product-title {
  display: table-cell;
  vertical-align: middle;
}

.cart-discount {
  margin-bottom: 4rem;
}
.cart-discount h4 {
  margin-bottom: 1.2rem;
  font-size: 1.6rem;
  font-weight: 400;
}
.cart-discount form {
  max-width: 420px;
}

.cart-summary {
  margin-bottom: 2.5rem;
  padding: 1.4rem 1.8rem 2.2rem;
  border: 1px solid #ddd;
  background: #fbfbfb;
}
.cart-summary h3 {
  margin-bottom: 1.3rem;
  font-size: 2.5rem;
  font-weight: 300;
}
.cart-summary h4 {
  margin-bottom: 0;
  border-top: 1px solid #ccc;
  font-size: 1.5rem;
  font-weight: 600;
}
.cart-summary h4 a {
  display: block;
  position: relative;
  padding: 1rem 2rem 1rem 0;
}
.cart-summary h4 a:after {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0.4rem;
  transform: translateY(-50%);
  font-family: "porto";
  font-size: 1.4rem;
  content: "\e81b";
}
.cart-summary h4 a.collapsed:after {
  content: "\e81c";
}

#total-estimate-section form {
  margin-bottom: 0;
  padding: 0.7rem 0 2.2rem;
}

.table.table-totals {
  margin-bottom: 2rem;
  border-top: 1px solid #ccc;
}
.table.table-totals tbody tr:first-child td {
  padding-top: 1.6rem;
}
.table.table-totals tr {
  border: 0;
}
.table.table-totals tr th,
.table.table-totals tr td {
  padding: 0.6rem 0;
  border: 0;
}
.table.table-totals tr th:last-child,
.table.table-totals tr td:last-child {
  text-align: right;
}
.table.table-totals tfoot {
  border-top: 1px solid #ccc;
  font-size: 1.8rem;
  font-weight: 600;
}
.table.table-totals tfoot tr td {
  padding-top: 1.3rem;
  padding-bottom: 1.6rem;
}

.checkout-methods .btn {
  letter-spacing: 0;
}
.checkout-methods .btn + .btn {
  margin-top: 2.2rem;
}

.cart-plus-minus {
  position: relative;
  display: flex;
  justify-content: center;
}
.cart-plus-minus input {
  border: 1px solid #ccc;
  text-align: center;
  margin-bottom: 0;
  height: 4.2rem;
  max-width: 46px;
  padding: 1.1rem 1rem;
  border-radius: 0;
  box-shadow: none;
  color: #777;
  font-family: Poppins, sans-serif;
}
.cart-plus-minus input:focus {
  border-radius: 0;
  outline: none;
}
.cart-plus-minus__actions {
  display: flex;
  flex-direction: column;
  margin-left: 0.2rem;
  justify-content: space-between;
}
.cart-plus-minus__actions button {
  height: 2rem;
  padding: 0;
  width: 2rem;
  text-align: center;
  font-size: 1.2rem;
  border: 1px solid #ccc;
  background-color: transparent;
  color: #ccc;
}
.cart-plus-minus__actions button:hover {
  cursor: pointer;
}
.cart-plus-minus__actions button:before {
  font-family: "porto";
  font-style: normal;
  font-weight: normal;
  speak: none;
}
.cart-plus-minus__actions button.inc:before {
  content: "\e80d";
}
.cart-plus-minus__actions button.desc:before {
  content: "\e80b";
}

@media (max-width: 767px) {
  .table.table-cart,
  .table.table-cart tbody,
  .table.table-cart tfoot {
    display: block;
  }
  .table.table-cart thead {
    display: none;
  }
  .table.table-cart tr td {
    padding: 0.5rem 1rem;
    border-top: 0;
  }
  .table.table-cart tr td.product-col {
    padding-bottom: 0.5rem;
  }
  .table.table-cart .product-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    border-top: 1px solid #ccc;
  }
  .table.table-cart .product-action-row {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
  }
  .table.table-cart .product-action-row td {
    width: 100%;
  }
  .table.table-cart .product-action-row .float-right {
    margin-top: -4px;
    margin-left: auto;
  }
  .table.table-cart .product-col {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
  }
  .table.table-cart .product-col .product-image-container {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    margin-right: 0;
    margin-bottom: 1rem;
  }
  .table.table-cart tfoot {
    border-top: 1px solid #ccc;
  }
  .table.table-cart tfoot tr {
    display: block;
    width: 100%;
  }
  .table.table-cart tfoot tr td {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-align: start;
    align-items: flex-start;
    padding: 1rem 0;
  }
  .table.table-cart tfoot .btn {
    margin-top: 0.5rem;
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .table.table-cart tfoot .float-left {
    display: none;
  }
  .table.table-cart tfoot .float-right {
    margin-left: auto;
  }
}
.checkout-progress-bar {
  display: block;
  margin: 0 0 3rem;
  font-size: 0;
  line-height: 1.4;
  counter-reset: i;
}
.checkout-progress-bar li {
  display: inline-block;
  position: relative;
  width: 50%;
  margin: 0;
  text-align: center;
  vertical-align: top;
}
.checkout-progress-bar li:before {
  position: absolute;
  top: 1.9rem;
  left: 0;
  width: 100%;
  height: 0.7rem;
  transition: background 0.3s;
  background-color: #e4e4e4;
  content: "";
}
.checkout-progress-bar li > span {
  display: inline-block;
  width: 100%;
  padding-top: 4.5rem;
  color: #ccc;
  font-size: 1.6rem;
  font-weight: 300;
  word-wrap: break-word;
}
.checkout-progress-bar li > span:before, .checkout-progress-bar li > span:after {
  position: absolute;
  top: 0;
  left: 50%;
  transition: background 0.3s;
  border-radius: 50%;
  content: "";
}
.checkout-progress-bar li > span:before {
  width: 3.8rem;
  height: 3.8rem;
  margin-left: -1.9rem;
  background-color: #e4e4e4;
}
.checkout-progress-bar li > span:after {
  top: 0.6rem;
  width: 2.6rem;
  height: 2.6rem;
  margin-left: -1.3rem;
  background: #fff;
  color: #777;
  font-weight: 600;
  content: counter(i);
  counter-increment: i;
}
.checkout-progress-bar li.active:before,
.checkout-progress-bar li.active > span:before {
  background-color: #ff5501;
}
.checkout-progress-bar li.active > span {
  color: #000;
}
.checkout-progress-bar li.active > span:after {
  font-family: "porto";
  content: "\e84e";
}
.checkout-progress-bar li:first-child:before {
  border-radius: 0.6rem 0 0 0.6rem;
}
.checkout-progress-bar li:last-child:before {
  border-radius: 0 0.6rem 0.6rem 0;
}

.checkout-steps li {
  margin-bottom: 3rem;
}
.checkout-steps li form {
  max-width: 580px;
  margin-top: 3rem;
  margin-bottom: 0;
  padding-bottom: 2rem;
  border-bottom: 1px solid #ccc;
}
.checkout-steps li form:last-of-type {
  padding-bottom: 0.1rem;
  border-bottom: 0;
}
.checkout-steps li form p {
  margin-bottom: 1rem;
  color: #777;
  font-size: 1.3rem;
}
.checkout-steps li .form-footer {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.step-title {
  margin-bottom: 0;
  padding-bottom: 9px;
  border-bottom: 1px solid #ccc;
  font-size: 2.2rem;
  font-weight: 300;
}
.step-title:after {
  display: block;
  clear: both;
  content: "";
}
.step-title .step-title-edit {
  margin-top: 0.4rem;
  float: right;
  font-size: 1.4rem;
}

.shipping-step-addresses {
  margin-bottom: 2rem;
  font-size: 0;
}
.shipping-step-addresses:after {
  display: block;
  clear: both;
  content: "";
}

.shipping-address-box {
  display: inline-block;
  position: relative;
  width: 100%;
  min-height: 240px;
  margin: 0;
  padding: 1.8rem 3.2rem 1rem 1.8rem;
  transition: 0.3s border-color;
  border: 0.2rem solid transparent;
  font-size: 1.3rem;
  line-height: 3rem;
  vertical-align: top;
  word-wrap: break-word;
}
.shipping-address-box.active {
  border-color: #ff5501;
}
.shipping-address-box.active:after {
  position: absolute;
  top: 0;
  right: 0;
  width: 2.7rem;
  height: 2.7rem;
  padding-top: 0.2rem;
  background-color: #ff5501;
  color: #fff;
  font-family: "porto";
  font-size: 1.9rem;
  line-height: 2.1rem;
  text-align: center;
  content: "\e84e";
}
.shipping-address-box.active .address-box-action {
  visibility: hidden;
}

.address-box-action {
  margin-top: 2rem;
}
.address-box-action .btn {
  font-family: Euclid, sans-serif;
  letter-spacing: 0;
  text-transform: initial;
}
.address-box-action .btn.btn-sm {
  padding: 0.85rem 1.2rem;
  font-size: 1.3rem;
}
.address-box-action .btn.btn-sm.btn-link {
  padding-right: 0;
  padding-left: 0;
}

.btn-new-address {
  margin-bottom: 1.2rem;
  padding: 0.95rem 1.3rem;
  font-family: Euclid, sans-serif;
  letter-spacing: 0;
  text-transform: initial;
}

.table-step-shipping {
  max-width: 600px;
}
.table-step-shipping tr:hover {
  cursor: pointer;
}
.table-step-shipping tr td {
  padding: 0.75rem;
  border: 0;
}
.table-step-shipping tr td:first-child {
  width: 10%;
}

.delivery-method li input {
  padding: 0.75rem;
  width: 2.8rem;
}
.delivery-method li label {
  color: inherit;
  padding: 0.75rem;
  margin: 0;
}
.delivery-method li:hover {
  cursor: pointer;
}

.checkout-steps-action:after {
  display: block;
  clear: both;
  content: "";
}
.checkout-steps-action .btn {
  min-width: 70px;
  text-align: center;
}

.order-summary {
  margin-top: 3.3rem;
  margin-bottom: 3rem;
  padding: 2.2rem 2.7rem;
  border: 1px solid #ddd;
  background: #f5f5f5;
}
.order-summary h3 {
  margin-bottom: 1.3rem;
  font-size: 2.5rem;
  font-weight: 300;
}
.order-summary h4 {
  margin-bottom: 0;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  font-size: 1.5rem;
  font-weight: 600;
}
.order-summary h4 a {
  display: block;
  position: relative;
  padding: 1rem 2rem 1rem 0;
}
.order-summary h4 a:after {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0.4rem;
  transform: translateY(-50%);
  font-family: "porto";
  font-size: 1.5rem;
  font-weight: 400;
  content: "\e81b";
}
.order-summary h4 a.collapsed:after {
  content: "\e81c";
}

.table-mini-cart {
  margin-bottom: 0;
}
.table-mini-cart tr td {
  padding: 2rem 0;
  border: 0;
  border-bottom: 1px solid #ccc;
  vertical-align: top;
}
.table-mini-cart .product-col .product-image-container {
  max-width: 75px;
  padding-right: 1.5rem;
  vertical-align: top;
}
.table-mini-cart .product-col > div {
  display: table-cell;
  padding-top: 0.7rem;
  vertical-align: top;
}
.table-mini-cart .price-col {
  padding-top: 2.5rem;
  font-size: 1.6rem;
  font-weight: 400;
}
.table-mini-cart .product-title {
  display: block;
  max-width: 170px;
  margin-bottom: 0.8rem;
  font-size: 1.4rem;
  font-weight: 600;
}
.table-mini-cart .product-qty {
  display: block;
  color: #777;
  font-size: 1.3rem;
}

.checkout-payment {
  margin-bottom: 3rem;
}
.checkout-payment .step-title {
  margin-bottom: 1.8rem;
}
.checkout-payment h4 {
  font-weight: 400;
}
.checkout-payment .form-group {
  max-width: 480px;
}

#checkout-shipping-address {
  display: none;
  padding-left: 3rem;
  line-height: 3rem;
}
#checkout-shipping-address.show {
  display: block;
}

#new-checkout-address {
  display: none;
}
#new-checkout-address.show {
  display: block;
}

.checkout-discount {
  margin-top: 2rem;
  margin-bottom: 3rem;
}
.checkout-discount form {
  max-width: 480px;
  margin-bottom: 0;
  padding-top: 1.5rem;
  padding-bottom: 1rem;
}
.checkout-discount h4 {
  margin-bottom: 0;
  border-top: 1px solid #ccc;
  font-size: 1.4rem;
  font-weight: 400;
}
.checkout-discount h4 a {
  display: block;
  position: relative;
  padding: 1rem 2rem 1rem 0;
}
.checkout-discount h4 a:after {
  display: inline-block;
  position: absolute;
  top: 50%;
  right: 0.4rem;
  transform: translateY(-50%);
  font-family: "porto";
  font-size: 1.5rem;
  content: "\e81b";
}
.checkout-discount h4 a.collapsed:after {
  content: "\e81c";
}

.checkout-info-box {
  font-size: 1.3rem;
  line-height: 2.7rem;
}
.checkout-info-box .step-title {
  margin-bottom: 1.8rem;
}

@media (min-width: 576px) {
  .checkout-progress-bar li {
    width: 185px;
  }
  .checkout-progress-bar li > span {
    font-size: 1.8rem;
  }
  .shipping-address-box {
    width: 50%;
  }
}
@media (min-width: 768px) {
  .shipping-address-box {
    width: 33.33%;
  }
}
.post {
  margin-bottom: 3rem;
}
.post a {
  color: inherit;
}
.post a:hover, .post a:focus {
  text-decoration: underline;
}
.post .read-more {
  float: right;
}
.post .read-more i:before {
  margin: 0;
}

.post-media {
  margin-bottom: 2rem;
  border-radius: 0;
  background-color: #ccc;
}
.post-media .post-date {
  position: absolute;
  top: 1rem;
  left: 1rem;
  width: 4.5rem;
  padding: 1rem 0.8rem;
  color: #fff;
  background: #222529;
  font-family: Euclid, sans-serif;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.05em;
}
.post-media .day {
  display: block;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1;
}
.post-media .month {
  display: block;
  font-size: 1.12rem;
  line-height: 1;
  opacity: 0.7;
}

.post-slider {
  margin-bottom: 3rem;
}
.post-slider .owl-dots {
  position: absolute;
  right: 0;
  bottom: 0.25rem;
  left: 0;
  margin: 0 !important;
}

.post-body {
  padding-bottom: 2.7rem;
  border-bottom: 1px solid #ddd;
}
.post-body .post-date {
  width: 45px;
  margin-right: 10px;
  float: left;
  text-align: center;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
}
.post-body .post-date .day {
  display: block;
  margin-bottom: 0.3rem;
  padding: 1rem 0.2rem;
  border-radius: 0.2rem 0.2rem 0 0;
  background-color: #f4f4f4;
  color: #e1ddc3;
  font-size: 1.6rem;
  font-weight: 700;
  line-height: 1.375;
}
.post-body .post-date .month {
  display: block;
  padding: 0 0.2rem 0.2rem;
  border-radius: 0 0 0.2rem 0.2rem;
  background-color: #e1ddc3;
  color: #fff;
  font-size: 1.2rem;
  line-height: 1.33;
  box-shadow: 0 -1px 0 0 rgba(0, 0, 0, 0.07) inset;
}

.post-title {
  margin-bottom: 1rem;
  color: #222529;
  font-size: 2.2rem;
  font-weight: 400;
  line-height: 1.35;
}

.post-content:after {
  display: block;
  clear: both;
  content: "";
}

.post-meta > span {
  display: inline-block;
  margin-right: 1.5rem;
}
.post-meta i {
  margin-right: 0.5rem;
}
.post-meta i:before {
  margin: 0;
}

.post-date-in-media .post-media {
  overflow: hidden;
}
.post-date-in-media .post-media img {
  transition: transform 0.2s;
}
.post-date-in-media .post-media:hover img {
  transform: scale(1.1, 1.1);
}
.post-date-in-media .post-body {
  margin-left: 0;
  padding-bottom: 2.1rem;
  border: 0;
}
.post-date-in-media .post-title {
  margin-bottom: 1.6rem;
  font-size: 1.8rem;
  font-family: Euclid, sans-serif;
  font-weight: 700;
}
.post-date-in-media p {
  font-size: 1.3rem;
  line-height: 1.846;
}
.post-date-in-media .post-comment {
  color: #999;
  font-size: 1rem;
  text-transform: uppercase;
}

.single .post-meta {
  margin-bottom: 2.4rem;
}
.single .post-title {
  font-size: 2.4rem;
}
.single h3 {
  font-size: 2.2rem;
  font-weight: 400;
}
.single h3 i {
  margin-right: 0.2rem;
}
.single .post-content {
  margin-bottom: 4rem;
}

.post-share {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin-bottom: 2.6rem;
  padding: 2.8rem 0;
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}
.post-share h3 {
  margin-right: 1.5rem;
}

.post-author {
  margin-bottom: 3rem;
  padding-bottom: 2.7rem;
  border-bottom: 1px solid #ddd;
}
.post-author:after {
  display: block;
  clear: both;
  content: "";
}
.post-author h3 {
  margin-bottom: 2rem;
}
.post-author figure {
  max-width: 80px;
  margin-right: 2rem;
  margin-bottom: 0;
  float: left;
}
.post-author h4 {
  margin: 1rem;
  font-weight: 600;
}
.post-author .author-content {
  font-size: 1.3rem;
  line-height: 1.8;
}
.post-author .author-content p:last-child {
  margin-bottom: 0;
}

.comment-respond h3 {
  margin-bottom: 1.2rem;
}
.comment-respond h3 + p {
  margin-bottom: 2.6rem;
}
.comment-respond form {
  margin-bottom: 0;
}

.related-posts {
  margin-bottom: 4rem;
}
.related-posts h4 {
  margin-bottom: 2rem;
  font-size: 2rem;
  text-transform: uppercase;
}
.related-posts .post {
  margin-bottom: 0;
  padding-bottom: 0;
  border-bottom: 0;
}
.related-posts .post p {
  margin-bottom: 1rem;
}
.related-posts .post-body {
  padding-bottom: 0;
  border-bottom: 0;
}
.related-posts .post-media {
  margin-bottom: 2rem;
}
.related-posts .post-title {
  margin-bottom: 1rem;
  font-size: 2rem;
}

.sidebar {
  position: relative;
  font-size: 1.3rem;
}
.sidebar .widget {
  margin-bottom: 3rem;
}
.sidebar .sidebar-wrapper {
  background-color: #fff;
}
.sidebar .sidebar-wrapper .widget:last-child {
  margin-bottom: 0;
  padding-bottom: 3rem;
}

.widget-title {
  margin: 0.5rem 0 1.3rem;
  color: #000;
  font-size: 1.8rem;
  font-weight: 700;
  line-height: 1.2;
}

.widget form {
  margin-bottom: 0;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.list li {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
}
.list li.active {
  font-weight: 600;
}
.list li a {
  display: block;
  position: relative;
  padding: 0.8rem 0 0.8rem 2rem;
  border-bottom: 1px solid #ededde;
  color: #000;
  font-size: 1.3rem;
}
.list li a:before {
  display: inline-block;
  position: absolute;
  width: 0;
  height: 0;
  margin: 0.6rem 0 0 -1rem;
  border-top: 4px solid transparent;
  border-bottom: 4px solid transparent;
  border-left: 4px solid #333;
  content: "";
}
.list li a:hover, .list li a:focus {
  background-color: #eee;
  text-decoration: none;
}

.widget-search form {
  position: relative;
  margin: 0;
}
.widget-search .form-control {
  width: 100%;
  max-width: none;
  height: 5rem;
  padding-top: 1.5rem;
  padding-right: 5.5rem;
  padding-bottom: 1.5rem;
}
.widget-search .search-submit {
  position: absolute;
  top: 50%;
  right: 0;
  width: 44px;
  height: 44px;
  margin-top: -22px;
  border: 0;
  outline: none;
  background-color: transparent;
  color: #e1ddc3;
  font-size: 1.3rem;
  text-align: center;
  box-shadow: none;
  cursor: pointer;
}

.tagcloud:after {
  display: block;
  clear: both;
  content: "";
}
.tagcloud a {
  display: block;
  margin: 0 0.7rem 0.7rem 0;
  padding: 1.1rem 1.4rem;
  float: left;
  background-color: #e1e1e1;
  color: #7e7d79;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 11px;
}
.tagcloud a:hover, .tagcloud a:focus {
  background-color: #e1ddc3;
  color: #fff;
  text-decoration: none;
}

.simple-post-list {
  margin: 0;
  padding: 0;
  list-style: none;
}
.simple-post-list li {
  margin: 0 0 1rem;
}
.simple-post-list li:after {
  display: block;
  clear: both;
  content: "";
}
.simple-post-list li:last-child {
  margin-bottom: 0;
}
.simple-post-list .post-media {
  width: 6.5rem;
  margin: 0 1.2rem 0 0;
  float: left;
  border-radius: 0;
  line-height: 0;
}
.simple-post-list .post-media img {
  display: block;
  width: 100%;
  max-width: none;
  height: auto;
}
.simple-post-list .post-meta {
  color: #888;
  font-size: 1.3rem;
}

@media (min-width: 576px) {
  .post-share {
    flex-direction: row;
    align-items: center;
  }
  .post-share h3 {
    margin-bottom: 0;
  }
  .related-posts {
    padding-top: 1rem;
  }
}
@media (min-width: 768px) {
  .single .post-meta {
    margin-bottom: 3.2rem;
  }
  .single .post-title {
    font-size: 2.6rem;
  }
  .post-body {
    margin-left: 60px;
  }
  .post-body .post-date {
    margin-left: -60px;
  }
  .related-posts {
    padding-top: 2.5rem;
  }
}
@media (max-width: 767px) {
  .comment-respond .form-footer {
    margin-bottom: 3rem;
  }
}
@media (max-width: 767px) {
  .comment-respond .form-footer {
    margin-bottom: 2rem;
  }
}
#map {
  height: 280px;
  margin-bottom: 4rem;
  background-color: #ccc;
}
#map address {
  margin: 0;
  padding: 0.625rem 0.875rem;
  font-size: 1.3rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
}
#map a {
  display: inline-block;
  margin-top: 0.8rem;
  font-size: 1.2rem;
  text-transform: uppercase;
}

.contact-info {
  margin-bottom: 3rem;
  padding-top: 0.5rem;
}

.contact-info > div {
  margin-bottom: 1.5rem;
}
.contact-info > div:after {
  display: block;
  clear: both;
  content: "";
}

.contact-info i {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 4.3rem;
  height: 4.3rem;
  float: left;
  border-radius: 0;
  background-color: #e1ddc3;
  color: #fff;
  font-size: 1.6rem;
  text-align: center;
}

.contact-info p {
  margin-bottom: 0;
  margin-left: 5.5rem;
}
.contact-info p:first-of-type {
  padding-top: 0.1rem;
}

@media (min-width: 768px) {
  #map {
    height: 380px;
    margin-bottom: 5rem;
  }
}
@media (min-width: 992px) {
  #map {
    height: 460px;
    margin-bottom: 6rem;
  }
}
.dashboard-content {
  margin-bottom: 1em;
}

.dashboard-content h2 {
  margin-bottom: 2rem;
  font-size: 2.4rem;
}

.dashboard-content .form-control {
  max-width: 100%;
}

#account-chage-pass {
  display: none;
  padding-bottom: 1.5rem;
}
#account-chage-pass.show {
  display: block;
}

.widget-dashboard .widget-title {
  margin-top: 0.2rem;
  font-size: 1.8rem;
  font-weight: 600;
}

.widget-dashboard {
  margin-bottom: 3rem;
}

@media (min-width: 768px) {
  .dashboard-content h2 {
    font-size: 2.6rem;
  }
}
.home-slide {
  height: 100%;
  background: #9C9895;
}
.owl-carousel .owl-item .home-slide div.slide-bg {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-height: none;
}
.home-slide .banner-layer {
  position: relative;
  padding: 7% 9.8% 0 10%;
}
@media (max-width: 575px) {
  .home-slide .banner-layer {
    padding: 0;
  }
}
.home-slide h2 {
  margin-left: -3px;
  font-family: "Euclid";
  font-size: 3em;
  letter-spacing: 0.001em;
  line-height: 1;
}
.home-slide h3 {
  font-size: 7.0625em;
  line-height: 1;
}
.home-slide h5 {
  margin-right: 2.2em;
  font-size: 1.25em;
  font-weight: 700;
  line-height: 1;
  vertical-align: middle;
}
.home-slide em {
  font-size: 2.125em;
  font-style: normal;
  vertical-align: text-top;
  margin-top: -2px;
  display: inline-block;
  letter-spacing: 0.01em;
}
.home-slide .btn-xl {
  padding: 1.125em 2.5em;
  margin-top: -4px;
  font-size: 1.25em;
  letter-spacing: -0.025em;
}

.home-slide1 .slide-bg {
  background-position: 34%;
}
@media (max-width: 575px) {
  .home-slide1 .slide-bg {
    background-position: 70%;
  }
}

.home-slide2 h2 {
  font-size: 3.47em;
}

.rotated-upto-text {
  position: relative;
  padding-left: 0.265em;
}
.rotated-upto-text small {
  display: inline-block;
  position: absolute;
  left: -1em;
  top: 50%;
  transform: translateY(-50%) rotate(-90deg);
  font-size: 0.2641em;
  font-weight: 500;
  letter-spacing: -0.02em;
}

.divider-short-thick {
  margin: 2.2rem auto 0 0;
  width: 60px;
  border-top: 5px solid #222529;
}

.banners-container {
  background-color: #efefef;
}
.banners-container .banner {
  overflow: hidden;
  margin-bottom: 0;
  background-color: transparent;
}
.banners-container img {
  max-height: 210px;
  object-fit: cover;
}
.banners-container .banner-content {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  padding: 2rem;
  font-size: 1.3rem;
  line-height: 1.154;
  letter-spacing: -0.025em;
}
.banners-container .banner-title {
  font-size: 2.2rem;
  letter-spacing: -0.025em;
}
.banners-container hr {
  width: 35px;
  border-top-width: 4px;
  margin: 1.9rem 0 1.8rem;
}
.banners-container .btn {
  color: #222529;
  padding: 0;
}

.banners-container .banner,
.info-boxes-container .info-box {
  border-left: 1px solid #dedede;
  border-right: 1px solid #dedede;
}

.info-boxes-container .info-box {
  border-bottom: 1px solid #e7e7e7;
  padding-left: 2.4rem;
  padding-right: 2.4rem;
}

.promo-section {
  padding-top: 12.5rem;
  padding-bottom: 11.9rem;
  background-color: #24252A;
}

.category-banner {
  padding-top: 9rem;
  padding-bottom: 7.8rem;
  background-color: #24252A;
}
.promo-content {
  position: relative;
  text-align: center;
}
.promo-content h2 {
  color: #fff;
  font-size: 4.088rem;
  font-family: "Euclid";
  line-height: 1;
  letter-spacing: 0.001em;
  text-transform: capitalize;
}
.promo-content h3 {
  color: #fff;
  font-size: 8.886rem;
  line-height: 1;
}
.promo-content hr {
  border-top-color: #fff;
}
.promo-content .btn {
  padding: 1.22em 2.95em;
}
.promo-content .divider-short-thick {
  margin: 2.2rem auto 3rem;
  width: 53.38px;
  border-top-width: 4.44px;
}

.section-title {
  font-size: 2.2rem;
  text-transform: uppercase;
}

.section-description {
  font-size: 1.4rem;
}

.section-sub-title {
  font-size: 1.2rem;
  letter-spacing: 0.05em;
  font-weight: 600;
  text-transform: uppercase;
}

.blog-section {
  padding-top: 5.7rem;
  padding-bottom: 5.7rem;
  background-color: #f4f4f2;
}
.blog-section .post {
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  margin-bottom: 0;
}
.blog-section .post-media {
  margin: 0;
}
.blog-section .post-body {
  flex: 1;
  -ms-flex: 1;
  z-index: 1;
  position: relative;
  margin: 0 0 0 -35px;
  padding: 3.3rem 4rem 3.2rem;
  border: 0;
  background-color: #fff;
}
.blog-section .post-body .post-date {
  display: inline-block;
  float: none;
  width: auto;
  margin: 0 0 0.7rem;
  padding: 0.7rem 0.8rem;
  color: #fff;
  background-color: #222529;
  font-size: 1.1rem;
  font-weight: 700;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  box-shadow: none;
}
.blog-section .post-title {
  margin-bottom: 0.8rem;
  font-size: 1.8rem;
  font-weight: 600;
  letter-spacing: -0.01em;
  text-transform: uppercase;
}
.blog-section .post-content {
  font-size: 1.3rem;
  letter-spacing: 0.03em;
  line-height: 1.9;
}
.blog-section .post-content p {
  max-width: 250px;
  margin-bottom: 0.7rem;
}
.blog-section .read-more {
  float: left;
  color: #222529;
  font-size: 1.4rem;
  font-weight: 700;
  letter-spacing: -0.025em;
  text-transform: uppercase;
}
.blog-section .read-more i {
  display: inline-block;
  margin-top: -0.4rem;
  line-height: 1;
  vertical-align: middle;
}

.brands-section {
  padding-top: 3.2rem;
  padding-bottom: 3.2rem;
}

.product-default .label-group {
  top: 1.2rem;
  left: 1.2rem;
}
.product-default .product-label {
  padding: 6px 6px 5px 7px;
  font-size: 9px;
  font-family: "Euclid", sans-serif;
}
.product-default .product-label.label-sale {
  background-color: #EE8379;
}

.price-box {
  font-family: "Euclid", sans-serif;
}

.product-list .btn-icon-wish,
.product-list .btn-quickview {
  border-color: #e1ddc3;
  color: #61605a;
}

.product-widget {
  margin-bottom: 2rem;
}
.product-widget .ratings-container {
  margin-bottom: 1.2rem;
}
.product-widget figure {
  margin-right: 1.2rem;
}
.product-widget .product-title {
  font-weight: 500;
  margin-bottom: 0;
}
.product-widget .ratings,
.product-widget .product-ratings {
  font-size: 10px;
}
.product-widget .ratings:before {
  color: #706f6c;
}
.product-widget .price-box {
  margin-bottom: 0;
}
.product-widget .product-price {
  color: #222529;
  font-size: 1.8rem;
  letter-spacing: 0.005em;
}
.product-widget:hover figure {
  box-shadow: none;
}

.categories-slider .category-content {
  padding: 2.5rem 3.5rem;
}
.categories-slider h3 {
  position: relative;
  z-index: 1;
  width: 100%;
  padding: 1.45em 2em;
  color: #000;
  font-size: 12px;
  font-weight: 600;
  letter-spacing: 0;
  text-transform: none;
  text-align: center;
}
.categories-slider h3:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transform: skew(-25deg);
  background: #fff;
}
.categories-slider h3:after {
  content: "\e81f";
  margin-left: 6px;
  font-family: "porto";
}

.products-slider .owl-dots .owl-dot span {
  border-color: #e1ddc3;
}

.main .sidebar-shop .widget {
  border: 0;
  background-color: white;
}
.sidebar-shop .widget .owl-nav {
  top: -5.1rem;
}
.sidebar-shop .widget-block h5 {
  margin-top: 0.8rem;
}
.sidebar-shop .widget-title {
  padding-bottom: 1rem;
  border-bottom: 1px solid #e7e7e7;
  letter-spacing: 0.05em;
}

.toolbox label {
  color: #222529;
  font-size: 1.3rem;
  font-weight: 700;
}

.btn-lg {
  min-width: 17rem;
}

.btn.btn-dark.loadmore,
.btn.btn-dark.loadmore:hover {
  border: 0;
  padding: 1.11em 3.15em;
  color: #fff;
  font-size: 1.4rem;
}

.btn-center {
  margin-left: 50%;
  transform: translate(-50%);
}

.top-notice {
  color: #fff;
  font-weight: 400;
}
.top-notice a:hover {
  color: #e1ddc3;
}

.heading {
  color: #222529;
}

@media (min-width: 992px) {
  .info-boxes-container .info-box:not(:last-child) {
    border-right: 0;
  }
}
@media (max-width: 991px) {
  .home-slide {
    font-size: 1.3rem;
  }
  .owl-carousel.owl-theme .owl-nav .owl-prev {
    left: 1.7vw;
  }
  .owl-carousel.owl-theme .owl-nav .owl-next {
    right: 1.7vw;
  }
  .info-boxes-container .info-box {
    justify-content: flex-start;
    -ms-flex-pack: start;
  }
}
@media (min-width: 768px) {
  .banners-container .banner:not(:last-child) {
    border-right: 0;
  }
}
@media (max-width: 767px) {
  .banners-container .banner:not(:last-child) {
    border-bottom: 1px solid #e7e7e7;
  }
  .promo-content h2 {
    font-size: 5vw;
  }
  .promo-content h3 {
    font-size: 10vw;
  }
}
@media (min-width: 576px) {
  .category-banner .divider-short-thick {
    width: 4.5px;
    border-top: 53.38px solid #fff;
    margin: 0 2.4rem;
  }
}
@media (max-width: 575px) {
  .home-slide {
    font-size: 2.3vw;
  }
  .home-slider h5 {
    margin-right: 1rem;
    margin-top: 1rem;
    color: #fff;
  }
  .home-slider .btn {
    margin-top: 1.6rem;
  }
}
@media (max-width: 479px) {
  .blog-section .post {
    flex-wrap: wrap;
    -ms-flex-wrap: wrap;
  }
  .blog-section .post-body {
    margin: 0;
    flex: 1 1 100%;
    -ms-flex: 1 1 100%;
  }
}
.history-section {
  padding: 5rem 0;
  background-color: #efefef;
}
.history-section .tab-content h3 {
  margin-bottom: 2rem;
  font: 400 2.5rem/1.2 Euclid, sans-serif;
  text-transform: uppercase;
}
.history-section .tab-content img {
  margin-bottom: 2rem;
}
.history-section .col-lg-2 {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.nav.nav-history {
  width: 100%;
  margin-bottom: 1rem;
}
.nav.nav-history .nav-item {
  display: inline-block;
  margin-right: 1.7rem;
  font: 400 1.8rem/1 Euclid, sans-serif;
}
.nav.nav-history .nav-item:last-child {
  margin-right: 0;
}
.nav.nav-history .nav-link {
  display: -ms-inline-flexbox;
  display: inline-flex;
  position: relative;
  -ms-flex-align: center;
  align-items: center;
  padding: 1.5rem 0;
  color: #979797;
}
.nav.nav-history .nav-link:before {
  display: inline-block;
  width: 9px;
  height: 2px;
  margin-right: 1rem;
  background-color: #979797;
  content: "";
}
.nav.nav-history .nav-link.active, .nav.nav-history .nav-link:hover, .nav.nav-history .nav-link:focus {
  color: #222529;
}
.nav.nav-history .nav-link.active:before, .nav.nav-history .nav-link:hover:before, .nav.nav-history .nav-link:focus:before {
  background-color: #222529;
}

@media (min-width: 992px) {
  .history-section {
    padding-top: 6.5rem;
    padding-bottom: 6.5rem;
  }
  .history-section .tab-content img {
    margin-bottom: 0;
  }
  .history-section .tab-content h3 {
    font-size: 3rem;
  }
  .history-section .tab-content .col-sm-8 {
    padding-right: 45px;
  }
  .history-section .tab-content .col-sm-4 + .col-sm-8 {
    padding-right: 15px;
    padding-left: 45px;
  }
  .nav.nav-history {
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .nav.nav-history .nav-item {
    display: block;
    margin-right: 0;
  }
  .nav.nav-history .nav-link {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: end;
    justify-content: flex-end;
  }
  .nav.nav-history .nav-link:before {
    margin-right: 2rem;
  }
}