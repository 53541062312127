.iti {
  width: 100%;

  &__selected-flag {
    padding-left: 2rem;
  }

  input#country-search-box {
    height: 40px;
    border-radius: 0;
    font-size: 14px;

    &:focus {
      outline: none;
    }
  }
}

.is-invalid {
  .iti {
    input {
      border-color: #dc3545;
    }
  }
}

.iti__country {
  font-size: 14px;
 

  &.iti__preferred {
    margin-top: 1.6rem;
  }
}

.checkout-steps li {
  margin-bottom: 0 !important;
}