// Product Page 2
.product-single-extended {
	margin-bottom: 6rem;

	.product-single-gallery {
		position: relative;

		.owl-item:before {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			z-index: 1;
			background-color: transparent;
			transition: background-color 1s;
		}

		.owl-item.center:before {
			background-color: rgba(0,0,0,0.07);
		}
	}

	.product-title {
		font-size: 3.5rem;
		line-height: 1.15;
	}

	.price-box {
		margin-bottom: 1rem;
		border: 0;
	}

	.product-price {
		font-size: 3.1rem;
	}

	.old-price {
		font-size: 2.4rem;

		& + .product-price {
			margin-left: 1.5rem;
		}
	}

	.widget-area  {
		.widget.widget-info {
			margin-bottom: 0;
		}
	}

	.product-filters-container {
		border-top: 1px solid #e7e7e7;
	}
}

.product-desc {
	.view-more {
		color: $primary-color;
		font-weight: 700;
		text-transform: uppercase;
	}
}

.product-single-header {
	margin-bottom: 3.2rem;
	padding-bottom: 1.4rem;
	border-bottom: 1px solid #dae2e6;
}

.single-qty-wrapper {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;

	label {
		margin-right: 1.3rem;
		margin-bottom: 0;
		color: #21293c;
		font-weight: 600;
		font-size: 1.5rem;
		line-height: 1.1;
		font-family: $shop-font-family;
		letter-spacing: .005em;
		text-transform: uppercase;
	}

	.product-single-qty {
		.horizontal-quantity {
			padding: .2rem;
			height: 24px;
			width: 36px;
			background-color: #f4f4f4;
			font-size: 1.3rem;
		}

		.btn {
			border-style: none;
		}
	}
}

.product-single-row {
	display: -ms-flexbox;
	display: flex;
	position: relative;
	-ms-flex-align: center;
	align-items: center;
	height: 700px;
	background-color: #fff;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	color: #7b858a;
	font-size: 1.4rem;
	letter-spacing: .005em;
	line-height: 1.92;

	.single-row-entire,
	.single-row-bg {
		position: absolute;
		top: 0;
		bottom: 0;
		background-color: #ccc;
		background-repeat: no-repeat;
		background-position: center center;
		background-size: cover;
	}

	.single-row-entire {
		width: 100%;
	}

	.single-row-bg {
		left: 50%;
		width: 50%;
	}

	&.single-row-reverse {
		.single-row-bg {
			left: 0;
		}
	}

	h5 {
		margin-bottom: 1.1rem;
		color: #7b858a;
		font-size: 2rem;
		font-weight: 600;
		letter-spacing: -.01em;
	}

	h2 {
		margin-bottom: 2.8rem;
		font-size: 4.5rem;
		font-weight: 600;
		letter-spacing: -.01em;
	}

	p {
		margin-bottom: 0;
	}

	ul,
	ol {
		margin-bottom: 0;

		li {
			margin-bottom: .4rem;

			i {
				margin-right: 2rem;
				color: #21293c;
				font-size: 1.5rem;

				&:before {
					margin: 0;
				}
			}
		}
	}
}

.product-single-video {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-align: center;
	align-items: center;
	height: 300px;
	background-color: #4d4d4d;
	background-repeat: no-repeat;
	background-position: center center;
	background-size: cover;
	color: #7b858a;
	text-align: center;

	h3 {
		margin-bottom: 1.2rem;
		color: #21293c;
		font-size: 3.5rem;
		font-weight: 600;
		letter-spacing: -.01em;
	}

	.video-btn {
		color: #7b858a;
		font-size: 2rem;
		font-weight: 600;
		letter-spacing: -.01em;
		text-transform: uppercase;

		img {
			display: inline-block;
			max-width: 38px;
			height: auto;
			margin-left: .7rem;
		}
	}
}

@include mq(lg, max) {
	.product-single-row h2 {
		font-size: 3.5rem;
	}
}

@include mq(md, max) {
	.product-single-row {
		display: block;

		.single-row-bg {
			position: relative;
			left: 0;
			width: 100%;
			height: 50%;
		}

		.single-row-entire {
			position: relative;
			height: 50%;
		}

		.container {
			display: -ms-flexbox;
			display: flex;
			-ms-flex-align: center;
			align-items: center;
			height: 50%;
		}
	}

	.product-single-extended {
		.product-price {
			font-size: 2.4rem;
		}

		.product-title {
			font-size: 3rem;
		}
	}
}

@include mq(sm, max) {
	.product-single-row h2 {
		font-size: 3rem;
	}

	.product-single-extended .single-qty-wrapper {
		width: 100%;
		margin-bottom: 2rem;
	}
}