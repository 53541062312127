/*
 *  Owl Carousel - Core
 */
.owl-carousel {

  display: flex;
  width: 100%;
  height: 100%;
  height: 100%;
  -webkit-tap-highlight-color: transparent;
  /* position relative and z-index fix webkit rendering fonts issue */
  position: relative;
  z-index: 1;

  .owl-stage {
    position: relative;
    -ms-touch-action: pan-Y;
    touch-action: manipulation;
    -moz-backface-visibility: hidden;
    /* fix firefox animation glitch */
    display: flex;
    width: 100%;
    height: 100%;

  }

  .owl-stage:after {
    content: ".";
    display: block;
    clear: both;
    visibility: hidden;
    line-height: 0;
    height: 0;
  }

  .owl-stage-outer {
    position: relative;
    overflow: hidden;
    /* fix for flashing background */
    -webkit-transform: translate3d(0px, 0px, 0px);

    display: flex;
    width: 100%;
    height: 100%;

    owl-stage {
      display: flex;
      width: 100%;
      height: 100%;

      &>* {
        display: block;
        width: 100%;
        height: 100%;
      }
    }
  }

  .owl-wrapper,
  .owl-item {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
  }

  .owl-item {
    position: relative;
    min-height: 1px;
    float: left;
    -webkit-backface-visibility: hidden;
    -webkit-tap-highlight-color: transparent;
    -webkit-touch-callout: none;
    height: 100%;
  }

  .owl-item img {
    display: block;
    width: 100%;
  }

  .owl-nav.disabled,
  .owl-dots.disabled {
    display: none;
  }

  .owl-nav .owl-prev,
  .owl-nav .owl-next,
  .owl-dot {
    cursor: pointer;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  .owl-nav button.owl-prev,
  .owl-nav button.owl-next,
  button.owl-dot {
    background: none;
    color: inherit;
    border: 0;
    padding: 0 !important;
    font: inherit;
  }

  &.owl-loaded {
    display: block;
  }

  &.owl-loading {
    opacity: 0;
    display: block;
  }

  &.owl-hidden {
    opacity: 0;
  }

  &.owl-refresh .owl-item {
    visibility: hidden;
  }

  &.owl-drag .owl-item {
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  &.owl-grab {
    cursor: move;
    cursor: grab;
  }

  &.owl-rtl {
    direction: rtl;
  }

  &.owl-rtl .owl-item {
    float: right;
  }


}

/* No Js */
.no-js .owl-carousel {
  display: block;
}

.owl-theme,
.owl-carousel,
.owl-carousel .owl-stage-outer,
owl-stage,
.owl-carousel .owl-stage,
.owl-carousel .owl-item {
  height: 100%;
}

.prod-thumbnail {
  .owl-item {
    width: 80px !important;

    img {
      width: 80px !important;

      &.product-gallery__carousel-item--active {
        border: 2px solid #21293c;
      }
    }
  }

  .owl-theme,
  .owl-carousel,
  .owl-carousel .owl-stage-outer,
  owl-stage,
  .owl-carousel .owl-stage,
  .owl-carousel .owl-item {
    width: 100% !important;
  }
}


.selected-products-slider {
  .owl-carousel {
    .owl-stage {
      .owl-item {
        @media screen and (min-width: 992px) {
          &:first-child {
            margin-left: 1%;
          }
        }
      }
    }
  }

  .owl-theme .owl-nav [class*=owl-] {
    top: 20%;
    background: white;
    width: 5rem;
    height: 5rem;
    border-radius: 5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
      background: white;
    }
  }

  .owl-carousel .owl-nav .owl-next {
    right: 4vw;
  }

  .owl-carousel .owl-nav .owl-prev {
    left: 4vw;
  }

  .owl-theme .owl-nav .disabled {
    display: none;
  }

}