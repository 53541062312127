.ngx-dropdown-container .ngx-dropdown-button {
    height: 6rem;
    margin-bottom: 1rem;
    padding: 2rem !important;
    transition: all 0.3s;
    border: 1px solid #dfdfdf !important;
    border-radius: 0;
    background-color: #fff;
    color: #777 !important;
    font-family: Poppins, sans-serif;
    font-size: 1.2rem !important;
    font-weight: 400;
    line-height: 1.5 !important;
    border-radius: 0 !important;
}