// Header

.header-top {
	.header-right {
		-ms-flex-align: stretch;
		align-items: stretch;
	}

	a {
		color: #fff;
	}
}

.header-middle {
	// background-color: #9E9993;
	// background-image: url('/assets/images/header-bg.jpg');
	// background-repeat: no-repeat;
	// background-size: cover;
	padding-top: 0;
	padding-bottom: 0;

	&.fixed {
		padding: 1rem;

		.sizes-alert {
			left: -16px;
			right: -16px;
			width: calc(100% + 32px);
			top: -12px;
		}
	}

	.header-transparent &:not(.fixed) {
		background-color: transparent;
		background-image: none;
	}

	.container,
	.container-fluid {
		position: relative;
	}

	.sizes-alert {
		position: relative;
		padding: 16px;
		// margin-bottom: 4px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: #F44336;
		font-size: 1.6rem;
	}
}

.header-dropdowns {
	border-left: 1px solid #2c3035;
	margin-left: 1rem;
	letter-spacing: .01em;
}

.cart-count {
	background: $primary-color-dark;
}

.cart-dropdown {
	padding-left: 1.9rem;
	border-left: 1px solid rgba(0, 0, 0, .2);

	.dropdown-menu:before {
		right: 16px;
	}

	.dropdown-toggle {
		display: block;
		position: relative;
		color: #fff;
		transform: translateY(1px);

		i {
			padding-right: 1.1rem;
			line-height: 1.25;
			color: #333;
		}

		.dropdown-cart-text {
			margin-left: 1rem;
		}

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	.product-image-container {
		border: 0;
	}

	.product-title {
		font-weight: 500;
	}
}

.dropdown-cart-action .btn {
	color: #fff;
	font-weight: bold;
}

.search-toggle,
.header-icon {
	display: inline-block;
	padding: 0 1.68rem;
	color: #333;
	font-size: 2.8rem;
	line-height: 1.25;
	border-left: 1px solid rgba(0, 0, 0, .2);
}

.search-toggle i {
	color: #333;

	&:before {
		margin: 0 6px 0 8px;
		transform: translateY(-2px);

	}
}

.header-search-wrapper {
	padding-right: 1.68rem;
	right: -2rem;
}

@include mq(md) {
	.header-dropdowns {
		margin-left: 2.5rem;
	}
}

@include mq(lg, max) {
	.logo {
		max-width: 100px;
		padding: 15px;
	}
	.header-top {
		.container {
			flex-wrap: wrap;
			-ms-flex-wrap: wrap;
		}

		.header-left,
		.header-right {
			width: 100%;
			justify-content: center;
			-ms-flex-pack: center;
			text-align: center;
		}
	}
}

@include mq(sm, max) {
	.logo {
		max-width: 100px;
		padding: 0;
		

		img {
			height: 75px;
			padding: 6px;
		}
	}

	.top-menu li+li {
		margin-left: 1rem;
	}

	.header-dropdown,
	.header-dropdowns {
		margin-left: 0;
	}
}

@include mq(xs, max) {
	.top-menu li+li {
		margin-left: 0;
	}

	.header-icon {
		display: none;
	}

	.sizes-alert {
		flex-direction: column;
	}
}