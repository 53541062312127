.my-account {
  margin-top: 40px;
}

.sidebar {
  position: relative;
  font-size: 13px;
}

.dashboard-content {
  margin-bottom: 10px;
}

.dashboard-content h2,
#account-chage-pass h3 {
  margin-bottom: 20px;
  font-size: 24px;
  color: #2f333a;
}

@media screen and (min-width: 992px) {

  form,
  .form-footer {
    margin-bottom: 50px;
  }
}


@media screen and (min-width: 768px) {

  form,
  .form-footer {
    margin-bottom: 40px;
  }
}

.sidebar .widget {
  margin-bottom: 30px;
}


.widget-title {
  margin: 5px 0 13px;
  color: #000;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.2;
}

.widget-dashboard .widget-title {
  margin-top: 2px;
  font-size: 17px;
  font-weight: 600;
}

.widget-dashboard {


  .list {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .list li.active {
    font-weight: 600;
  }

  .list li {
    display: block;
    position: relative;
    margin: 0;
    padding: 0;
  }

  .list li a {
    display: block;
    position: relative;
    padding: 9px 0 7px 20px;
    border-bottom: 1px solid #ededde;
    color: #000;
    font-size: 13px;

    &::before {
      display: inline-block;
      position: absolute;
      width: 0;
      height: 0;
      margin: 9px 0 0 -10px;
      border-top: 4px solid transparent;
      border-bottom: 4px solid transparent;
      border-left: 4px solid #333;
      content: '';
      top: 0;
      bottom: 0;
      margin: auto;
      left: 0;
    }
  }

  .list li a:hover,
  .list li a:focus {
    background-color: #eee;
    text-decoration: none;
  }
}

.required {
  color: #e02b27;
  font-size: 12px;
}

.custom-control.material-checkbox .material-control-input:checked~.material-control-indicator {
  border: 2px solid #2f333a;
  border-top: 0px;
  border-left: 0px;
}

.custom-control.material-checkbox .material-control-indicator {
  border: 1px solid #2f333a;
}

.custom-control {
  padding-left: 0;

  display: flex;
  align-items: center;

  input {
    margin-right: 1.6rem;
  }
}

.required-field>label::after {
  margin: 0 0 0 .45rem;
  color: #e02b27;
  font-size: 1.2rem;
  content: '*';
}

.form-footer-right {
  display: flex;
  margin-left: auto;
  justify-content: flex-end;
}
