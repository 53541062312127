// Cart
.cart-table-container {
  margin-bottom: 2.5rem;

  .product-image img {
    max-width: 18rem;
  }
}

.table.table-cart {
  tr {

    th,
    td {
      vertical-align: middle;

      &:not(.product-col) {
        text-align: center;
      }
    }

    th {
      padding: 2.4rem 1.5rem 1.2rem;
      border: 0;
      font-weight: 600;
    }

    td {
      padding: 2rem 1.5rem;
      border-top: 1px solid #ccc;

      &.product-col {
        padding: 2rem .8rem 1.8rem 0;
      }
    }

    &.product-action-row {
      td {
        padding: 0 0 2.2rem;
        border: 0;
      }
    }
  }

  .product-title {
    margin-bottom: 0;
  }

  tfoot {
    td {
      padding: 2rem 0 1rem;
    }

    .btn {
      padding: .85rem 2rem;
      font-family: $font-family;
      letter-spacing: 0;
      text-transform: initial;

      &+.btn {
        margin-left: 1rem;
      }
    }
  }

  .bootstrap-touchspin.input-group {
    margin-right: auto;
    margin-left: auto;
  }
}

.btn-remove,
.btn-edit,
.btn-move {

  &:hover,
  &:focus {
    color: $primary-color;
    text-decoration: none;
  }
}

.btn-edit {
  margin-right: 1rem;
  font-size: 1.3rem;
}

.btn-move {
  font-size: 1.3rem;
  line-height: 2.5rem;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

.qty-col {
  min-width: 98px;
}

.product-col {
  tbody & {
    font-size: 0;
  }

  .product-image-container {
    display: table-cell;
    padding-right: 1.8rem;
    margin-bottom: 0;
    vertical-align: middle;
  }

  .product-image img {
    border: 1px solid #ccc;
  }

  .product-title {
    display: table-cell;
    vertical-align: middle;
  }
}

.cart-discount {
  margin-bottom: 4rem;

  h4 {
    margin-bottom: 1.2rem;
    font-size: 1.6rem;
    font-weight: 400;
  }

  form {
    max-width: 420px;
  }
}

.cart-summary {
  margin-bottom: 2.5rem;
  padding: 1.4rem 1.8rem 2.2rem;
  border: 1px solid #ddd;
  background: #fbfbfb;

  h3 {
    margin-bottom: 1.3rem;
    font-size: 2.5rem;
    font-weight: 300;
  }

  h4 {
    margin-bottom: 0;
    border-top: 1px solid #ccc;
    font-size: 1.5rem;
    font-weight: 600;

    a {
      display: block;
      position: relative;
      padding: 1rem 2rem 1rem 0;

      &:after {
        display: inline-block;
        position: absolute;
        top: 50%;
        right: .4rem;
        transform: translateY(-50%);
        font-family: 'porto';
        font-size: 1.4rem;
        content: '\e81b';
      }

      &.collapsed:after {
        content: '\e81c';
      }
    }
  }
}

#total-estimate-section {
  form {
    margin-bottom: 0;
    padding: .7rem 0 2.2rem;
  }
}

.table.table-totals {
  margin-bottom: 2rem;
  border-top: 1px solid #ccc;

  tbody {
    tr:first-child {
      td {
        padding-top: 1.6rem;
      }
    }
  }

  tr {
    border: 0;

    th,
    td {
      padding: .6rem 0;
      border: 0;

      &:last-child {
        text-align: right;
      }
    }
  }

  tfoot {
    border-top: 1px solid #ccc;
    font-size: 1.8rem;
    font-weight: 600;

    tr td {
      padding-top: 1.3rem;
      padding-bottom: 1.6rem;
    }
  }
}

.checkout-methods {
  .btn {
    letter-spacing: 0;

    +.btn {
      margin-top: 2.2rem;
    }
  }
}

.cart-plus-minus {
  position: relative;
  display: flex;
  justify-content: center;

  input {
    border: 1px solid #ccc;
    text-align: center;
    margin-bottom: 0;
    height: 4.2rem;
    max-width: 46px;
    padding: 1.1rem 1rem;
    border-radius: 0;
    box-shadow: none;
	color: #777;
    font-family: Poppins,sans-serif;

    &:focus {
      border-radius: 0;
      outline: none;
    }
  }

  &__actions {
    display: flex;
    flex-direction: column;
    margin-left: .2rem;
    justify-content: space-between;

    button {
      height: 2rem;
      padding: 0;
      width: 2rem;
      text-align: center;
      font-size: 1.2rem;
	  border: 1px solid #ccc;
	  background-color: transparent;
	  color: #ccc;

	  &:hover {
		  cursor: pointer;
	  }

	  &:before {
		font-family: "porto";
		font-style: normal;
		font-weight: normal;
		speak: none;
	  }

	  &.inc {
		&:before {
			content: '\e80d';
		}
	  }

	  &.desc {
		&:before {
			content: '\e80b';
		}
	  }
    }

  }
}

@include mq(md, max) {
  .table.table-cart {

    &,
    tbody,
    tfoot {
      display: block;
    }

    thead {
      display: none;
    }

    tr {
      td {
        padding: .5rem 1rem;
        border-top: 0;

        &.product-col {
          padding-bottom: .5rem;
        }
      }
    }

    .product-row {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-direction: column;
      flex-direction: column;
      border-top: 1px solid #ccc;
    }

    .product-action-row {
      display: -ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center;

      td {
        width: 100%;
      }

      .float-right {
        margin-top: -4px;
        margin-left: auto;
      }
    }

    .product-col {
      -ms-flex-direction: column;
      flex-direction: column;
      -ms-flex-pack: center;
      justify-content: center;
      text-align: center;

      .product-image-container {
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
        margin-right: 0;
        margin-bottom: 1rem;
      }
    }

    tfoot {
      border-top: 1px solid #ccc;

      tr {
        display: block;
        width: 100%;

        td {
          display: -ms-flexbox;
          display: flex;
          -ms-flex-direction: column;
          flex-direction: column;
          -ms-flex-align: start;
          align-items: flex-start;
          padding: 1rem 0;
        }
      }

      .btn {
        margin-top: .5rem;
        padding-right: 1rem;
        padding-left: 1rem;
      }

      .float-left {
        display: none;
      }

      .float-right {
        margin-left: auto;
      }
    }
  }
}
