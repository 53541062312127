.skeleton-box {
    display: flex;
    height: 100%;
    position: relative;
    overflow: hidden;
    background-color: #DDDBDD;
  
    &::after {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      transform: translateX(-100%);
      background-image: linear-gradient(
        90deg,
        rgba(#fff, 0) 0,
        rgba(#fff, 0.2) 20%,
        rgba(#fff, 0.5) 60%,
        rgba(#fff, 0)
      );
      animation: shimmer 2s infinite;
      content: '';
    }
  
    @keyframes shimmer {
      100% {
        transform: translateX(100%);
      }
    }

    &.line {
        height: 1.6rem;
        margin-bottom: 1rem;

        &:last-child {
            margin-bottom: 2.4rem;
        }
    }
  }

  .o-media__figure {
      height: 200px;
      margin-bottom: 1.6rem;
  }