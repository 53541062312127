.dashboard-content {
	margin-bottom: 1em;
}

.dashboard-content h2 {
	margin-bottom: 2rem;
	font-size: 2.4rem;
}

.dashboard-content .form-control {
	max-width: 100%;
}

#account-chage-pass {
	display: none;
	padding-bottom: 1.5rem;

	&.show {
		display: block;
	}
}

.widget-dashboard .widget-title {
	margin-top: .2rem;
	font-size: 1.8rem;
	font-weight: 600;
}

.widget-dashboard {
	margin-bottom: 3rem;
}

@include mq(md) {
	.dashboard-content h2 {
		font-size: 2.6rem;
	}
}
