// Category

// # Contents
// 1. Sidebar
// 2. Sidebar Widgets
// 2.1) Category List
// 2.2) Size List
// 2.3) Price Slider
// 2.4) Widget Block
// 3. Horizontal Filter
// 3.1) Sort List
// 3.2) Filter Toggle


// # Variables
@include set-default(
	(
		category: (
			sidebar: (
				title: (
					font-size: 1.5rem,
					font-weight: 600
				)
			)
		)
	)
);

// 1. Sidebar

.sidebar-shop {
	font-size: $sidebar-font-size;

	.widget {
		padding: 2rem;
		border: 1px solid #e7e7e7;
		@include clearfix;

		&:not(:last-child) {
			border-bottom: 0;
		}

		.config-swatch-list {
			margin-top: .3rem;

			li {
				display: -ms-flexbox;
				display: flex;
				-ms-flex-align: center;
				align-items: center;
				margin-right: 0;
				margin-bottom: .5rem;
				font-size: $sidebar-font-size;

				&.active {
					font-weight: bold;
				}

				&:last-child {
					margin-bottom: 0;
				}

				a {
					margin-right: 1.5rem;
				}
			}
		}
	}

	.widget-title {
		margin: 0;
		color: #313131;
		@include css(font-size, category, sidebar, title, font-size);
		@include css(font-weight, category, sidebar, title, font-weight);
		font-family: $second-font-family;
		line-height: 1.4;
		text-transform: uppercase;

		a {
			display: block;
			position: relative;
			color: inherit;

			&:hover,
			&:focus {
				text-decoration: none;
			}

			&:before,
			&:after {
				display: inline-block;
				position: absolute;
				top: 50%;
				right: 0;
				width: 10px;
				height: 2px;
				margin-top: -1px;
				transition: all .35s;
				background: $primary-color-dark;
				content: '';
			}

			&.collapsed:after {
				transform: rotate(-90deg);
			}
		}
	}

	.widget-body {
		padding: 1.5rem 0;
	}

	.widget-featured {
		position: relative;

		.widget-body {
			padding-top: 2.3rem;
		}

		.product-sm:last-child {
			margin-bottom: 0;
		}
	}
}

// 2. Sidebar Widgets

.widget {
	.owl-carousel .owl-nav {
		position: absolute;
		top: -4.3rem;
		right: -.4rem;
		line-height: 0;

		button.owl-next,
		button.owl-prev {
			padding: 0 .4rem !important;
			border-radius: 0;
			color: $headings-text;
			font-size: 2rem;
			line-height: 1;
		}

		i:before {
			width: auto;
			margin: 0;
		}
	}
}

// 2.1) Category List
.cat-list {
	margin: 0;
	padding: 0;
	list-style: none;
	// max-height: 330px;
	overflow: auto;

	li {
		margin-bottom: 1.4rem;

		&:last-child {
			margin-bottom: 0;
		}

		a {
			color: $body-text;

			&:hover,
			&:focus {
				color: $primary-color;
			}
		}

		strong {
			margin-bottom: 1.4rem;
			display: block;
		}

		ul {
			background: #f5f5f5;
			padding: 1.2rem;
			li {
				font-size: 1.2rem;

				&.active {
					
					a {
						font-weight: bold;
						color: #e1ddc3;
					}
				}
			}
		}

		&.active {
			a {
				font-weight: bold;
			}
		}
	}
}

// 2.2) Size List
.config-size-list {
	margin: 0;
	padding: 0;
	font-size: 0;
	list-style: none;
}

.config-size-list li {
	display: -ms-inline-flexbox;
	display: inline-flex;
	margin-bottom: .8rem;

	&:not(:last-child) {
		margin-right: .8rem;
	}
}

.config-size-list a {
	display: block;
	position: relative;
	width: 4.4rem;
	height: 3rem;
	transition: all .3s;
	border: 1px solid #e9e9e9;
	color: $body-text;
	font-size: 1.4rem;
	font-weight: 600;
	line-height: 2.8rem;
	text-align: center;
	text-decoration: none;

	&:hover,
	&:focus,
	&.active {
		border-color: $primary-color;
		background-color: $primary-color;
		color: #61605a;
		text-decoration: none;
	}
}

// 2.3) Price Slider
.price-slider-wrapper {
	padding-top: 2rem;
}

.filter-price-action {
	margin-top: 2.5rem;
	padding-bottom: .4rem;

	.btn {
		padding: 3px .7em;
		font-size: 1.2rem;
		font-weight: 400;
	}

	.filter-price-text {
		font-size: 1.2rem;
		line-height: 2;
	}
}

// 2.4) Widget Block
.widget-block {
	font-size: 1.5rem;
	line-height: 1.42;

	h5 {
	  margin-bottom: 2rem;
	  color: #7a7d82;
	  font-size: 1.4rem;
	  font-weight: 600;
	  font-family: $font-family;
	}
  
	p {
		color: #21293c;
	  	max-width: 210px;
	}
}
  
// 3. Horizontal Filter

.horizontal-filter {
	margin-bottom: 2rem;
	padding: 12px 12px 2px;
	background-color: #f4f4f4;

	.filter-price-form {
		font-family: $font-family;
		font-size: 1.36rem;

		.btn {
			font-family: inherit;
			padding: .7rem 1.2rem;
			font-size: 1.2rem;
			font-weight: 400;
		}
	}

	.input-price {
		display: block;
		width: 50px;
		padding: 6px;
		line-height: 1.45;
		outline: none;
		border: 1px solid rgba(0, 0, 0, 0.09);
	}

	select {
		border: 0;
	}
}

// 3.1) Sort List
.sort-menu-trigger {
	display: block;
	color: #313131;
	font-size: 12px;
	line-height: 1.4;
	text-transform: uppercase;
}

.sort-list li {
	padding: 1rem 0;
	font-size: 12px;
	text-transform: uppercase;

	a {
		color: inherit;
		font-weight: 600;
	}
}

// 3.2) Filter Toggle
.filter-toggle {
	span {
		color: $body-text;
		font-size: 1.3rem;
	}

	a {
		display: inline-block;
		position: relative;
		width: 46px;
		height: 26px;
		margin-left: 8px;
		border-radius: 13px;
		background: #e6e6e6;
		text-decoration: none;

		&:before {
			position: absolute;
			left: 0;
			width: 42px;
			height: 22px;
			-webkit-transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
			transform: translate3d(2px, 2px, 0) scale3d(1, 1, 1);
			transition: all .3s linear;
			border-radius: 11px;
			background-color: #fff;
			content: '';
		}

		&:after {
			position: absolute;
			left: 0;
			width: 22px;
			height: 22px;
			-webkit-transform: translate3d(2px, 2px, 0);
			transform: translate3d(2px, 2px, 0);
			transition: all .2s ease-in-out;
			border-radius: 11px;
			background-color: #fff;
			box-shadow: 0 2px 2px rgba(0, 0, 0, .24);
			content: '';
		}
	}

	&.opened {
		a {
			background-color: $primary-color;
		}

		a:before {
			-webkit-transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
			transform: translate3d(18px, 2px, 0) scale3d(0, 0, 0);
		}

		a:after {
			-webkit-transform: translate3d(22px, 2px, 0);
			transform: translate3d(22px, 2px, 0);
		}
	}
}

// 5. Extra
// 5.1) Loadmore
.btn-loadmore {
	box-shadow: none;
	padding: 1.3rem 3rem;
	border: 1px solid #e7e7e7;
	font-size: 1.2rem;
	font-family: $font-family;
	color: #555;

	&:hover {
		border-color: #ccc;
	}
}

// # Responsive
@include mq(lg) {
	.horizontal-filter:not(.filter-sorts) {
		padding-left: 20px;
		padding-right: 20px;
	}

	.filter-sorts {
		.toolbox-left {
			position: relative;
		}

		.toolbox-item.toolbox-sort {
			margin-left: 0;
			margin-right: 1rem;
			background-color: #fff;
		}

		select {
			border: 0;
			text-transform: uppercase;
		}

		.mobile-sidebar.sidebar-shop {
			left: 0;
			padding: 0;
			visibility: visible;
			z-index: 2;
		}
	}

	.sort-list {
		display: none;
		position: absolute;
		top: 100%;
		left: 0;
		min-width: 220px;
		margin-top: 10px;
		padding: 10px 15px;
		background: #fff;
		box-shadow: 0 1px 3px rgba(0, 0, 0, .15);
		z-index: 99;

		&:before, &:after {
			content: '';
			position: absolute;
			bottom: 100%;
			border-right: 10px solid transparent;
			border-bottom: 10px solid #fff;
			border-left: 10px solid transparent;
		}

		&:before {
			left: 21px;
			z-index: 999;
		}

		&:after {
			left: 20px;
			border-right-width: 11px;
			border-bottom: 11px solid #e8e8e8;
			border-left-width: 11px;
		}
	}

	.sort-menu-trigger {
		min-width: 140px;
		height: 34px;
		padding-left: .8rem;
		color: #777;
		line-height: 34px;
		z-index: 9;

		&:hover,
		&:focus {
			text-decoration: none;
		}
	}

	.toolbox-item.opened {
		.sort-list {
			display: block;
		}
	}
}

@include mq(lg, max) {
	.sort-menu-trigger {
		margin-bottom: 1.5rem;
		font-weight: 700;
	}

	.sidebar-shop .widget {
		padding: 2rem 0;
		border: 0;
		
		&:first-child {
			padding-top: 0;
		}

		&:not(:last-child) {
			border-bottom: 1px solid #e7e7e7;
		}
	}
}
@media (min-width: 992px) and (max-width: get( layout, container, max-width-desktop)) {
	.sidebar-shop .product-widget figure {
		max-width: 70px;
		margin-right: 1.5rem;
	}
}